// Imports
import styled from 'styled-components/macro';


// Styled components
const ClauseHeading = styled.h3`
	font-size: 1.5rem;
	font-weight: 600;
	margin-bottom: 0.75rem;
`;

export default ClauseHeading;
