// Imports
import { faCalendars } from '@fortawesome/pro-regular-svg-icons';
import Feature from '..';


// Banner
const feature: React.ComponentProps<typeof Feature> = {
	banner: {
		label: { icon: faCalendars, text: 'Calendars' },
		heading: 'Showcase upcoming events & meetings, plus collect RSVPs',
		description:
			'Share all the details residents need to know about upcoming events via fully-featured calendars. Save time with repeating events and automatic email announcements, plus know who’s coming with powerful RSVP features.',
		screenshot: {
			width: 900,
			height: 710,
			src: '/images/screenshots/features/calendars/banner.webp',
		},
	},
	pitch: { text: 'Bring residents together and promote involvement', textMaxWidth: 'min(35.25rem, 47vw)' },
	tiles: {
		leftColumn: [
			{
				heading: 'Robust repeating abilities',
				screenshot: {
					width: 980,
					height: 542,
					src: (layout) => `/images/screenshots/features/calendars/${layout}/repeating.webp`,
				},
			},
			{
				heading: 'Add notes & attachments',
				screenshot: {
					width: 980,
					height: 386,
					src: (layout) => `/images/screenshots/features/calendars/${layout}/notes-attachments.webp`,
				},
			},
			{
				heading: 'Announce at any time',
				screenshot: {
					width: 980,
					height: 732,
					src: (layout) => `/images/screenshots/features/calendars/${layout}/announce.webp`,
				},
			},
			{
				heading: 'Upcoming events on homepage',
				screenshot: {
					width: 980,
					height: 234,
					src: (layout) => `/images/screenshots/features/calendars/${layout}/upcoming-homepage.webp`,
				},
			},
		],
		rightColumn: [
			{
				heading: 'Powerful RSVPs',
				screenshot: {
					width: 980,
					height: 356,
					src: (layout) => `/images/screenshots/features/calendars/${layout}/rsvps.webp`,
				},
			},
			{
				heading: 'Display holidays',
				screenshot: {
					width: 680,
					height: 432,
					src: (layout) => `/images/screenshots/features/calendars/${layout}/holidays.webp`,
				},
			},
			{
				heading: 'Automatic email announcements',
				screenshot: {
					width: 980,
					height: 676,
					src: (layout) => `/images/screenshots/features/calendars/${layout}/automatic-announcements.webp`,
				},
			},
			{
				heading: 'Organize into multiple calendars',
				screenshot: {
					width: 780,
					height: 432,
					src: (layout) => `/images/screenshots/features/calendars/${layout}/multiple-calendars.webp`,
				},
			},
		],
	},
	testimonials: [
		{
			text: 'As I add items to the calendar, I LOVE that an email automatically goes out weekly to notify residents of the new info.',
			source: {
				name: 'Elizabeth',
				hoa: 'Highlands of Haynes Creek',
				url: 'https://hohc.communitysite.com/',
			},
		},
	],
};

export default feature;
