// Define a page message
export interface PageMessage {
	color: 'success' | 'danger';
	title: string;
	message: string | React.ReactElement;
	dismissed?: boolean;
}


// Define user details
export interface UserDetails {
	id: number | null;
	name: string | null;
	firstName: string | null;
	lastName: string | null;
	email: string | null;
	profilePhotoURL: string | null;
}

interface UserState extends UserDetails {
	signedIn: boolean;
}


// Export initial state interface
export interface State {
	route: {
		lastChange: number;
	};
	user: UserState;
	pageMessages: PageMessage[];
	activeNetworkRequestCount: number;
}


// Define types/interfaces for actions
export const ROUTE_CHANGED = 'ROUTE_CHANGED';
export interface RouteChangedAction {
	type: typeof ROUTE_CHANGED;
}

export const SIGNED_IN = 'SIGNED_IN';
export interface SignedInAction extends UserDetails {
	type: typeof SIGNED_IN;
}

export const SIGNED_OUT = 'SIGNED_OUT';
export interface SignedOutAction {
	type: typeof SIGNED_OUT;
}

export const SHOW_PAGE_MESSAGE = 'SHOW_PAGE_MESSAGE';
export interface ShowPageMessageAction extends PageMessage {
	type: typeof SHOW_PAGE_MESSAGE;
}

export const HIDE_PAGE_MESSAGE = 'HIDE_PAGE_MESSAGE';
export interface HidePageMessageAction {
	type: typeof HIDE_PAGE_MESSAGE;
	index: number;
}

export const INCREMENT_NETWORK_REQUEST_COUNT = 'INCREMENT_NETWORK_REQUEST_COUNT';
export interface IncrementNetworkRequestCountAction {
	type: typeof INCREMENT_NETWORK_REQUEST_COUNT;
}

export const DECREMENT_NETWORK_REQUEST_COUNT = 'DECREMENT_NETWORK_REQUEST_COUNT';
export interface DecrementNetworkRequestCountAction {
	type: typeof DECREMENT_NETWORK_REQUEST_COUNT;
}


// Export union type
export type Actions =
	| RouteChangedAction
	| SignedInAction
	| SignedOutAction
	| ShowPageMessageAction
	| HidePageMessageAction
	| IncrementNetworkRequestCountAction
	| DecrementNetworkRequestCountAction;
