// Imports
import React from 'react';


// Function component
const Logo: React.FC = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='201'
		height='36'
		viewBox='0 0 201 36'
		fill='none'
		role='img'
		aria-labelledby='logo-svg-title'
	>
		<title id='logo-svg-title'>HOA Express logo</title>
		<g clipPath='url(#clip0_7_2)'>
			<path
				d='M112.146 6.75775V10.0846H105.251V16.1491H110.344V19.4413H105.251V26.5801H112.146V29.8723H101.682V6.75775H112.146Z'
				fill='#7AA9CC'
			/>
			<path
				d='M122.577 17.8125L127.983 29.8723H123.998L120.498 21.4859L117.207 29.8723H113.291L118.454 17.8125L113.499 6.75775H117.449L120.533 14.1738L123.409 6.75775H127.29L122.577 17.8125Z'
				fill='#7AA9CC'
			/>
			<path
				d='M133.759 20.5156V29.8723H130.19V6.75775H136.115C138.333 6.75775 139.961 7.27757 140.966 8.24789C142.179 9.42615 142.803 11.2282 142.803 13.654C142.803 16.0452 142.179 17.8472 140.966 19.0254C140.447 19.5453 139.788 19.9265 139.026 20.169C138.264 20.4116 137.293 20.5156 136.115 20.5156H133.759ZM133.759 17.2234H135.318C136.635 17.2234 137.571 17.0155 138.09 16.5996C138.783 16.0798 139.13 15.1095 139.13 13.654C139.13 12.1985 138.783 11.1935 138.09 10.7084C137.571 10.2925 136.635 10.0846 135.318 10.0846H133.759V17.2234Z'
				fill='#7AA9CC'
			/>
			<path
				d='M148.817 20.4116V29.8723H145.248V6.75775H150.861C152.836 6.75775 154.361 7.24291 155.435 8.17859C156.786 9.35684 157.479 11.0896 157.479 13.3768C157.479 16.3224 156.44 18.3323 154.361 19.4066L157.791 29.8723H154.014L150.965 20.4116H148.817ZM148.817 17.0848H150.134C151.104 17.0848 151.797 17.0155 152.213 16.8422C153.287 16.3917 153.807 15.2828 153.807 13.5847C153.807 12.3025 153.495 11.4015 152.906 10.8123C152.594 10.4658 152.178 10.2579 151.693 10.1885C151.242 10.1192 150.723 10.0846 150.134 10.0846H148.817V17.0848Z'
				fill='#7AA9CC'
			/>
			<path
				d='M170.922 6.75775V10.0846H164.027V16.1491H169.121V19.4413H164.027V26.5801H170.922V29.8723H160.458V6.75775H170.922Z'
				fill='#7AA9CC'
			/>
			<path
				d='M185.512 12.1985L182.082 13.2381C181.527 10.9163 180.522 9.77269 178.998 9.77269C178.374 9.77269 177.82 10.0153 177.404 10.4311C176.919 10.8816 176.676 11.4361 176.676 12.1292C176.676 12.9956 177.023 13.758 177.646 14.451C178.062 14.9016 179.102 15.7679 180.73 16.9808C182.393 18.2977 183.537 19.3027 184.161 20.0997C185.165 21.3126 185.65 22.6988 185.65 24.3276C185.65 26.0256 185.096 27.4465 183.953 28.5208C182.775 29.6644 181.25 30.2188 179.344 30.2188C175.394 30.2188 173.038 28.0356 172.31 23.7038L175.741 22.6988C176.191 25.4712 177.404 26.8573 179.344 26.8573C180.141 26.8573 180.799 26.6148 181.25 26.1296C181.735 25.6444 181.978 24.986 181.978 24.2236C181.978 23.3919 181.666 22.6642 181.077 21.9711C180.592 21.4166 179.725 20.6542 178.547 19.7185C176.815 18.367 175.741 17.5353 175.394 17.1888C173.8 15.6293 173.003 13.9659 173.003 12.1292C173.003 10.2925 173.662 8.87167 174.944 7.79739C176.052 6.89637 177.404 6.44586 179.032 6.44586C182.359 6.44586 184.507 8.35186 185.512 12.1985Z'
				fill='#7AA9CC'
			/>
			<path
				d='M200.282 12.1985L196.852 13.2381C196.297 10.9163 195.292 9.77269 193.768 9.77269C193.144 9.77269 192.59 10.0153 192.174 10.4311C191.689 10.8816 191.446 11.4361 191.446 12.1292C191.446 12.9956 191.793 13.758 192.416 14.451C192.832 14.9016 193.872 15.7679 195.5 16.9808C197.163 18.2977 198.307 19.3027 198.931 20.0997C199.935 21.3126 200.421 22.6988 200.421 24.3276C200.421 26.0256 199.866 27.4465 198.723 28.5208C197.545 29.6644 196.02 30.2188 194.114 30.2188C190.164 30.2188 187.808 28.0356 187.08 23.7038L190.511 22.6988C190.961 25.4712 192.174 26.8573 194.114 26.8573C194.911 26.8573 195.57 26.6148 196.02 26.1296C196.505 25.6444 196.748 24.986 196.748 24.2236C196.748 23.3919 196.436 22.6642 195.847 21.9711C195.362 21.4166 194.495 20.6542 193.317 19.7185C191.585 18.367 190.511 17.5353 190.164 17.1888C188.57 15.6293 187.773 13.9659 187.773 12.1292C187.773 10.2925 188.432 8.87167 189.714 7.79739C190.822 6.89637 192.174 6.44586 193.802 6.44586C197.129 6.44586 199.277 8.35186 200.282 12.1985Z'
				fill='#7AA9CC'
			/>
			<path
				d='M58.3347 19.7185H53.2758V29.8723H49.7068V6.75775H53.2758V16.3917H58.3347V6.75775H61.9383V29.8723H58.3347V19.7185Z'
				fill='#1C6296'
			/>
			<path
				d='M77.8115 14.1045V22.5255C77.8115 23.8424 77.7076 24.882 77.4997 25.7484C77.2918 26.5801 76.9453 27.3425 76.4948 28.0009C75.4207 29.4564 73.7575 30.2188 71.5052 30.2188C69.253 30.2188 67.5898 29.4564 66.5156 28.0009C65.9612 27.2039 65.5801 26.3375 65.4068 25.3672C65.2682 24.5008 65.1989 23.5305 65.1989 22.5255V14.1045C65.1989 12.8223 65.3029 11.748 65.5108 10.9163C65.7187 10.0499 66.0305 9.32218 66.5156 8.66375C67.5898 7.17361 69.253 6.44586 71.5052 6.44586C73.7575 6.44586 75.4207 7.17361 76.4948 8.66375C77.0492 9.42615 77.4304 10.3272 77.569 11.2975C77.7422 12.1639 77.8115 13.0995 77.8115 14.1045ZM68.7679 14.1738V22.4562C68.7679 23.5652 68.8372 24.3276 68.9065 24.7434C69.253 26.1643 70.1192 26.8573 71.5052 26.8573C72.5794 26.8573 73.3417 26.4415 73.7575 25.6098C74 25.1593 74.1386 24.6741 74.1733 24.1196C74.2079 23.6691 74.2079 23.1147 74.2079 22.4562V14.1738C74.2079 13.0649 74.1733 12.3025 74.0693 11.8866C73.7575 10.5004 72.8912 9.77269 71.5052 9.77269C70.4311 9.77269 69.6688 10.1885 69.253 11.0549C69.0104 11.5054 68.8718 11.9906 68.8372 12.5104C68.8025 12.9609 68.7679 13.5154 68.7679 14.1738Z'
				fill='#1C6296'
			/>
			<path
				d='M89.3954 24.8474H84.4751L83.5396 29.8723H80.0399L84.4751 6.75775H89.3608L94.0385 29.8723H90.3656L89.3954 24.8474ZM88.7717 21.5552L86.9006 11.1935L85.0295 21.5552H88.7717Z'
				fill='#1C6296'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M27.4408 21.7563C27.4408 21.2322 27.0153 20.8066 26.491 20.8066H26.4906C25.9664 20.8066 25.5408 21.2322 25.5408 21.7563V30.3363C25.5408 30.8605 25.9664 31.2861 26.4906 31.2861H26.491C27.0153 31.2861 27.4408 30.8605 27.4408 30.3363V21.7563Z'
				fill='white'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M27.4408 21.7578C27.4408 21.2328 27.0145 20.8066 26.4895 20.8066H20.0759C19.5509 20.8066 19.1246 21.2328 19.1246 21.7578C19.1246 22.2828 19.5509 22.709 20.0759 22.709H26.4895C27.0145 22.709 27.4408 22.2828 27.4408 21.7578Z'
				fill='white'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M21.0246 21.7563C21.0246 21.2322 20.599 20.8066 20.0748 20.8066H20.0745C19.5502 20.8066 19.1246 21.2322 19.1246 21.7563V30.3363C19.1246 30.8605 19.5502 31.2861 20.0745 31.2861H20.0748C20.599 31.2861 21.0246 30.8605 21.0246 30.3363V21.7563Z'
				fill='white'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M36.1312 9.05944C36.1312 8.12939 35.376 7.37433 34.4459 7.37433H34.4457C33.5156 7.37433 32.7604 8.12939 32.7604 9.05944V30.8072C32.7604 31.7373 33.5156 32.4924 34.4457 32.4924H34.4459C35.376 32.4924 36.1312 31.7373 36.1312 30.8072V9.05944Z'
				fill='white'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M13.6805 9.05944C13.6805 8.12939 12.9254 7.37433 11.9952 7.37433H11.9951C11.0649 7.37433 10.3098 8.12939 10.3098 9.05944V30.8072C10.3098 31.7373 11.0649 32.4924 11.9951 32.4924H11.9952C12.9254 32.4924 13.6805 31.7373 13.6805 30.8072V9.05944Z'
				fill='white'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M21.8028 1.73113C22.2681 0.92391 23.2988 0.647337 24.1049 1.11339L39.1962 9.8392C40.0021 10.3052 40.2783 11.3374 39.8129 12.1447C39.3476 12.9519 38.3169 13.2285 37.5108 12.7624L22.4197 4.03658C21.6136 3.57053 21.3374 2.53835 21.8028 1.73113Z'
				fill='white'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M24.6378 1.73113C24.1725 0.92391 23.1418 0.647337 22.3358 1.11339L7.24459 9.8392C6.43854 10.3052 6.16238 11.3374 6.62775 12.1447C7.09311 12.9519 8.12377 13.2285 8.9298 12.7624L24.021 4.03658C24.8271 3.57053 25.1032 2.53835 24.6378 1.73113Z'
				fill='white'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M1.11729 33.6601C15.7103 29.0779 31.3541 29.0779 45.9471 33.6601C46.369 33.7845 46.6037 34.2089 46.4619 34.6092C46.4585 34.6212 46.4547 34.6329 46.4507 34.6445C46.4467 34.6561 46.4424 34.6675 46.4378 34.6787C46.4287 34.7012 46.4184 34.7228 46.4071 34.7437C46.3845 34.7855 46.3579 34.8242 46.3276 34.8594C46.2669 34.93 46.1918 34.9872 46.1064 35.0294C45.9356 35.1136 45.7235 35.1373 45.5009 35.0863C31.1984 30.5954 15.866 30.5954 1.56349 35.0863C1.18044 35.1673 0.738815 34.9826 0.602501 34.6092C0.481094 34.2018 0.708928 33.78 1.11729 33.6601Z'
				fill='white'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M27.4408 21.7563C27.4408 21.2322 27.0153 20.8066 26.491 20.8066H26.4906C25.9664 20.8066 25.5408 21.2322 25.5408 21.7563V30.3363C25.5408 30.8605 25.9664 31.2861 26.4906 31.2861H26.491C27.0153 31.2861 27.4408 30.8605 27.4408 30.3363V21.7563Z'
				fill='#67D91D'
				fillOpacity='0.7'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M27.4408 21.7578C27.4408 21.2328 27.0145 20.8066 26.4895 20.8066H20.0759C19.5509 20.8066 19.1246 21.2328 19.1246 21.7578C19.1246 22.2828 19.5509 22.709 20.0759 22.709H26.4895C27.0145 22.709 27.4408 22.2828 27.4408 21.7578Z'
				fill='#59BB19'
				fillOpacity='0.7'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M21.0246 21.7563C21.0246 21.2322 20.599 20.8066 20.0748 20.8066H20.0745C19.5502 20.8066 19.1246 21.2322 19.1246 21.7563V30.3363C19.1246 30.8605 19.5502 31.2861 20.0745 31.2861H20.0748C20.599 31.2861 21.0246 30.8605 21.0246 30.3363V21.7563Z'
				fill='#479515'
				fillOpacity='0.7'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M36.1312 9.05944C36.1312 8.12939 35.376 7.37433 34.4458 7.37433H34.4456C33.5156 7.37433 32.7604 8.12939 32.7604 9.05944V30.8072C32.7604 31.7373 33.5156 32.4924 34.4456 32.4924H34.4458C35.376 32.4924 36.1312 31.7373 36.1312 30.8072V9.05944Z'
				fill='#68D91E'
				fillOpacity='0.7'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M13.6805 9.05944C13.6805 8.12939 12.9254 7.37433 11.9952 7.37433H11.9951C11.0649 7.37433 10.3098 8.12939 10.3098 9.05944V30.8072C10.3098 31.7373 11.0649 32.4924 11.9951 32.4924H11.9952C12.9254 32.4924 13.6805 31.7373 13.6805 30.8072V9.05944Z'
				fill='#3A7910'
				fillOpacity='0.7'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M21.8028 1.73113C22.2681 0.92391 23.2988 0.647337 24.1049 1.11339L39.1962 9.8392C40.0021 10.3052 40.2783 11.3374 39.8129 12.1447C39.3476 12.9519 38.3169 13.2285 37.5108 12.7624L22.4197 4.03658C21.6136 3.57053 21.3374 2.53835 21.8028 1.73113Z'
				fill='#59BB1A'
				fillOpacity='0.7'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M24.6378 1.73113C24.1725 0.92391 23.1418 0.647337 22.3358 1.11339L7.24459 9.8392C6.43854 10.3052 6.16238 11.3374 6.62775 12.1447C7.09311 12.9519 8.12377 13.2285 8.9298 12.7624L24.021 4.03658C24.8271 3.57053 25.1032 2.53835 24.6378 1.73113Z'
				fill='#489514'
				fillOpacity='0.7'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M1.11729 33.6601C15.7103 29.0779 31.3541 29.0779 45.9471 33.6601C46.369 33.7845 46.6037 34.2089 46.4619 34.6092C46.4585 34.6212 46.4547 34.6329 46.4507 34.6445C46.4467 34.6561 46.4424 34.6675 46.4378 34.6787C46.4287 34.7012 46.4184 34.7228 46.4071 34.7437C46.3845 34.7855 46.3579 34.8242 46.3276 34.8594C46.2669 34.93 46.1918 34.9872 46.1064 35.0294C45.9356 35.1136 45.7235 35.1373 45.5009 35.0863C31.1984 30.5954 15.866 30.5954 1.56349 35.0863C1.18044 35.1673 0.738815 34.9826 0.602501 34.6092C0.481094 34.2018 0.708928 33.78 1.11729 33.6601Z'
				fill='#DCB983'
			/>
		</g>
		<defs>
			<clipPath id='clip0_7_2'>
				<rect width='200' height='34.288' fill='white' transform='translate(0.5 0.855988)' />
			</clipPath>
		</defs>
	</svg>
);

export default Logo;
