// Imports
import Theme from '../types/theme';
import { setSaturation, setLightness } from 'polished';


// Export
const theme: Theme = {
	breakpoints: {
		mobile: 767,
		tablet: 1024,
		desktop: 1199,
		widescreen: 1399,
	},
	colors: {
		primary: '#1c5f97',
		accent: '#7fb55b',
		background: 'dynamic',
		danger: '#e64560',
		success: '#83cc52',
		info: '#dcb983',
		link: 'dynamic',
		yellow: '#ffdd57',
	},
};

theme.colors.link = setSaturation(0.43, setLightness(0.49, theme.colors.primary));
theme.colors.background = setSaturation(0.07, setLightness(0.95, theme.colors.primary));

export default theme;
