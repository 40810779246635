// Imports
import React from 'react';
import styled from 'styled-components/macro';
import SimplePageTemplate from '../../resources/simple-page-template';


// Styled components
const Container = styled.div`
	text-align: center;
	max-width: 37.5rem;
`;

const Hiring = styled.h2`
	font-size: 2rem;
	font-weight: 600;
	
	padding-top: 3.75rem;
	margin-bottom: 1rem;
`;


// Function component
const Careers: React.FC = () => (
	<SimplePageTemplate heading='Careers' descriptor='Interested in joining our team?'>
		<Container>
			<Hiring>We might be hiring!</Hiring>
			
			<p>
				All hiring across the Sparksuite family of companies is handled by Sparksuite. Check{' '}
				<a href='https://sparksuite.careers/' target='_blank' rel='noopener noreferrer'>
					Sparksuite’s website
				</a>{' '}
				for openings.
			</p>
		</Container>
	</SimplePageTemplate>
);

export default Careers;
