// Imports
import specialSizes from './special-sizes';
import subscriptionSizes from './subscription-sizes';


/** A mapping of website sizes to their respective email account limits */
const emailAccountLimitMapping: {
	[size in (typeof subscriptionSizes)[number] | typeof specialSizes.LEGACY | typeof specialSizes.BIG_DATA]: number;
} = {
	5000: 35,
	2500: 35,
	1000: 30,
	500: 30,
	300: 25,
	200: 25,
	125: 20,
	75: 20,
	50: 15,
	25: 15,
	65535: 5, // Big data
	9999: 25, // Legacy deluxe plan
};

export default emailAccountLimitMapping;


/** Determines whether a particular size is valid for using with the mapping */
export function sizeIsValidForEmailAccountLimitMapping(size: number): size is keyof typeof emailAccountLimitMapping {
	return Object.keys(emailAccountLimitMapping).includes(String(size));
}
