// Imports
import styled from 'styled-components/macro';
import BannerWithList from '../../../resources/banner-with-list';
import items from './items';


// Styled components
const StyledBannerWithList = styled(BannerWithList)`
	margin-top: 0;
	
	padding: 3.75rem 0;
	margin: 0 3.25rem;
	
	@media (max-width: 48rem) {
		padding: 3.75rem 2.5rem;
		margin: 0;
	}
`;


// Function component
const Numbers: React.FC = () => <StyledBannerWithList items={items} />;

export default Numbers;
