// Imports
import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import SimplePageTemplate from '../../resources/simple-page-template';


// Styled components
const Container = styled.div`
	text-align: center;
`;

const GetBackOnTrack = styled.h2`
	font-size: 2rem;
	font-weight: 600;
	
	padding-top: 3.75rem;
	margin-bottom: 1rem;
`;


// Function component
const NotFound: React.FC = () => (
	<SimplePageTemplate heading='Page not found' descriptor='We can’t find the page you’re looking for'>
		<Container>
			<GetBackOnTrack>Get back on track</GetBackOnTrack>
			
			<p>
				Use the links above or head to our <Link to='/'>homepage</Link>.
			</p>
		</Container>
	</SimplePageTemplate>
);

export default NotFound;
