// Imports
import Banner from './banner';
import FeatureSpotlight from './spotlight';
import styled from 'styled-components/macro';
import FeaturePitch from './pitch';
import Tiles from './tiles';
import FeatureQuotes from './quotes';
import Quotes from '../../resources/quotes';


// Styled components
const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;


// Define the accepted props
interface Props {
	banner: React.ComponentProps<typeof Banner>;
	pitch: {
		text: string | JSX.Element;
		textMaxWidth: string;
	};
	tiles: React.ComponentProps<typeof Tiles>;
	testimonials: React.ComponentProps<typeof Quotes>['quotes'];
}


// Function component
const Feature: React.FC<Props> = ({ banner, pitch, tiles, testimonials }) => (
	<Wrapper>
		<Banner {...banner} />
		
		<FeatureSpotlight />
		
		<FeaturePitch textMaxWidth={pitch.textMaxWidth}>{pitch.text}</FeaturePitch>
		
		<Tiles {...tiles} />
		
		<FeatureQuotes quotes={testimonials} centered />
	</Wrapper>
);

export default Feature;
