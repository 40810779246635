// Imports
import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import ScrollBehaviorContext, { ContextDetails } from '../../../shareables/contexts/scroll-behavior';
import SimplePageTemplate from '../simple-page-template';
import StickyNavbar from './navbar';


// Styled components
const Container = styled.div<{ gap: number }>`
	display: flex;
	gap: ${({ gap }) => gap}rem;
	width: 100%;
	
	padding-top: 3.75rem;
	
	@media (max-width: 48rem) {
		flex-direction: column;
		gap: 2.5rem;
		padding-top: 2.5rem;
	}
`;


// Define the accepted props
type Props = React.ComponentProps<typeof StickyNavbar> &
	React.ComponentProps<typeof SimplePageTemplate> & { spaceBetweenNavbarAndContent: number };


// Function component
const SectionWithStickyNavbar: React.FC<Props> = ({ heading, descriptor, links, spaceBetweenNavbarAndContent }) => {
	// Use React Router functionality
	const location = useLocation();
	
	
	// Use context
	const context = useContext(ScrollBehaviorContext);
	
	
	// Implement scroll behavior
	let overrideScrollBehavior: NonNullable<ContextDetails>['overrideScrollBehavior'] | null = null;
	
	if (context) {
		overrideScrollBehavior = context.overrideScrollBehavior;
	}
	
	useEffect(
		() =>
			overrideScrollBehavior?.(() => {
				// Scroll to element if specified, otherwise scroll to top
				if (location.hash && document.querySelector(location.hash)) {
					window.scrollTo({ top: document.querySelector(location.hash)?.scrollHeight ?? 0, left: 0 });
				} else {
					window.scrollTo(0, 0);
				}
			}),
		[location.hash, overrideScrollBehavior]
	);
	
	
	// Return JSX
	return (
		<SimplePageTemplate heading={heading} descriptor={descriptor}>
			<Container gap={spaceBetweenNavbarAndContent}>
				<StickyNavbar links={links} />
				
				<Outlet />
			</Container>
		</SimplePageTemplate>
	);
};

export default SectionWithStickyNavbar;
