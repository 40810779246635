// Imports
import { Route } from 'react-router-dom';
import Policies from '.';
import OurGuarantee from './our-guarantee';
import PrivacyPolicy from './privacy-policy';
import TermsOfService from './terms-of-service';


// Function component
const sectionRoutes = (
	<Route path='policies' element={<Policies />}>
		<Route path='our-guarantee' element={<OurGuarantee />} />
		<Route path='privacy-policy' element={<PrivacyPolicy />} />
		<Route path='terms-of-service' element={<TermsOfService />} />
	</Route>
);

export default sectionRoutes;
