// Imports
import { useMemo } from 'react';
import styled from 'styled-components/macro';
import { Rating as Props } from './ratings';
import Star from './star';


// Styled components
const Wrapper = styled.figure`
	text-align: center;
	color: hsl(206, 25%, 20%);
	
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.62rem;
`;

const Stars = styled.div`
	display: flex;
	gap: 0.5rem;
	
	font-size: 1.25rem;
	height: 1.25rem;
	line-height: 1;
`;

const Company = styled.a`
	color: inherit;
	text-decoration: underline;
	
	&:hover {
		text-decoration: none;
	}
`;

const Caption = styled.figcaption`
	line-height: 1.1875rem;
`;

const Subtext = styled.small`
	display: block;
	font-size: 0.75rem;
	text-align: center;
`;


// Function component
const Rating: React.FC<Props> = ({ rating, site }) => {
	const stars = useMemo(() => {
		const result: JSX.Element[] = [];
		
		for (let i = 0; i < 5; i += 1) {
			let fill = rating - i;
			
			if (fill >= 1) {
				fill = 1;
			} else {
				fill = fill <= 0 ? 0 : Math.round(fill / 0.5) * 0.5;
			}
			
			result.push(<Star key={i} percentage={fill} />);
		}
		
		return result;
	}, [rating]);
	
	return (
		<Wrapper>
			<Stars>{stars}</Stars>
			<Caption>
				{rating.toLocaleString()} on{' '}
				{site.url ? (
					<Company href={site.url} target='_blank' rel='noopener noreferrer'>
						{site.name}
					</Company>
				) : (
					site.name
				)}
				{site.subtext && <Subtext>{site.subtext}</Subtext>}
			</Caption>
		</Wrapper>
	);
};

export default Rating;
