// Imports
import styled from 'styled-components/macro';
import { GetOnlinePaymentFeesResponse } from '../../../../shareables/types/api/main/countries/online-payment-fees/get';
import formatCurrency from '../../../../shareables/utils/project-specific/formatting/format-currency';
import PricingDetailsTable from '../table';


// Styled components
const Label = styled.td`
	&& {
		white-space: normal;
	}
`;


// Define the accepted props
interface Props {
	country: GetOnlinePaymentFeesResponse[0];
}


// Function component
const OnlinePaymentsTable: React.FC<Props> = ({ country }) => (
	<PricingDetailsTable>
		<thead>
			<tr>
				<th>{country.name}</th>
				<th>Fee</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<Label>Transaction fee for credit/debit cards</Label>
				<td>
					{country.cardFees.percentage ? `${country.cardFees.percentage}%` : ''}
					{country.cardFees.percentage && country.cardFees.fixed ? ' + ' : ''}
					{country.cardFees.fixed
						? formatCurrency(country.cardFees.fixed, { currency: country.currencyCode, hideCentsIfInteger: true })
						: ''}
				</td>
			</tr>
			{country.bankAccountFees && (
				<tr>
					<Label>Transaction fee for bank accounts</Label>
					<td>
						{country.bankAccountFees.percentage ? `${country.bankAccountFees.percentage}%` : ''}
						{country.bankAccountFees.percentage && country.bankAccountFees.fixed ? ' + ' : ''}
						{country.bankAccountFees.fixed
							? formatCurrency(country.bankAccountFees.fixed, {
									currency: country.currencyCode,
									hideCentsIfInteger: true,
							  })
							: ''}
					</td>
				</tr>
			)}
			<tr>
				<Label>Transaction fee for international cards</Label>
				<td>
					{country.extraFees.internationalCardFees.percentage
						? `${country.extraFees.internationalCardFees.percentage}%`
						: ''}
					{country.extraFees.internationalCardFees.percentage && country.extraFees.internationalCardFees.fixed
						? ' + '
						: ''}
					{country.extraFees.internationalCardFees.fixed
						? formatCurrency(country.extraFees.internationalCardFees.fixed, {
								currency: country.currencyCode,
								hideCentsIfInteger: true,
						  })
						: ''}{' '}
					additional
				</td>
			</tr>
			<tr>
				<Label>Chargeback fee</Label>
				<td>
					{formatCurrency(country.extraFees.chargeback, { currency: country.currencyCode, hideCentsIfInteger: true })}
				</td>
			</tr>
			
			{Boolean(country.extraFees.failedACHPayment) && (
				<tr>
					<Label>Failed bank account payment fee</Label>
					<td>
						{formatCurrency(country.extraFees.failedACHPayment ?? 0, {
							currency: country.currencyCode,
							hideCentsIfInteger: true,
						})}
					</td>
				</tr>
			)}
		</tbody>
	</PricingDetailsTable>
);

export default OnlinePaymentsTable;
