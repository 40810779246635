// Imports
import React from 'react';
import SectionWithStickyNavbar from '../../resources/section-with-sticky-navbar';


// Function component
const PricingDetails: React.FC = () => (
	<SectionWithStickyNavbar
		heading='Pricing details'
		descriptor='Additional details for specific features'
		links={[
			{ to: '/pricing-details/text-messages', text: 'Text messages' },
			{ to: '/pricing-details/mailed-letters', text: 'Mailed letters' },
			{ to: '/pricing-details/online-payments', text: 'Online payments' },
		]}
		spaceBetweenNavbarAndContent={3.75}
	/>
);

export default PricingDetails;
