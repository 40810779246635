// Imports
import Logo from './logo';


// Function component
const AppleValleyPropertyOwnersAssociation: React.FC = () => (
	<Logo width={10.4} aria-label='Apple Valley Property Owners Association'>
		<svg
			clipRule='evenodd'
			fillRule='evenodd'
			strokeLinejoin='round'
			strokeMiterlimit='2'
			viewBox='0 0 519 125'
			xmlns='http://www.w3.org/2000/svg'
			aria-hidden
		>
			<g fillRule='nonzero'>
				<path
					d='m.33-.541c-.028.049-.054.096-.078.142s-.047.091-.068.134h.206c-.007-.037-.015-.077-.024-.12-.009-.042-.021-.094-.036-.156zm.082-.109c.008.078.023.17.045.276.022.107.05.221.084.342l.08.016-.007.021c-.02-.002-.04-.003-.06-.004s-.044-.001-.071-.001c-.022 0-.044 0-.067.001s-.047.002-.071.004l.008-.021.076-.016c-.003-.023-.006-.051-.011-.083-.005-.033-.012-.073-.021-.12h-.227c-.024.047-.043.087-.056.119-.014.033-.024.061-.031.084l.081.016-.007.021c-.018-.002-.035-.003-.053-.004-.017-.001-.038-.001-.061-.001-.028 0-.051 0-.069.001s-.036.002-.054.004l.007-.021.074-.016c.1-.16.178-.293.236-.399.057-.106.086-.169.086-.19 0-.001 0-.002 0-.003-.001 0-.001-.001-.001-.001z'
					transform='matrix(104.964 0 0 104.964 8.3909998 82.4262295)'
				/>
				<path
					d='m.136-.04c.01.003.019.005.028.006.008.001.017.002.025.002.029 0 .054-.007.074-.02s.037-.033.052-.062c.014-.027.025-.061.034-.1.009-.04.013-.078.013-.114 0-.017-.002-.029-.006-.036s-.01-.011-.018-.011c-.028 0-.065.034-.111.102-.045.068-.076.146-.091.233zm.029-.187c.046-.067.092-.122.139-.163s.085-.061.113-.061c.015 0 .027.007.036.022.008.015.013.035.013.061 0 .053-.009.103-.028.151-.018.048-.044.089-.078.124-.031.032-.066.058-.104.076-.038.019-.075.028-.111.028 0 0-.002 0-.006-.001-.004 0-.007 0-.011 0-.016.106-.055.19-.117.254s-.135.096-.22.096c-.026 0-.047-.005-.062-.015s-.022-.023-.022-.04c0-.007.002-.014.005-.022.004-.007.009-.014.015-.022.02.015.041.027.065.035.023.008.046.012.069.012.044 0 .08-.016.105-.049.026-.033.047-.092.065-.175.018-.087.033-.171.044-.254s.017-.139.017-.17c0-.009-.001-.015-.003-.02-.002-.004-.005-.006-.009-.006-.005 0-.013.004-.023.011-.01.008-.02.018-.031.031l-.014-.014c.032-.036.061-.064.086-.083.026-.02.047-.03.062-.03.01 0 .018.004.023.011.005.008.008.019.008.034 0 .016-.002.04-.007.071-.004.032-.011.068-.019.108z'
					transform='matrix(104.964 0 0 104.964 75.6851696 82.4262295)'
				/>
				<path
					d='m.136-.04c.01.003.019.005.028.006.008.001.017.002.025.002.029 0 .054-.007.074-.02s.037-.033.052-.062c.014-.027.025-.061.034-.1.009-.04.013-.078.013-.114 0-.017-.002-.029-.006-.036s-.01-.011-.018-.011c-.028 0-.065.034-.111.102-.045.068-.076.146-.091.233zm.029-.187c.046-.067.092-.122.139-.163s.085-.061.113-.061c.015 0 .027.007.036.022.008.015.013.035.013.061 0 .053-.009.103-.028.151-.018.048-.044.089-.078.124-.031.032-.066.058-.104.076-.038.019-.075.028-.111.028 0 0-.002 0-.006-.001-.004 0-.007 0-.011 0-.016.106-.055.19-.117.254s-.135.096-.22.096c-.026 0-.047-.005-.062-.015s-.022-.023-.022-.04c0-.007.002-.014.005-.022.004-.007.009-.014.015-.022.02.015.041.027.065.035.023.008.046.012.069.012.044 0 .08-.016.105-.049.026-.033.047-.092.065-.175.018-.087.033-.171.044-.254s.017-.139.017-.17c0-.009-.001-.015-.003-.02-.002-.004-.005-.006-.009-.006-.005 0-.013.004-.023.011-.01.008-.02.018-.031.031l-.014-.014c.032-.036.061-.064.086-.083.026-.02.047-.03.062-.03.01 0 .018.004.023.011.005.008.008.019.008.034 0 .016-.002.04-.007.071-.004.032-.011.068-.019.108z'
					transform='matrix(104.964 0 0 104.964 128.781209 82.4262295)'
				/>
				<path
					d='m.395-.697c-.017-.009-.033-.016-.047-.021s-.027-.007-.038-.007c-.029 0-.051.013-.067.041-.017.027-.035.089-.054.185-.018.088-.032.17-.043.245-.011.076-.016.124-.016.145 0 .007.001.012.003.017.002.004.005.006.008.006.005 0 .009-.002.015-.005.005-.003.014-.012.028-.026l.015.014c-.029.035-.056.062-.08.081-.024.019-.044.029-.058.029-.01 0-.017-.004-.023-.012-.005-.007-.008-.018-.008-.032 0-.018.002-.048.008-.088.005-.04.012-.088.022-.144.014-.082.027-.146.038-.191.012-.046.025-.085.038-.117.025-.059.059-.108.103-.147.045-.04.086-.059.125-.059.015 0 .027.003.036.01.008.007.012.016.012.029 0 .008-.001.017-.004.025-.003.007-.008.015-.013.022z'
					transform='matrix(104.964 0 0 104.964 181.8798725 82.4262295)'
				/>
				<path
					d='m.125-.233c-.004.018-.006.035-.008.05-.002.016-.003.03-.003.042 0 .025.004.044.013.057.01.013.023.02.04.02.016 0 .034-.006.053-.019.02-.014.041-.033.064-.059l.017.016c-.033.041-.068.075-.107.1-.039.026-.073.038-.101.038-.026 0-.045-.008-.059-.025-.013-.016-.02-.04-.02-.071 0-.046.008-.093.024-.143.016-.049.035-.089.059-.119s.053-.055.088-.076c.035-.022.065-.032.089-.032.019 0 .035.005.046.016.01.01.016.025.016.044 0 .033-.02.065-.061.097-.04.032-.09.053-.15.064zm.005-.027c.033 0 .06-.009.081-.029.022-.019.032-.043.032-.072 0-.013-.002-.023-.008-.03-.005-.007-.013-.01-.023-.01-.018 0-.034.012-.048.036-.014.025-.025.06-.034.105z'
					transform='matrix(104.964 0 0 104.964 208.326427 82.4262295)'
				/>
				<path
					d='m.012-.609.007-.021c.025.003.048.005.069.006.022.001.041.002.057.002.021 0 .06-.002.115-.006.013-.001.022-.002.028-.002l-.007.021-.077.016c.006.061.017.133.033.216s.037.174.063.273c.066-.118.119-.216.158-.297.039-.08.066-.144.081-.192l-.081-.016.007-.021c.028.003.051.005.068.006.018.001.033.002.045.002.019 0 .053-.002.102-.006.01-.001.018-.002.024-.002l-.007.021-.074.016c-.081.122-.15.235-.209.34-.059.104-.099.185-.121.244l-.076.022c-.01-.075-.025-.166-.047-.271-.021-.105-.048-.216-.078-.335z'
					transform='matrix(104.964 0 0 104.964 276.0441998 82.4262295)'
				/>
				<path
					d='m.322-.39c-.011 0-.024-.001-.039-.002-.016-.001-.026-.002-.032-.002-.024 0-.044.005-.059.016-.015.01-.027.027-.038.051-.013.029-.024.066-.034.111-.009.044-.014.083-.014.116 0 .011.002.02.005.026.003.005.007.008.013.008.024 0 .059-.033.104-.099s.077-.141.094-.225zm-.034.182c-.042.064-.086.116-.132.156-.045.04-.081.06-.107.06-.012 0-.021-.006-.027-.017s-.009-.028-.009-.05c0-.119.032-.213.095-.285.063-.071.146-.106.251-.106h.021l.045-.001c-.019.067-.035.133-.046.197s-.016.113-.016.147c0 .01.001.017.003.021.002.005.005.007.01.007.004 0 .008-.002.012-.006.005-.003.013-.012.026-.026l.016.014c-.027.032-.052.058-.077.077-.025.018-.044.028-.059.028-.01 0-.017-.004-.023-.012-.005-.008-.008-.019-.008-.034 0-.014.002-.035.006-.062.004-.028.01-.063.019-.108z'
					transform='matrix(104.964 0 0 104.964 330.73000645 82.4262295)'
				/>
				<path
					d='m.395-.697c-.017-.009-.033-.016-.047-.021s-.027-.007-.038-.007c-.029 0-.051.013-.067.041-.017.027-.035.089-.054.185-.018.088-.032.17-.043.245-.011.076-.016.124-.016.145 0 .007.001.012.003.017.002.004.005.006.008.006.005 0 .009-.002.015-.005.005-.003.014-.012.028-.026l.015.014c-.029.035-.056.062-.08.081-.024.019-.044.029-.058.029-.01 0-.017-.004-.023-.012-.005-.007-.008-.018-.008-.032 0-.018.002-.048.008-.088.005-.04.012-.088.022-.144.014-.082.027-.146.038-.191.012-.046.025-.085.038-.117.025-.059.059-.108.103-.147.045-.04.086-.059.125-.059.015 0 .027.003.036.01.008.007.012.016.012.029 0 .008-.001.017-.004.025-.003.007-.008.015-.013.022z'
					transform='matrix(104.964 0 0 104.964 380.9041105 82.4262295)'
				/>
				<path
					d='m.395-.697c-.017-.009-.033-.016-.047-.021s-.027-.007-.038-.007c-.029 0-.051.013-.067.041-.017.027-.035.089-.054.185-.018.088-.032.17-.043.245-.011.076-.016.124-.016.145 0 .007.001.012.003.017.002.004.005.006.008.006.005 0 .009-.002.015-.005.005-.003.014-.012.028-.026l.015.014c-.029.035-.056.062-.08.081-.024.019-.044.029-.058.029-.01 0-.017-.004-.023-.012-.005-.007-.008-.018-.008-.032 0-.018.002-.048.008-.088.005-.04.012-.088.022-.144.014-.082.027-.146.038-.191.012-.046.025-.085.038-.117.025-.059.059-.108.103-.147.045-.04.086-.059.125-.059.015 0 .027.003.036.01.008.007.012.016.012.029 0 .008-.001.017-.004.025-.003.007-.008.015-.013.022z'
					transform='matrix(104.964 0 0 104.964 407.350665 82.4262295)'
				/>
				<path
					d='m.125-.233c-.004.018-.006.035-.008.05-.002.016-.003.03-.003.042 0 .025.004.044.013.057.01.013.023.02.04.02.016 0 .034-.006.053-.019.02-.014.041-.033.064-.059l.017.016c-.033.041-.068.075-.107.1-.039.026-.073.038-.101.038-.026 0-.045-.008-.059-.025-.013-.016-.02-.04-.02-.071 0-.046.008-.093.024-.143.016-.049.035-.089.059-.119s.053-.055.088-.076c.035-.022.065-.032.089-.032.019 0 .035.005.046.016.01.01.016.025.016.044 0 .033-.02.065-.061.097-.04.032-.09.053-.15.064zm.005-.027c.033 0 .06-.009.081-.029.022-.019.032-.043.032-.072 0-.013-.002-.023-.008-.03-.005-.007-.013-.01-.023-.01-.018 0-.034.012-.048.036-.014.025-.025.06-.034.105z'
					transform='matrix(104.964 0 0 104.964 433.7972195 82.4262295)'
				/>
				<path
					d='m.456-.455c-.019.07-.036.16-.051.269-.016.109-.026.177-.031.204-.014.072-.033.129-.058.173s-.059.08-.102.111c-.025.017-.053.031-.084.042-.032.01-.062.015-.09.015-.027 0-.048-.005-.062-.015-.015-.009-.022-.023-.022-.041 0-.006.002-.013.005-.02s.008-.014.014-.022c.02.015.042.027.065.035.022.008.045.012.068.012.094 0 .165-.164.213-.492l.005-.034c-.047.067-.095.121-.143.163-.048.041-.086.062-.113.062-.009 0-.015-.003-.02-.01-.005-.006-.007-.015-.007-.027 0-.027.008-.077.023-.15.016-.073.024-.127.024-.162 0-.009-.001-.014-.003-.017-.001-.003-.004-.005-.009-.005-.003 0-.009.003-.017.01-.008.006-.017.014-.027.025l-.014-.013c.027-.033.053-.059.079-.078.026-.02.047-.029.063-.029.009 0 .016.003.02.009.005.005.007.014.007.025 0 .038-.008.094-.023.168-.016.073-.024.122-.024.146 0 .01.001.017.003.02s.006.005.011.005c.027 0 .065-.035.113-.105s.078-.149.091-.236c.006-.003.016-.007.029-.012.029-.013.052-.022.067-.026z'
					transform='matrix(104.964 0 0 104.964 470.853885 82.4262295)'
				/>
				<path
					d='m.216-.281c-.006.044-.011.087-.015.128-.004.042-.007.082-.009.121l.088.016-.008.021c-.022-.002-.044-.003-.065-.004-.022-.001-.047-.001-.077-.001s-.055 0-.077.001c-.021.001-.042.002-.063.004l.007-.021.082-.016c.022-.11.04-.212.053-.305s.022-.178.026-.256l-.086-.016.008-.021c.02.002.039.004.058.005.02.001.039.002.058.002.029 0 .065-.002.107-.005s.069-.005.081-.005c.049 0 .086.012.111.035.026.023.038.057.038.102 0 .064-.024.116-.071.157-.048.041-.11.061-.186.061-.009 0-.018 0-.028-.001-.01 0-.021-.001-.032-.002zm.007-.037c.011.003.022.006.032.007.01.002.021.003.031.003.044 0 .079-.017.105-.051.025-.034.038-.08.038-.138 0-.035-.008-.06-.025-.077s-.042-.025-.077-.025c-.013 0-.03.002-.05.006-.004.001-.007.002-.008.002-.008.038-.016.08-.024.125-.007.045-.015.094-.022.148z'
					transform='matrix(27.40968 0 0 27.40968 150.522827276 114.80682079)'
				/>
				<path
					d='m.323-.322c-.005-.01-.01-.018-.016-.022-.005-.005-.011-.007-.018-.007-.026 0-.055.032-.087.095-.032.064-.055.141-.068.231-.014.005-.031.012-.053.021-.021.008-.036.014-.043.017.017-.079.03-.152.04-.22.01-.067.015-.111.015-.134 0-.008-.001-.014-.004-.019-.002-.004-.006-.006-.011-.006-.004 0-.011.003-.02.01s-.019.017-.03.029l-.015-.014c.034-.036.063-.064.089-.082.025-.019.047-.028.064-.028.009 0 .016.003.021.009.004.007.006.017.006.031 0 .012-.002.034-.007.067-.005.032-.011.067-.019.106l.002.001c.034-.067.068-.119.1-.157s.059-.057.08-.057c.011 0 .019.004.026.013.007.008.01.02.01.034 0 .013-.005.026-.016.039-.01.014-.026.029-.046.043z'
					transform='matrix(27.40968 0 0 27.40968 165.244909025 114.80682079)'
				/>
				<path
					d='m.282-.296c0-.036-.005-.063-.016-.081s-.028-.027-.049-.027c-.033 0-.059.023-.078.07-.019.046-.029.108-.029.187 0 .034.005.06.016.078.011.017.027.026.048.026.032 0 .058-.023.078-.069.02-.047.03-.108.03-.184zm-.001-.158c.033 0 .058.011.075.032.016.021.025.053.025.095 0 .041-.007.081-.019.12-.013.038-.031.072-.055.102-.027.034-.059.061-.095.082-.037.021-.07.032-.1.032-.033 0-.058-.01-.074-.031-.017-.02-.025-.052-.025-.093 0-.051.009-.099.026-.145.018-.046.042-.083.072-.112.027-.025.056-.045.087-.06.03-.015.058-.022.083-.022z'
					transform='matrix(27.40968 0 0 27.40968 174.46655324 114.80682079)'
				/>
				<path
					d='m.136-.04c.01.003.019.005.028.006.008.001.017.002.025.002.029 0 .054-.007.074-.02s.037-.033.052-.062c.014-.027.025-.061.034-.1.009-.04.013-.078.013-.114 0-.017-.002-.029-.006-.036s-.01-.011-.018-.011c-.028 0-.065.034-.111.102-.045.068-.076.146-.091.233zm.029-.187c.046-.067.092-.122.139-.163s.085-.061.113-.061c.015 0 .027.007.036.022.008.015.013.035.013.061 0 .053-.009.103-.028.151-.018.048-.044.089-.078.124-.031.032-.066.058-.104.076-.038.019-.075.028-.111.028 0 0-.002 0-.006-.001-.004 0-.007 0-.011 0-.016.106-.055.19-.117.254s-.135.096-.22.096c-.026 0-.047-.005-.062-.015s-.022-.023-.022-.04c0-.007.002-.014.005-.022.004-.007.009-.014.015-.022.02.015.041.027.065.035.023.008.046.012.069.012.044 0 .08-.016.105-.049.026-.033.047-.092.065-.175.018-.087.033-.171.044-.254s.017-.139.017-.17c0-.009-.001-.015-.003-.02-.002-.004-.005-.006-.009-.006-.005 0-.013.004-.023.011-.01.008-.02.018-.031.031l-.014-.014c.032-.036.061-.064.086-.083.026-.02.047-.03.062-.03.01 0 .018.004.023.011.005.008.008.019.008.034 0 .016-.002.04-.007.071-.004.032-.011.068-.019.108z'
					transform='matrix(27.40968 0 0 27.40968 186.10995689 114.80682079)'
				/>
				<path
					d='m.125-.233c-.004.018-.006.035-.008.05-.002.016-.003.03-.003.042 0 .025.004.044.013.057.01.013.023.02.04.02.016 0 .034-.006.053-.019.02-.014.041-.033.064-.059l.017.016c-.033.041-.068.075-.107.1-.039.026-.073.038-.101.038-.026 0-.045-.008-.059-.025-.013-.016-.02-.04-.02-.071 0-.046.008-.093.024-.143.016-.049.035-.089.059-.119s.053-.055.088-.076c.035-.022.065-.032.089-.032.019 0 .035.005.046.016.01.01.016.025.016.044 0 .033-.02.065-.061.097-.04.032-.09.053-.15.064zm.005-.027c.033 0 .06-.009.081-.029.022-.019.032-.043.032-.072 0-.013-.002-.023-.008-.03-.005-.007-.013-.01-.023-.01-.018 0-.034.012-.048.036-.014.025-.025.06-.034.105z'
					transform='matrix(27.40968 0 0 27.40968 199.97582876 114.80682079)'
				/>
				<path
					d='m.323-.322c-.005-.01-.01-.018-.016-.022-.005-.005-.011-.007-.018-.007-.026 0-.055.032-.087.095-.032.064-.055.141-.068.231-.014.005-.031.012-.053.021-.021.008-.036.014-.043.017.017-.079.03-.152.04-.22.01-.067.015-.111.015-.134 0-.008-.001-.014-.004-.019-.002-.004-.006-.006-.011-.006-.004 0-.011.003-.02.01s-.019.017-.03.029l-.015-.014c.034-.036.063-.064.089-.082.025-.019.047-.028.064-.028.009 0 .016.003.021.009.004.007.006.017.006.031 0 .012-.002.034-.007.067-.005.032-.011.067-.019.106l.002.001c.034-.067.068-.119.1-.157s.059-.057.08-.057c.011 0 .019.004.026.013.007.008.01.02.01.034 0 .013-.005.026-.016.039-.01.014-.026.029-.046.043z'
					transform='matrix(27.40968 0 0 27.40968 209.6514458 114.80682079)'
				/>
				<path
					d='m.228-.551.008.009c-.009.018-.016.036-.022.056-.006.019-.01.039-.014.061.022.002.038.003.049.004s.019.001.026.001c.015 0 .027-.001.035-.003.009-.003.016-.006.024-.011l.008.01c-.017.018-.035.031-.054.039-.018.007-.042.011-.071.011h-.014c-.005-.001-.009-.001-.012-.001-.011.058-.021.111-.027.159-.007.049-.01.085-.01.107 0 .01.001.016.003.02.002.003.006.005.011.005s.012-.003.02-.01c.009-.007.018-.016.028-.028l.016.013c-.03.038-.057.067-.083.087-.026.02-.048.03-.067.03-.01 0-.018-.003-.023-.01-.005-.006-.007-.017-.007-.03 0-.021.004-.061.013-.121s.022-.135.038-.225c-.02 0-.037.001-.051.004-.015.003-.027.007-.039.012l-.005-.013c.037-.02.074-.045.11-.073.036-.029.073-.063.11-.103z'
					transform='matrix(27.40968 0 0 27.40968 219.8501309 114.80682079)'
				/>
				<path
					d='m.456-.455c-.019.07-.036.16-.051.269-.016.109-.026.177-.031.204-.014.072-.033.129-.058.173s-.059.08-.102.111c-.025.017-.053.031-.084.042-.032.01-.062.015-.09.015-.027 0-.048-.005-.062-.015-.015-.009-.022-.023-.022-.041 0-.006.002-.013.005-.02s.008-.014.014-.022c.02.015.042.027.065.035.022.008.045.012.068.012.094 0 .165-.164.213-.492l.005-.034c-.047.067-.095.121-.143.163-.048.041-.086.062-.113.062-.009 0-.015-.003-.02-.01-.005-.006-.007-.015-.007-.027 0-.027.008-.077.023-.15.016-.073.024-.127.024-.162 0-.009-.001-.014-.003-.017-.001-.003-.004-.005-.009-.005-.003 0-.009.003-.017.01-.008.006-.017.014-.027.025l-.014-.013c.027-.033.053-.059.079-.078.026-.02.047-.029.063-.029.009 0 .016.003.02.009.005.005.007.014.007.025 0 .038-.008.094-.023.168-.016.073-.024.122-.024.146 0 .01.001.017.003.02s.006.005.011.005c.027 0 .065-.035.113-.105s.078-.149.091-.236c.006-.003.016-.007.029-.012.029-.013.052-.022.067-.026z'
					transform='matrix(27.40968 0 0 27.40968 228.26832887 114.80682079)'
				/>
				<path
					d='m.35-.595c-.062 0-.112.031-.15.094-.037.062-.056.146-.056.25 0 .076.012.132.034.168.023.035.06.053.109.053.064 0 .115-.03.152-.091s.056-.144.056-.248c0-.078-.012-.135-.035-.171-.023-.037-.06-.055-.11-.055zm.053-.042c.065 0 .115.019.15.058s.052.096.052.169c0 .118-.036.218-.109.299-.072.082-.16.123-.263.123-.064 0-.113-.019-.147-.057s-.051-.094-.051-.166c0-.053.008-.104.024-.153s.038-.092.067-.129c.035-.045.077-.08.126-.106.05-.025.1-.038.151-.038z'
					transform='matrix(27.40968 0 0 27.40968 249.29383757 114.80682079)'
				/>
				<path
					d='m.333-.256c-.042.071-.093.134-.151.188-.059.054-.098.081-.118.081-.003 0-.005-.001-.006-.003-.001-.001-.002-.004-.002-.007 0-.005.003-.018.009-.04.024-.092.036-.175.036-.249 0-.026-.002-.045-.007-.057-.004-.012-.011-.018-.02-.018-.006 0-.013.003-.021.009s-.019.015-.031.028l-.014-.012c.025-.035.049-.062.072-.081.022-.019.042-.029.057-.029.018 0 .032.009.04.027.009.018.014.045.014.082 0 .038-.003.078-.008.121-.006.043-.013.088-.024.135.048-.041.088-.086.119-.136s.055-.105.07-.164c.012-.011.023-.021.033-.032.01-.01.02-.021.028-.033l.017.008c-.003.013-.005.029-.006.046s-.002.039-.002.068c0 .074.005.133.014.177.009.043.022.065.037.065.009 0 .018-.006.029-.018.011-.011.023-.028.035-.051.018-.033.032-.067.042-.101.011-.033.016-.062.016-.087 0-.012-.002-.02-.005-.026s-.008-.009-.015-.009c-.005 0-.011.002-.018.006-.006.003-.013.009-.02.016l-.012-.013c.023-.03.043-.052.06-.067s.032-.022.044-.022.022.005.029.015.011.024.011.041c0 .036-.012.081-.037.138-.024.056-.057.112-.098.167-.023.031-.044.055-.063.071s-.035.024-.048.024c-.026 0-.046-.02-.06-.061s-.022-.106-.026-.197z'
					transform='matrix(27.40968 0 0 27.40968 266.89313627 114.80682079)'
				/>
				<path
					d='m.038.013c.017-.079.03-.152.04-.22.01-.067.015-.111.015-.134 0-.008-.001-.014-.004-.019-.002-.004-.006-.006-.011-.006-.004 0-.011.003-.02.01s-.019.017-.03.029l-.015-.014c.035-.038.065-.066.09-.084.025-.019.046-.028.063-.028.009 0 .016.003.02.01.005.007.007.018.007.032 0 .013-.003.036-.007.069-.005.033-.012.072-.021.117.048-.066.097-.12.148-.163.051-.044.088-.065.112-.065.009 0 .016.002.021.007s.007.012.007.022c0 .026-.008.076-.024.152-.015.076-.023.133-.023.172 0 .006.001.011.003.015.002.003.004.005.007.005.005 0 .011-.003.019-.008.007-.006.016-.014.026-.026l.015.013c-.029.036-.054.063-.076.081s-.042.027-.058.027c-.011 0-.019-.004-.025-.012-.005-.008-.008-.021-.008-.038 0-.034.007-.087.022-.158.014-.071.021-.121.021-.149 0-.006-.001-.011-.003-.015-.003-.003-.006-.005-.01-.005-.01 0-.025.009-.045.028s-.042.044-.066.076c-.027.036-.047.073-.062.112s-.025.081-.03.127c-.014.005-.028.011-.043.017-.016.006-.034.014-.055.023z'
					transform='matrix(27.40968 0 0 27.40968 285.7247285 114.80682079)'
				/>
				<path
					d='m.125-.233c-.004.018-.006.035-.008.05-.002.016-.003.03-.003.042 0 .025.004.044.013.057.01.013.023.02.04.02.016 0 .034-.006.053-.019.02-.014.041-.033.064-.059l.017.016c-.033.041-.068.075-.107.1-.039.026-.073.038-.101.038-.026 0-.045-.008-.059-.025-.013-.016-.02-.04-.02-.071 0-.046.008-.093.024-.143.016-.049.035-.089.059-.119s.053-.055.088-.076c.035-.022.065-.032.089-.032.019 0 .035.005.046.016.01.01.016.025.016.044 0 .033-.02.065-.061.097-.04.032-.09.053-.15.064zm.005-.027c.033 0 .06-.009.081-.029.022-.019.032-.043.032-.072 0-.013-.002-.023-.008-.03-.005-.007-.013-.01-.023-.01-.018 0-.034.012-.048.036-.014.025-.025.06-.034.105z'
					transform='matrix(27.40968 0 0 27.40968 300.13879397 114.80682079)'
				/>
				<path
					d='m.323-.322c-.005-.01-.01-.018-.016-.022-.005-.005-.011-.007-.018-.007-.026 0-.055.032-.087.095-.032.064-.055.141-.068.231-.014.005-.031.012-.053.021-.021.008-.036.014-.043.017.017-.079.03-.152.04-.22.01-.067.015-.111.015-.134 0-.008-.001-.014-.004-.019-.002-.004-.006-.006-.011-.006-.004 0-.011.003-.02.01s-.019.017-.03.029l-.015-.014c.034-.036.063-.064.089-.082.025-.019.047-.028.064-.028.009 0 .016.003.021.009.004.007.006.017.006.031 0 .012-.002.034-.007.067-.005.032-.011.067-.019.106l.002.001c.034-.067.068-.119.1-.157s.059-.057.08-.057c.011 0 .019.004.026.013.007.008.01.02.01.034 0 .013-.005.026-.016.039-.01.014-.026.029-.046.043z'
					transform='matrix(27.40968 0 0 27.40968 309.81441101 114.80682079)'
				/>
				<path
					d='m.25-.359c-.01-.016-.022-.028-.034-.036s-.025-.012-.038-.012c-.015 0-.027.004-.037.014-.01.009-.015.021-.015.035 0 .018.014.044.042.078.007.008.013.014.016.018.018.022.031.042.039.06.008.017.012.035.012.052 0 .04-.019.077-.056.108-.037.032-.079.048-.127.048-.022 0-.04-.004-.054-.013-.013-.008-.02-.019-.02-.032 0-.007.004-.016.012-.025.007-.009.018-.019.031-.029.012.019.025.033.04.042.014.009.03.014.046.014.017 0 .03-.005.041-.015s.016-.022.016-.037c0-.01-.003-.021-.008-.031-.005-.011-.017-.026-.035-.047 0 0-.001-.001-.002-.003-.043-.052-.065-.095-.065-.13 0-.04.018-.076.054-.106.036-.031.077-.046.123-.046.02 0 .036.004.049.012.012.008.019.018.019.03 0 .009-.005.018-.014.028s-.021.018-.035.023z'
					transform='matrix(27.40968 0 0 27.40968 320.01309611 114.80682079)'
				/>
				<path
					d='m.33-.541c-.028.049-.054.096-.078.142s-.047.091-.068.134h.206c-.007-.037-.015-.077-.024-.12-.009-.042-.021-.094-.036-.156zm.082-.109c.008.078.023.17.045.276.022.107.05.221.084.342l.08.016-.007.021c-.02-.002-.04-.003-.06-.004s-.044-.001-.071-.001c-.022 0-.044 0-.067.001s-.047.002-.071.004l.008-.021.076-.016c-.003-.023-.006-.051-.011-.083-.005-.033-.012-.073-.021-.12h-.227c-.024.047-.043.087-.056.119-.014.033-.024.061-.031.084l.081.016-.007.021c-.018-.002-.035-.003-.053-.004-.017-.001-.038-.001-.061-.001-.028 0-.051 0-.069.001s-.036.002-.054.004l.007-.021.074-.016c.1-.16.178-.293.236-.399.057-.106.086-.169.086-.19 0-.001 0-.002 0-.003-.001 0-.001-.001-.001-.001z'
					transform='matrix(27.40968 0 0 27.40968 336.02034923 114.80682079)'
				/>
				<path
					d='m.25-.359c-.01-.016-.022-.028-.034-.036s-.025-.012-.038-.012c-.015 0-.027.004-.037.014-.01.009-.015.021-.015.035 0 .018.014.044.042.078.007.008.013.014.016.018.018.022.031.042.039.06.008.017.012.035.012.052 0 .04-.019.077-.056.108-.037.032-.079.048-.127.048-.022 0-.04-.004-.054-.013-.013-.008-.02-.019-.02-.032 0-.007.004-.016.012-.025.007-.009.018-.019.031-.029.012.019.025.033.04.042.014.009.03.014.046.014.017 0 .03-.005.041-.015s.016-.022.016-.037c0-.01-.003-.021-.008-.031-.005-.011-.017-.026-.035-.047 0 0-.001-.001-.002-.003-.043-.052-.065-.095-.065-.13 0-.04.018-.076.054-.106.036-.031.077-.046.123-.046.02 0 .036.004.049.012.012.008.019.018.019.03 0 .009-.005.018-.014.028s-.021.018-.035.023z'
					transform='matrix(27.40968 0 0 27.40968 353.59223825 114.80682079)'
				/>
				<path
					d='m.25-.359c-.01-.016-.022-.028-.034-.036s-.025-.012-.038-.012c-.015 0-.027.004-.037.014-.01.009-.015.021-.015.035 0 .018.014.044.042.078.007.008.013.014.016.018.018.022.031.042.039.06.008.017.012.035.012.052 0 .04-.019.077-.056.108-.037.032-.079.048-.127.048-.022 0-.04-.004-.054-.013-.013-.008-.02-.019-.02-.032 0-.007.004-.016.012-.025.007-.009.018-.019.031-.029.012.019.025.033.04.042.014.009.03.014.046.014.017 0 .03-.005.041-.015s.016-.022.016-.037c0-.01-.003-.021-.008-.031-.005-.011-.017-.026-.035-.047 0 0-.001-.001-.002-.003-.043-.052-.065-.095-.065-.13 0-.04.018-.076.054-.106.036-.031.077-.046.123-.046.02 0 .036.004.049.012.012.008.019.018.019.03 0 .009-.005.018-.014.028s-.021.018-.035.023z'
					transform='matrix(27.40968 0 0 27.40968 362.1177908 114.80682079)'
				/>
				<path
					d='m.282-.296c0-.036-.005-.063-.016-.081s-.028-.027-.049-.027c-.033 0-.059.023-.078.07-.019.046-.029.108-.029.187 0 .034.005.06.016.078.011.017.027.026.048.026.032 0 .058-.023.078-.069.02-.047.03-.108.03-.184zm-.001-.158c.033 0 .058.011.075.032.016.021.025.053.025.095 0 .041-.007.081-.019.12-.013.038-.031.072-.055.102-.027.034-.059.061-.095.082-.037.021-.07.032-.1.032-.033 0-.058-.01-.074-.031-.017-.02-.025-.052-.025-.093 0-.051.009-.099.026-.145.018-.046.042-.083.072-.112.027-.025.056-.045.087-.06.03-.015.058-.022.083-.022z'
					transform='matrix(27.40968 0 0 27.40968 370.64334335 114.80682079)'
				/>
				<path
					d='m.272-.351c-.013-.015-.025-.026-.036-.033s-.022-.01-.033-.01c-.024 0-.045.025-.063.074-.019.049-.028.106-.028.172 0 .028.004.049.013.063.01.014.023.021.041.021.016 0 .034-.007.054-.02.02-.014.042-.033.064-.059l.017.017c-.034.042-.071.075-.109.099-.039.025-.072.037-.101.037-.027 0-.046-.008-.059-.025-.013-.016-.019-.042-.019-.078 0-.044.007-.089.02-.132.013-.044.031-.081.053-.111.024-.032.055-.06.094-.083s.071-.035.098-.035c.017 0 .031.004.042.013.011.008.017.018.017.03 0 .009-.006.019-.017.029-.012.011-.028.021-.048.031z'
					transform='matrix(27.40968 0 0 27.40968 382.286747 114.80682079)'
				/>
				<path
					d='m.206-.125.016.013c-.028.038-.055.068-.081.09-.026.021-.048.032-.065.032-.009 0-.016-.003-.021-.01-.004-.006-.006-.016-.006-.03 0-.034.008-.087.024-.159s.024-.123.024-.153c0-.005-.001-.01-.003-.012-.001-.003-.004-.005-.007-.005-.005 0-.012.003-.019.008-.008.006-.017.015-.028.027l-.015-.015c.03-.036.058-.064.083-.084.026-.02.046-.03.06-.03.009 0 .016.003.021.009s.007.014.007.026c0 .011-.005.048-.016.11-.003.021-.006.037-.008.049-.007.041-.012.074-.016.101-.004.026-.006.043-.006.048 0 .007.001.012.003.015.002.004.006.005.01.005.005 0 .011-.003.018-.009.007-.005.015-.014.025-.026zm-.029-.521c.012 0 .023.004.031.013.009.008.013.019.013.031 0 .021-.006.038-.018.053-.012.014-.026.021-.043.021-.013 0-.023-.004-.032-.013-.008-.009-.012-.02-.012-.034 0-.019.006-.035.019-.05.012-.014.026-.021.042-.021z'
					transform='matrix(27.40968 0 0 27.40968 391.74993902 114.80682079)'
				/>
				<path
					d='m.322-.39c-.011 0-.024-.001-.039-.002-.016-.001-.026-.002-.032-.002-.024 0-.044.005-.059.016-.015.01-.027.027-.038.051-.013.029-.024.066-.034.111-.009.044-.014.083-.014.116 0 .011.002.02.005.026.003.005.007.008.013.008.024 0 .059-.033.104-.099s.077-.141.094-.225zm-.034.182c-.042.064-.086.116-.132.156-.045.04-.081.06-.107.06-.012 0-.021-.006-.027-.017s-.009-.028-.009-.05c0-.119.032-.213.095-.285.063-.071.146-.106.251-.106h.021l.045-.001c-.019.067-.035.133-.046.197s-.016.113-.016.147c0 .01.001.017.003.021.002.005.005.007.01.007.004 0 .008-.002.012-.006.005-.003.013-.012.026-.026l.016.014c-.027.032-.052.058-.077.077-.025.018-.044.028-.059.028-.01 0-.017-.004-.023-.012-.005-.008-.008-.019-.008-.034 0-.014.002-.035.006-.062.004-.028.01-.063.019-.108z'
					transform='matrix(27.40968 0 0 27.40968 399.28417481 114.80682079)'
				/>
				<path
					d='m.228-.551.008.009c-.009.018-.016.036-.022.056-.006.019-.01.039-.014.061.022.002.038.003.049.004s.019.001.026.001c.015 0 .027-.001.035-.003.009-.003.016-.006.024-.011l.008.01c-.017.018-.035.031-.054.039-.018.007-.042.011-.071.011h-.014c-.005-.001-.009-.001-.012-.001-.011.058-.021.111-.027.159-.007.049-.01.085-.01.107 0 .01.001.016.003.02.002.003.006.005.011.005s.012-.003.02-.01c.009-.007.018-.016.028-.028l.016.013c-.03.038-.057.067-.083.087-.026.02-.048.03-.067.03-.01 0-.018-.003-.023-.01-.005-.006-.007-.017-.007-.03 0-.021.004-.061.013-.121s.022-.135.038-.225c-.02 0-.037.001-.051.004-.015.003-.027.007-.039.012l-.005-.013c.037-.02.074-.045.11-.073.036-.029.073-.063.11-.103z'
					transform='matrix(27.40968 0 0 27.40968 412.38714392 114.80682079)'
				/>
				<path
					d='m.206-.125.016.013c-.028.038-.055.068-.081.09-.026.021-.048.032-.065.032-.009 0-.016-.003-.021-.01-.004-.006-.006-.016-.006-.03 0-.034.008-.087.024-.159s.024-.123.024-.153c0-.005-.001-.01-.003-.012-.001-.003-.004-.005-.007-.005-.005 0-.012.003-.019.008-.008.006-.017.015-.028.027l-.015-.015c.03-.036.058-.064.083-.084.026-.02.046-.03.06-.03.009 0 .016.003.021.009s.007.014.007.026c0 .011-.005.048-.016.11-.003.021-.006.037-.008.049-.007.041-.012.074-.016.101-.004.026-.006.043-.006.048 0 .007.001.012.003.015.002.004.006.005.01.005.005 0 .011-.003.018-.009.007-.005.015-.014.025-.026zm-.029-.521c.012 0 .023.004.031.013.009.008.013.019.013.031 0 .021-.006.038-.018.053-.012.014-.026.021-.043.021-.013 0-.023-.004-.032-.013-.008-.009-.012-.02-.012-.034 0-.019.006-.035.019-.05.012-.014.026-.021.042-.021z'
					transform='matrix(27.40968 0 0 27.40968 420.80534189 114.80682079)'
				/>
				<path
					d='m.282-.296c0-.036-.005-.063-.016-.081s-.028-.027-.049-.027c-.033 0-.059.023-.078.07-.019.046-.029.108-.029.187 0 .034.005.06.016.078.011.017.027.026.048.026.032 0 .058-.023.078-.069.02-.047.03-.108.03-.184zm-.001-.158c.033 0 .058.011.075.032.016.021.025.053.025.095 0 .041-.007.081-.019.12-.013.038-.031.072-.055.102-.027.034-.059.061-.095.082-.037.021-.07.032-.1.032-.033 0-.058-.01-.074-.031-.017-.02-.025-.052-.025-.093 0-.051.009-.099.026-.145.018-.046.042-.083.072-.112.027-.025.056-.045.087-.06.03-.015.058-.022.083-.022z'
					transform='matrix(27.40968 0 0 27.40968 428.34071975 114.80682079)'
				/>
				<path
					d='m.038.013c.017-.079.03-.152.04-.22.01-.067.015-.111.015-.134 0-.008-.001-.014-.004-.019-.002-.004-.006-.006-.011-.006-.004 0-.011.003-.02.01s-.019.017-.03.029l-.015-.014c.035-.038.065-.066.09-.084.025-.019.046-.028.063-.028.009 0 .016.003.02.01.005.007.007.018.007.032 0 .013-.003.036-.007.069-.005.033-.012.072-.021.117.048-.066.097-.12.148-.163.051-.044.088-.065.112-.065.009 0 .016.002.021.007s.007.012.007.022c0 .026-.008.076-.024.152-.015.076-.023.133-.023.172 0 .006.001.011.003.015.002.003.004.005.007.005.005 0 .011-.003.019-.008.007-.006.016-.014.026-.026l.015.013c-.029.036-.054.063-.076.081s-.042.027-.058.027c-.011 0-.019-.004-.025-.012-.005-.008-.008-.021-.008-.038 0-.034.007-.087.022-.158.014-.071.021-.121.021-.149 0-.006-.001-.011-.003-.015-.003-.003-.006-.005-.01-.005-.01 0-.025.009-.045.028s-.042.044-.066.076c-.027.036-.047.073-.062.112s-.025.081-.03.127c-.014.005-.028.011-.043.017-.016.006-.034.014-.055.023z'
					transform='matrix(27.40968 0 0 27.40968 439.9841234 114.80682079)'
				/>
			</g>
		</svg>
	</Logo>
);

export default AppleValleyPropertyOwnersAssociation;
