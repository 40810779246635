// Imports
import { Route } from 'react-router-dom';
import PricingDetails from '.';
import MailedLetters from './mailed-letters';
import OnlinePayments from './online-payments';
import TextMessages from './text-messages';


// Function component
const sectionRoutes = (
	<Route path='pricing-details' element={<PricingDetails />}>
		<Route path='mailed-letters' element={<MailedLetters />} />
		<Route path='text-messages' element={<TextMessages />} />
		<Route path='online-payments' element={<OnlinePayments />} />
	</Route>
);

export default sectionRoutes;
