// Imports
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import commonActions from '../../../../../shareables/foundation/front-end/redux/actions';
import helperFunctions from '../../../../../shareables/foundation/front-end/utils/helper-functions';
import styled from 'styled-components/macro';
import theme from '../../../../../shareables/theme';
import { setLightness, setSaturation } from 'polished';


// Define how long messages stay visible (in seconds)
const disappearTimer = 15;


// Styling
interface ContainerProps {
	color: 'success' | 'danger';
	currentOffset: string;
	visible: boolean;
	zIndex: number;
}

const Container = styled.article<ContainerProps>`
	width: calc(100vw - 50px);
	max-width: 400px;
	box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
	opacity: 1;
	transform: translateY(${(props) => props.currentOffset});
	transition: all 0.5s ease;
	will-change: transform;
	position: fixed;
	top: 0;
	right: 25px;
	background: ${(props) => setSaturation(0.6, setLightness(0.98, theme.colors[props.color]))};
	border-radius: 0.25rem;
	font-size: 1rem;
	visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
	opacity: ${(props) => (props.visible ? 1 : 0)};
	z-index: ${(props) => props.zIndex};
`;

interface HeaderProps {
	color: 'success' | 'danger';
}

const Header = styled.div<HeaderProps>`
	align-items: center;
	background: ${(props) => theme.colors[props.color]};
	border-radius: 0.25rem 0.25rem 0 0;
	color: #fff;
	display: flex;
	font-weight: 700;
	justify-content: space-between;
	line-height: 1.25;
	padding: 0.75em 1em;
	position: relative;
	
	p {
		margin: 0;
		padding: 0;
	}
	
	button {
		appearance: none;
		background-color: rgba(10, 10, 10, 0.2);
		border: none;
		border-radius: 290486px;
		cursor: pointer;
		pointer-events: auto;
		display: inline-block;
		flex-grow: 0;
		flex-shrink: 0;
		font-size: 0;
		height: 20px;
		max-height: 20px;
		max-width: 20px;
		min-height: 20px;
		min-width: 20px;
		outline: none;
		position: relative;
		vertical-align: top;
		width: 20px;
		margin-left: 0.75em;
		
		&::before,
		&::after {
			background-color: #fff;
			content: '';
			display: block;
			left: 50%;
			position: absolute;
			top: 50%;
			transform: translateX(-50%) translateY(-50%) rotate(45deg);
			transform-origin: center center;
		}
		
		&::before {
			height: 2px;
			width: 50%;
		}
		
		&::after {
			height: 50%;
			width: 2px;
		}
	}
`;

const Progress = styled.div`
	appearance: none;
	border: none;
	display: block;
	overflow: hidden;
	padding: 0;
	width: 100%;
	position: absolute;
	left: 0;
	bottom: 0;
	border-radius: 0;
	height: 0.2rem;
`;

interface BarProps {
	isNew: boolean;
}

const Bar = styled.div<BarProps>`
	width: ${(props) => (props.isNew ? '100%' : '0%')};
	height: inherit;
	transition: width ${disappearTimer}s linear;
	background: rgba(0, 0, 0, 0.15);
`;

const Body = styled.div`
	border-radius: 0 0 0.25rem 0.25rem;
	padding: 1.25em 1.5em;
	color: #333;
	
	a {
		color: inherit;
		text-decoration: underline;
		
		&:hover {
			text-decoration: none;
			color: inherit;
		}
	}
`;


// Define the accepted props
interface Props {
	maxZIndex: number;
	index: number;
	offset: number;
	storybookDisableHiding?: true;
}


// Function component
const PageMessage: React.FC<Props> = ({ maxZIndex, index, offset, storybookDisableHiding }) => {
	// Use Redux functionality
	const dispatch = useAppDispatch();
	const title = useAppSelector((state) => state.common.pageMessages[index].title);
	
	const message = useAppSelector((state) => state.common.pageMessages[index].message);
	
	const color = useAppSelector((state) => state.common.pageMessages[index].color);
	
	const dismissed = Boolean(useAppSelector((state) => state.common.pageMessages[index].dismissed));
	
	
	// Use state
	const [isNew, setIsNew] = useState(true);
	const [currentOffset, setCurrentOffset] = useState('-100%');
	
	
	// Automatically transition from new to not new
	useEffect(() => {
		setIsNew(false);
	}, []);
	
	
	// Automatically hide the page message after a long period of time
	useEffect(() => {
		if (storybookDisableHiding) {
			return;
		}
		
		window.setTimeout(() => {
			dispatch(commonActions.hidePageMessage(index));
		}, disappearTimer * 1000);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	
	
	// Set current offset when the passed-in prop changes
	useEffect(() => {
		setCurrentOffset(`${offset}px`);
	}, [offset]);
	
	
	// Return JSX
	return (
		<Container
			role='alert'
			currentOffset={currentOffset}
			zIndex={maxZIndex - index}
			visible={!isNew && !dismissed}
			color={color}
		>
			<Header color={color}>
				<p>{title}</p>
				
				<button
					{...helperFunctions.buttonize(() => {
						dispatch(commonActions.hidePageMessage(index));
					})}
					aria-label='Close message'
				></button>
				
				<Progress>
					<Bar isNew={isNew}></Bar>
				</Progress>
			</Header>
			
			<Body>{message}</Body>
		</Container>
	);
};

export default PageMessage;
