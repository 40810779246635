// Imports
import React from 'react';
import styled from 'styled-components/macro';
import SimplePageTemplate from '../../resources/simple-page-template';


// Styled components
const Container = styled.div`
	text-align: center;
	max-width: 40.625rem;
`;

const Subheading = styled.h2`
	font-size: 2rem;
	font-weight: 600;
	
	padding-top: 3.75rem;
	margin-bottom: 1rem;
`;


// Define the accepted props
interface Props extends React.ComponentProps<typeof SimplePageTemplate> {
	subheading: string;
}


// Function component
const WebsitePageTemplate: React.FC<Props> = ({ heading, descriptor, subheading, children }) => (
	<SimplePageTemplate heading={heading} descriptor={descriptor}>
		<Container>
			<Subheading>{subheading}</Subheading>
			
			{children}
		</Container>
	</SimplePageTemplate>
);

export default WebsitePageTemplate;
