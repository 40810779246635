// Imports
import styled from 'styled-components/macro';
import LoadingImage from '../../resources/loading-image';


// Styled components
const Screenshot = styled(LoadingImage)`
	width: ${({ width }) =>
		((Number(width) / 2 / 540) * 100).toFixed(
			2
		)}%; // 540px is the width of the tiles in the mockup this was derived from, and all screenshots were exported at 2x their size in the mockup
	height: auto;
`;

export default Screenshot;
