// Imports
import {
	faBullhorn,
	faCalendars,
	faCircleInfo,
	faCreditCard,
	faCubes,
	faGlobeAmericas,
	faShieldCheck,
	faSquarePollVertical,
	faUsers,
} from '@fortawesome/pro-duotone-svg-icons';
import Dropdown from './dropdown';


// Define links
const featureLinks: React.ComponentProps<typeof Dropdown>['items'] = [
	{
		title: 'Blast emails & texts',
		to: '/features/blast-communications',
		description: 'Powerful tools for sending announcements & alerts',
		icon: faBullhorn,
	},
	{
		title: 'Store residents',
		to: '/features/store-residents',
		description: 'Residents register and manage their own profiles',
		icon: faUsers,
	},
	{
		title: 'Online payments',
		to: '/features/online-payments',
		description: 'Easily collect payments from residents with low fees',
		icon: faCreditCard,
	},
	{
		title: 'Custom forms',
		to: '/features/custom-forms',
		description: 'Build customizable forms for questions & submissions',
		icon: faCubes,
	},
	{
		title: 'Domain & email',
		to: '/features/domain-and-email',
		description: 'Domain with fully-featured email accounts included',
		icon: faGlobeAmericas,
	},
	{
		title: 'Calendars',
		to: '/features/calendars',
		description: 'List upcoming events & meetings, with RSVPs',
		icon: faCalendars,
	},
	{
		title: 'Community info',
		to: '/features/community-info',
		description: 'Board members, committees, news articles, & more',
		icon: faCircleInfo,
	},
	{
		title: 'Surveys',
		to: '/features/surveys',
		description: 'Anonymously poll residents to collect feedback & thoughts',
		icon: faSquarePollVertical,
	},
	{
		title: 'Security',
		to: '/features/security',
		description: 'Industry-leading security across the board',
		icon: faShieldCheck,
	},
];

export default featureLinks;
