// Imports
import styled from 'styled-components/macro';


// Styled components
const PolicyHeading = styled.h2`
	font-size: 2rem;
	font-weight: 600;
	line-height: 1.2;
	
	margin-bottom: 1rem;
`;

export default PolicyHeading;
