// Imports
import React from 'react';
import styled, { css } from 'styled-components/macro';
import formatCurrency from '../../../../shareables/utils/project-specific/formatting/format-currency';


// Styled components
const Detail = styled.small<{ italic?: boolean; allowWrapping?: boolean }>`
	font-size: 0.875rem;
	
	${({ italic }) =>
		italic
			? css`
					font-style: italic;
			  `
			: ''}
	
	${({ allowWrapping }) =>
		allowWrapping
			? css`
					white-space: normal;
			  `
			: ''}
`;


// Define the accepted props
interface Props {
	item: {
		text: string;
		detail?: string;
	};
	price: {
		price: number;
		detail?: string;
	};
}


// Function component
const MailedLettersRow: React.FC<Props> = ({ item, price }) => (
	<tr>
		<td>
			{item.text}
			{item.detail && (
				<React.Fragment>
					{' '}
					<Detail italic allowWrapping>
						({item.detail})
					</Detail>
				</React.Fragment>
			)}
		</td>
		<td>
			{formatCurrency(price.price)}
			{price.detail && (
				<React.Fragment>
					{' '}
					<Detail>{price.detail}</Detail>
				</React.Fragment>
			)}
		</td>
	</tr>
);

export default MailedLettersRow;
