// Imports
import commonActions from '../shareables/foundation/front-end/redux/actions';
import { SIGNED_IN } from '../shareables/foundation/front-end/redux/types';
import { AppSignedInAction, AppUserDetails } from './types';


// Actions
const appCommonActions = {
	...commonActions,
	
	
	// Handles changing state to signed in
	signedIn(details: AppUserDetails): AppSignedInAction {
		return {
			type: SIGNED_IN,
			...details,
		};
	},
};

export default appCommonActions;
