// Imports
import { faCheck, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components/macro';
import subscriptionSizes from '../../../../shareables/utils/billing/subscription-sizes';
import { Feature } from './feature-groupings';


// Styled components
const Label = styled.th`
	font-size: clamp(0.84375rem, 2.34vw, 1.125rem);
	line-height: 1.2;
	text-align: left;
	font-weight: 400;
	padding-top: 0.625rem;
`;

const BaseCell = styled.td`
	text-align: center;
	padding-top: 0.625rem;
`;

const IconCell = styled(BaseCell)`
	font-size: clamp(0.84375rem, 2.34vw, 1.125rem);
	line-height: 1;
`;

const TextCell = styled(BaseCell)`
	font-size: clamp(0.65625rem, 1.82vw, 0.875rem);
	line-height: 1;
`;

const Note = styled.small`
	font-size: 0.75em;
	vertical-align: 50%;
`;


// Helper function that renders a cell
const renderCell = (cellValue: Feature['free'], size: (typeof subscriptionSizes)[number]) => {
	// Process cell value
	const processedCellValue = typeof cellValue === 'function' ? cellValue(size) : cellValue;
	
	
	// Render cell based on type of processed value
	if (typeof processedCellValue === 'boolean') {
		return (
			<IconCell>
				{processedCellValue ? (
					<span aria-label='Available on this plan'>
						<FontAwesomeIcon icon={faCheck} color='hsl(96, 54%, 59%)' />
					</span>
				) : (
					<span aria-label='Not available on this plan'>
						<FontAwesomeIcon icon={faXmark} color='hsl(350, 76%, 59%)' />
					</span>
				)}
			</IconCell>
		);
	}
	
	return <TextCell>{processedCellValue}</TextCell>;
};


// Define the accepted props
type Props = Feature & { size: (typeof subscriptionSizes)[number] };


// Function component
const FeatureRow: React.FC<Props> = ({ label, free, deluxe, size }) => {
	return (
		<tr>
			<Label scope='row'>
				{label.text}
				{label.note && (
					<React.Fragment>
						{' '}
						<Note>({label.note})</Note>
					</React.Fragment>
				)}
			</Label>
			
			{renderCell(free, size)}
			
			{renderCell(deluxe, size)}
		</tr>
	);
};

export default FeatureRow;
