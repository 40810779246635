// Imports
import PolicyHeading from '../policy-heading';


// Function component
const OurGuarantee: React.FC = () => (
	<div>
		<PolicyHeading>Our guarantee</PolicyHeading>
		
		<p>
			We’re confident you’ll love HOA Express. Just in case, we offer a 30-day money back guarantee for all website
			subscription payments. If you are not completely satisfied with your new HOA Express website, or if you make a
			renewal payment by mistake, contact our customer success team within 30 days to request a full refund.
		</p>
	</div>
);

export default OurGuarantee;
