// Imports
import styled from 'styled-components/macro';
import { faStar as faStarEmpty } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarFull, faStarHalfStroke } from '@fortawesome/pro-solid-svg-icons';


// Styled components
const Wrapper = styled.span`
	color: hsl(96, 54%, 59%);
`;


// Define the accepted props
interface Props {
	percentage: number;
}


// Build star icon map
const iconMap = {
	0: faStarEmpty,
	0.5: faStarHalfStroke,
	1: faStarFull,
};

const percentageIsIconMapKey = (percentage: number): percentage is keyof typeof iconMap => percentage in iconMap;


// Function component
const Star: React.FC<Props> = ({ percentage }) => {
	if (!percentageIsIconMapKey(percentage)) {
		throw new Error(`Unexpected percentage: ${percentage.toLocaleString()}`);
	}
	
	return (
		<Wrapper>
			<FontAwesomeIcon icon={iconMap[percentage]} />
		</Wrapper>
	);
};

export default Star;
