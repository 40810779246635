// Imports
import React from 'react';


// Define the accepted props
interface Props {
	country: string;
	credits: number;
}


// Function component
const TextMessagesRow: React.FC<Props> = ({ country, credits }) => (
	<tr>
		<td>{country}</td>
		<td>{credits.toLocaleString()}</td>
	</tr>
);

export default TextMessagesRow;
