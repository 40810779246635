// Imports
import * as Sentry from '@sentry/browser';
import merge from 'lodash/merge';
import commonActions from '../redux/actions';
import store from '../redux/store';
import keyValueStorage from './key-value-storage';
import { z } from 'zod';


/** Handle initial setup for an app, like initializing Sentry */
export default function setUp() {
	// Integrate Sentry when the app has been built
	if (process.env.NODE_ENV === 'production' && process.env.REACT_APP__SENTRY_DSN) {
		// Configure and init Sentry
		const sentryConfig: Sentry.BrowserOptions = {
			dsn: process.env.REACT_APP__SENTRY_DSN,
			environment: process.env.REACT_APP__ENVIRONMENT,
			beforeSend: (event, hint) => {
				// Catch browser generated network errors, and ignore them
				if (
					hint?.originalException &&
					hint.originalException instanceof TypeError &&
					(hint.originalException.message === 'Failed to fetch' ||
						hint.originalException.message === 'cancelled' ||
						hint.originalException.message === 'Load failed' ||
						hint.originalException.message === 'NetworkError when attempting to fetch resource.')
				) {
					store.dispatch(
						commonActions.showPageMessage({
							title: 'Oh snap!',
							message:
								'It looks like we’ve encountered an unexpected error while communicating with the back end. In some cases, refreshing the page can resolve the issue.',
							color: 'danger',
						})
					);
					
					return null;
				}
				
				
				// Catch ChunkLoadErrors, reload the page and ignore
				//  -> These occur whenever we deploy new front ends
				if (
					hint?.originalException &&
					hint.originalException instanceof Error &&
					hint.originalException.name === 'ChunkLoadError'
				) {
					const historyStateSchema = z.object({ pageRefreshedDueToError: z.literal(true).optional() }).nullable();
					const pageRefreshedDueToError = historyStateSchema.parse(window.history.state)?.pageRefreshedDueToError;
					
					if (!pageRefreshedDueToError) {
						window.history.replaceState(
							merge(window.history.state || {}, {
								pageRefreshedDueToError: true,
							}),
							''
						);
						
						window.location.reload();
					}
					
					return null;
				}
				
				
				// Clear local storage
				//  -> This may keep users from getting stuck because of corrupt/outdated data in the cache
				//  -> But don't clear it outside production, because we want errors to be repeatable
				if (process.env.REACT_APP__ENVIRONMENT === 'production') {
					keyValueStorage.clearSelectively();
					
					const historyStateSchema = z
						.object({ pageMessageDisplayedDueToError: z.literal(true).optional() })
						.nullable();
					
					const pageMessagePreviouslyDisplayed = historyStateSchema.parse(window.history.state)
						?.pageMessageDisplayedDueToError;
					
					if (!pageMessagePreviouslyDisplayed) {
						store.dispatch(
							commonActions.showPageMessage({
								title: 'Oh snap!',
								message:
									'It looks like we’ve encountered an unexpected error. In some cases, refreshing the page can resolve the issue.',
								color: 'danger',
							})
						);
						
						window.history.replaceState(
							merge(window.history.state || {}, {
								pageMessageDisplayedDueToError: true,
							}),
							''
						);
					}
				}
				
				
				// Return
				return event;
			},
		};
		
		if (process.env.REACT_APP__RELEASE && process.env.REACT_APP__RELEASE !== 'N/A') {
			sentryConfig.release = process.env.REACT_APP__RELEASE;
		}
		
		Sentry.init(sentryConfig);
		
		
		// Attach tags for Sentry
		Sentry.configureScope((scope) => {
			scope.setTag('namespace', process.env.REACT_APP__NAMESPACE_NAME);
			scope.setTag('microservice', process.env.REACT_APP__MICROSERVICE_NAME);
			scope.setTag('microservice.type', process.env.REACT_APP__MICROSERVICE_TYPE);
			
			if (process.env.REACT_APP__ENGINEER_NAME && process.env.REACT_APP__ENGINEER_NAME !== 'N/A') {
				scope.setTag('engineer', process.env.REACT_APP__ENGINEER_NAME);
			}
		});
	}
}
