// Imports
import Logo from './logo';


// Function component
const SoleraDiamondValley: React.FC = () => (
	<Logo width={3.75} aria-label='Solera Diamond Valley'>
		<svg
			clipRule='evenodd'
			fillRule='evenodd'
			strokeLinejoin='round'
			strokeMiterlimit='2'
			viewBox='0 0 210 140'
			xmlns='http://www.w3.org/2000/svg'
			aria-hidden
		>
			<g transform='translate(-76 -17)'>
				<path d='m216.584 55.332s.167.765-.476 2.927c-1.109 3.731-3.791 2.747-4.633 6.096-1.134 4.513 2.097 9.302 2.097 9.302s-.882-4.866 2.056-7.907c2.337-2.419 2.547-3.14 2.671-4.398.312-3.159-1.715-6.02-1.715-6.02z' />
				<path
					d='m216.584 55.332s.167.765-.476 2.927c-1.109 3.731-3.791 2.747-4.633 6.096-1.134 4.513 2.097 9.302 2.097 9.302s-.882-4.866 2.056-7.907c2.337-2.419 2.547-3.14 2.671-4.398.312-3.159-1.715-6.02-1.715-6.02z'
					transform='matrix(.756567 -.653916 .653916 .756567 23.9499 152.178)'
				/>
				<path
					d='m216.584 55.332s.167.765-.476 2.927c-1.109 3.731-3.791 2.747-4.633 6.096-1.134 4.513 2.097 9.302 2.097 9.302s-.882-4.866 2.056-7.907c2.337-2.419 2.547-3.14 2.671-4.398.312-3.159-1.715-6.02-1.715-6.02z'
					transform='matrix(.020531 -.999789 .999789 .020531 167.805 261.022)'
				/>
				<path
					d='m216.584 55.332s.167.765-.476 2.927c-1.109 3.731-3.791 2.747-4.633 6.096-1.134 4.513 2.097 9.302 2.097 9.302s-.882-4.866 2.056-7.907c2.337-2.419 2.547-3.14 2.671-4.398.312-3.159-1.715-6.02-1.715-6.02z'
					transform='matrix(-.656157 -.754624 .754624 -.656157 323.35 237.966)'
				/>
				<path
					d='m216.584 55.332s.167.765-.476 2.927c-1.109 3.731-3.791 2.747-4.633 6.096-1.134 4.513 2.097 9.302 2.097 9.302s-.882-4.866 2.056-7.907c2.337-2.419 2.547-3.14 2.671-4.398.312-3.159-1.715-6.02-1.715-6.02z'
					transform='matrix(-1 0 -0 -1 432.789 90.8957)'
				/>
				<path
					d='m216.584 55.332s.167.765-.476 2.927c-1.109 3.731-3.791 2.747-4.633 6.096-1.134 4.513 2.097 9.302 2.097 9.302s-.882-4.866 2.056-7.907c2.337-2.419 2.547-3.14 2.671-4.398.312-3.159-1.715-6.02-1.715-6.02z'
					transform='matrix(-.756567 .653916 -.653916 -.756567 408.839 -61.2821)'
				/>
				<path
					d='m216.584 55.332s.167.765-.476 2.927c-1.109 3.731-3.791 2.747-4.633 6.096-1.134 4.513 2.097 9.302 2.097 9.302s-.882-4.866 2.056-7.907c2.337-2.419 2.547-3.14 2.671-4.398.312-3.159-1.715-6.02-1.715-6.02z'
					transform='matrix(-.020531 .999789 -.999789 -.020531 264.984 -170.126)'
				/>
				<path
					d='m216.584 55.332s.167.765-.476 2.927c-1.109 3.731-3.791 2.747-4.633 6.096-1.134 4.513 2.097 9.302 2.097 9.302s-.882-4.866 2.056-7.907c2.337-2.419 2.547-3.14 2.671-4.398.312-3.159-1.715-6.02-1.715-6.02z'
					transform='matrix(.656157 .754624 -.754624 .656157 109.439 -147.071)'
				/>
				<path d='m76.524 132.700194h209.381v1.689h-209.381z' />
				<path d='m76.524 154.6272h209.381v1.689h-209.381z' />
				<g fillRule='nonzero'>
					<path
						d='m.062.002-.02-.001c.001-.045-.008-.1-.027-.163.008-.002.013-.004.016-.004.008.024.014.042.018.053s.01.02.017.029c.007.008.017.015.029.022.021.011.046.021.073.03.028.009.058.013.09.013.027 0 .055-.007.083-.021.028-.013.042-.043.042-.09 0-.023-.004-.044-.012-.06-.009-.017-.019-.031-.032-.041-.014-.011-.029-.02-.046-.028-.018-.008-.04-.018-.068-.028-.038-.014-.07-.028-.095-.043s-.045-.033-.059-.054c-.015-.022-.022-.048-.022-.079 0-.049.018-.09.054-.123.036-.032.088-.049.155-.049.034 0 .068.004.101.011.013.003.022.004.025.004.01 0 .019-.003.029-.01l.004-.002c.003 0 .006.001.01.002-.001.008-.002.015-.003.022-.001.008-.002.016-.002.025-.001.008-.002.015-.002.02s-.001.008-.001.01c.004.039.006.061.006.064l-.015.001c-.002-.033-.013-.059-.033-.078-.019-.019-.04-.032-.064-.039-.025-.007-.048-.01-.07-.01-.054 0-.09.014-.107.042s-.026.055-.026.08c0 .026.006.048.019.066.013.017.03.031.049.041.019.009.047.021.082.035.039.016.071.03.095.043.025.013.046.032.065.057s.028.056.028.093c0 .031-.008.059-.024.084s-.041.045-.073.06c-.033.014-.073.022-.12.022-.046 0-.094-.007-.143-.02-.009-.002-.014-.003-.016-.003-.009 0-.012.006-.01.017z'
						transform='matrix(85.55712 0 0 93.97296 85.605161928 125.311973186)'
					/>
					<path
						d='m.038-.183c0-.029.005-.055.016-.078s.025-.043.044-.06c.018-.017.04-.03.065-.039.024-.009.051-.014.079-.014.026 0 .05.005.073.014.023.01.043.023.06.039.018.017.031.036.041.059s.015.047.015.073c0 .034-.008.066-.024.095-.016.03-.039.054-.068.072-.03.018-.064.027-.102.027-.029 0-.059-.006-.09-.019s-.056-.033-.077-.061c-.021-.029-.032-.064-.032-.108zm.064-.027c0 .03.006.06.018.09s.029.055.051.074c.021.019.045.029.071.029.023 0 .044-.006.062-.019.019-.012.034-.03.045-.053s.016-.05.016-.079c0-.03-.006-.058-.019-.087-.013-.028-.031-.051-.053-.068-.023-.018-.048-.027-.075-.027-.022 0-.042.006-.06.018-.017.012-.031.029-.041.05-.01.022-.015.046-.015.072z'
						transform='matrix(85.55712 0 0 93.97296 125.752484 125.311973186)'
					/>
					<path
						d='m.013.002v-.015c.025-.004.042-.012.052-.022.01-.011.016-.027.019-.048.002-.011.003-.037.005-.076.002-.04.004-.086.006-.137.001-.052.002-.098.002-.139 0-.086-.004-.135-.013-.147-.006-.008-.017-.012-.033-.012-.004 0-.007 0-.009 0l-.013.002v-.015c.062-.026.106-.048.131-.066h.015c-.01.039-.017.108-.019.209l-.004.235c0 .11.002.172.007.187.003.008.013.015.029.019.017.005.032.008.045.009v.018c-.064-.005-.107-.007-.129-.007-.03 0-.06.002-.091.005z'
						transform='matrix(85.55712 0 0 93.97296 165.90016256 125.311973186)'
					/>
					<path
						d='m.345-.254-.244.053c.002.03.005.054.009.071.005.017.013.034.026.051.025.033.056.049.091.049.015 0 .03-.003.047-.008.017-.006.035-.016.055-.03l.009.012c-.057.041-.107.061-.151.061-.047 0-.083-.018-.109-.053s-.039-.075-.039-.121.01-.085.03-.116.042-.053.067-.067c.024-.014.043-.021.056-.021.015 0 .03.002.046.007.015.005.031.014.046.027s.028.032.038.055c.003.005.005.008.008.01.003.001.007.002.014.002zm-.243.034.064-.014c.003-.001.012-.004.03-.008.017-.004.028-.006.034-.007.017-.004.028-.007.032-.008.005-.002.007-.005.007-.008 0-.005-.004-.014-.012-.027-.007-.012-.019-.024-.033-.034-.015-.011-.032-.016-.051-.016-.025 0-.042.013-.052.039-.01.025-.017.053-.019.083z'
						transform='matrix(85.55712 0 0 93.97296 186.45169576 125.311973186)'
					/>
					<path
						d='m.024.001.001-.014c.016-.003.027-.007.034-.01.006-.003.011-.011.016-.023.004-.012.007-.03.009-.054.001-.024.002-.061.002-.111 0-.039-.002-.066-.006-.079-.005-.013-.015-.019-.032-.019-.01 0-.02.001-.031.004l.002-.016.126-.073c.001.02.002.048.002.083l.052-.03c.003-.001.005-.003.007-.004.004-.002.008-.005.012-.007.005-.003.013-.008.024-.014.011-.007.02-.013.026-.017l.004.002c.002.002.004.004.007.005.01.005.017.01.023.016.005.006.008.014.008.025 0 .013-.005.023-.016.031-.01.008-.023.013-.037.015 0-.011-.003-.02-.008-.025-.005-.006-.013-.009-.022-.009-.01 0-.021.003-.035.01-.013.007-.023.014-.03.02-.008.007-.013.013-.014.018-.002.005-.003.016-.003.035-.002.055-.003.084-.003.087 0 .064.003.103.01.118.003.005.006.008.01.009.003.002.009.002.018.002l.013-.001.058-.001-.001.02c-.002 0-.012 0-.031.001s-.031.001-.038.001z'
						transform='matrix(85.55712 0 0 93.97296 217.70143384 125.311973186)'
					/>
					<path
						d='m.12-.288-.06.027c-.003-.011-.004-.018-.004-.023 0-.017.01-.032.031-.046.02-.014.043-.026.069-.035.025-.009.043-.013.053-.013.009 0 .02.004.033.012s.023.015.031.023c.007.007.012.013.016.02.004.006.007.016.01.03.003.013.004.031.004.052 0 .006-.001.028-.002.065l-.002.037c-.001.017-.002.031-.002.042 0 .013.002.028.005.043.004.015.011.023.022.023.013 0 .03-.004.051-.014v.02l-.072.029c-.014.005-.022.007-.024.007-.008 0-.015-.005-.022-.015-.008-.01-.014-.021-.018-.035-.06.033-.107.05-.139.05-.02 0-.035-.006-.045-.017-.01-.012-.015-.029-.015-.052 0-.022.003-.039.008-.049.005-.011.014-.02.026-.026.025-.012.051-.021.078-.027s.056-.012.086-.016l.002-.06v-.016c0-.028-.008-.05-.023-.066-.016-.016-.035-.024-.056-.024-.01 0-.02.003-.03.009-.011.005-.016.014-.016.024 0 .005.002.012.005.021zm.116.231c-.001-.011 0-.041.003-.092-.043-.003-.078.002-.104.016-.026.013-.039.03-.039.05 0 .015.004.028.012.041.008.014.019.02.033.02.016 0 .047-.012.095-.035z'
						transform='matrix(85.55712 0 0 93.97296 244.47724752 125.311973186)'
					/>
					<path
						d='m.562-.359c0-.213-.084-.323-.287-.323-.034 0-.077.002-.111.002-.026 0-.066-.001-.089-.002.006.083.008.192.008.282v.11c0 .09-.003.209-.008.292.031-.002.062-.002.081-.002.031 0 .089.002.123.002.172 0 .283-.138.283-.361zm-.094.002c0 .24-.096.322-.225.322-.025 0-.055-.003-.075-.008-.004-.055-.004-.143-.004-.208v-.176c0-.065.001-.148.005-.211.027-.005.06-.008.091-.008.132 0 .208.072.208.289z'
						transform='matrix(26.55816 0 0 19.780896 74.532318579 151.4059337036)'
					/>
					<path
						d='m.171.002c-.004-.082-.007-.213-.007-.302v-.091c0-.09.003-.209.007-.291-.016.001-.033.002-.048.002s-.033-.001-.048-.002c.005.082.008.201.008.291v.091c0 .09-.003.22-.008.302.016-.001.033-.002.049-.002.014 0 .032.001.047.002z'
						transform='matrix(26.55816 0 0 19.780896 92.75110568 151.4059337036)'
					/>
					<path
						d='m.503-0c-.034-.109-.112-.383-.192-.688h-.07c-.093.284-.199.594-.233.688l.002.002c.009-.001.022-.002.034-.002.009 0 .022.001.031.002.012-.062.039-.162.07-.259.028-.002.062-.002.095-.002.035 0 .076 0 .105.002.029.108.049.196.061.259.017-.002.029-.002.042-.002.016 0 .037 0 .052.002zm-.169-.299c-.025.002-.061.002-.091.002-.027 0-.059 0-.085-.002.025-.086.066-.212.097-.302.024.085.061.226.079.302z'
						transform='matrix(26.55816 0 0 19.780896 101.27627504 151.4059337036)'
					/>
					<path
						d='m.69.002c-.013-.144-.034-.504-.038-.685h-.069c-.067.184-.156.411-.202.523h-.005c-.043-.108-.135-.344-.201-.523h-.071c-.004.148-.026.497-.053.685.013-.001.025-.002.036-.002.01 0 .024.001.034.002-.002-.021-.002-.051-.002-.085 0-.093.014-.399.018-.454h.005c.057.146.145.364.203.526h.026c.051-.153.148-.394.202-.527h.004c.008.108.018.441.018.54.018-.001.033-.002.048-.002s.032.001.047.002z'
						transform='matrix(26.55816 0 0 19.780896 117.15805472 151.4059337036)'
					/>
					<path
						d='m.6-.34c0-.22-.098-.358-.276-.358-.175 0-.274.139-.274.358s.1.358.274.358c.177 0 .276-.141.276-.358zm-.095 0c0 .216-.067.322-.181.322-.112 0-.18-.107-.18-.322 0-.217.067-.322.18-.322.115 0 .181.109.181.322z'
						transform='matrix(26.55816 0 0 19.780896 139.1482112 151.4059337036)'
					/>
					<path
						d='m.516-.682c-.011.001-.022.002-.032.002s-.023-.001-.034-.002c.01.08.013.223.013.333v.196l-.003.001c-.006-.013-.011-.023-.017-.032-.088-.143-.21-.349-.296-.501h-.07c.003.049.006.16.006.272v.072c0 .125-.007.263-.015.343.012-.001.025-.002.036-.002.01 0 .023.001.033.002-.01-.08-.013-.218-.013-.343v-.214h.003c.008.015.017.03.024.042.115.184.237.39.306.516h.05c-.003-.052-.004-.136-.004-.238v-.115c0-.109.004-.252.013-.332z'
						transform='matrix(26.55816 0 0 19.780896 158.37631904 151.4059337036)'
					/>
					<path
						d='m.562-.359c0-.213-.084-.323-.287-.323-.034 0-.077.002-.111.002-.026 0-.066-.001-.089-.002.006.083.008.192.008.282v.11c0 .09-.003.209-.008.292.031-.002.062-.002.081-.002.031 0 .089.002.123.002.172 0 .283-.138.283-.361zm-.094.002c0 .24-.096.322-.225.322-.025 0-.055-.003-.075-.008-.004-.055-.004-.143-.004-.208v-.176c0-.065.001-.148.005-.211.027-.005.06-.008.091-.008.132 0 .208.072.208.289z'
						transform='matrix(26.55816 0 0 19.780896 175.85158832 151.4059337036)'
					/>
					<path
						d='m.501-.68-.003-.002c-.009.001-.023.002-.032.002-.008 0-.02-.001-.029-.002-.033.139-.112.414-.167.585h-.003c-.052-.172-.121-.42-.162-.585-.015.002-.031.002-.041.002-.014 0-.033 0-.052-.002l-.003.002c.036.108.156.513.204.683h.069c.088-.285.186-.593.219-.683z'
						transform='matrix(21.971256 0 0 19.780896 204.3451776189 151.2081337036)'
					/>
					<path
						d='m.503-0c-.034-.109-.112-.383-.192-.688h-.07c-.093.284-.199.594-.233.688l.002.002c.009-.001.022-.002.034-.002.009 0 .022.001.031.002.012-.062.039-.162.07-.259.028-.002.062-.002.095-.002.035 0 .076 0 .105.002.029.108.049.196.061.259.017-.002.029-.002.042-.002.016 0 .037 0 .052.002zm-.169-.299c-.025.002-.061.002-.091.002-.027 0-.059 0-.085-.002.025-.086.066-.212.097-.302.024.085.061.226.079.302z'
						transform='matrix(21.971256 0 0 19.780896 219.395396432 151.2081337036)'
					/>
					<path
						d='m.371.002c-.001-.01-.001-.019-.001-.029s0-.021.001-.03c-.061.011-.138.015-.203.015-.002-.045-.004-.168-.004-.258v-.1c0-.09.002-.202.009-.282-.015.001-.034.002-.049.002-.016 0-.035-.001-.049-.002.006.08.008.192.008.282v.1c0 .09-.003.222-.008.302.036-.001.072-.003.101-.003.06 0 .136.001.195.003z'
						transform='matrix(21.971256 0 0 19.780896 235.28061452 151.2081337036)'
					/>
					<path
						d='m.371.002c-.001-.01-.001-.019-.001-.029s0-.021.001-.03c-.061.011-.138.015-.203.015-.002-.045-.004-.168-.004-.258v-.1c0-.09.002-.202.009-.282-.015.001-.034.002-.049.002-.016 0-.035-.001-.049-.002.006.08.008.192.008.282v.1c0 .09-.003.222-.008.302.036-.001.072-.003.101-.003.06 0 .136.001.195.003z'
						transform='matrix(21.971256 0 0 19.780896 248.045914256 151.2081337036)'
					/>
					<path
						d='m.373.002c-.002-.009-.002-.018-.002-.028 0-.009 0-.021.002-.03-.064.013-.143.015-.207.015-.002-.064-.002-.178-.002-.261v-.037c.093 0 .146.002.177.007-.001-.008-.001-.018-.001-.027s0-.021.001-.028c-.031.005-.083.009-.177.009v-.032c0-.065.002-.185.004-.23.087 0 .152.005.187.013-.001-.01-.001-.019-.001-.028 0-.01 0-.018.001-.027-.042.002-.11.003-.16.003-.036 0-.084-.001-.12-.003.006.082.008.192.008.282v.1c0 .09-.003.22-.008.302.028-.001.068-.003.102-.003.059 0 .144.001.196.003z'
						transform='matrix(21.971256 0 0 19.780896 260.811213992 151.2081337036)'
					/>
					<path
						d='m.462-.68-.004-.002c-.007.001-.024.002-.035.002-.01 0-.022-.001-.03-.002-.014.059-.084.223-.138.335h-.003c-.039-.087-.107-.248-.138-.335-.012.001-.03.002-.044.002-.015 0-.037-.001-.056-.002l-.002.002c.021.042.11.228.185.389v.032c0 .089-.002.185-.007.261.015-.001.032-.002.048-.002.014 0 .032.001.046.002-.004-.076-.007-.167-.007-.261v-.046c.048-.108.163-.339.185-.375z'
						transform='matrix(21.971256 0 0 19.780896 273.906082568 151.2081337036)'
					/>
				</g>
			</g>
		</svg>
	</Logo>
);

export default SoleraDiamondValley;
