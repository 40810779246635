// Imports
import { useState } from 'react';
import styled from 'styled-components/macro';
import Tab from './tab';
import TabContent from './tab-content';
import tabs, { Identifiers } from './tabs';


// Styled components
const Wrapper = styled.div`
	display: grid;
	gap: 3.2rem;
	max-width: 68.75rem;
	width: 100%;
	
	padding-top: 6.25rem;
`;

const Tabs = styled.div`
	width: 100%;
	max-width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	column-gap: 2.5rem;
	row-gap: 0.5rem;
	border-bottom: 0.0625rem solid hsl(206, 70%, 88%);
`;


// Define the accepted props
interface Props {
	storybookActiveTab?: Identifiers;
}


// Function component
const Features: React.FC<Props> = ({ storybookActiveTab }) => {
	// Use state
	const [activeTab, setActiveTab] = useState<Identifiers>(storybookActiveTab ?? 'DOCUMENTS');
	
	
	// Return JSX
	return (
		<Wrapper>
			<Tabs>
				{tabs.map(({ identifier, button }) => (
					<Tab key={identifier} identifier={identifier} activeTab={activeTab} setActiveTab={setActiveTab} {...button} />
				))}
			</Tabs>
			
			{tabs.map(({ identifier, content }) => (
				<TabContent key={identifier} identifier={identifier} activeTab={activeTab} {...content} />
			))}
		</Wrapper>
	);
};

export default Features;
