// Imports
import marked from 'marked';


/** Parse a string as Markdown into HTML, this HTML is not sanitized */
export default function parseMarkdown(markdown: string): string {
	return marked(markdown, {
		gfm: true,
		breaks: true,
	});
}
