// Imports
import styled from 'styled-components/macro';
import Quote, { Props as QuoteProps } from './quote';


// Styled components
const Wrapper = styled.ol<{ centered?: boolean }>`
	display: flex;
	width: 100%;
	justify-content: ${({ centered }) => (centered ? 'center' : 'space-evenly')};
	row-gap: 3.125rem;
	column-gap: 2.625rem;
	flex-wrap: wrap;
	list-style: none;
	padding: 0.67rem 0;
`;


// Define the accepted props
interface Props {
	quotes: QuoteProps[];
	centered?: boolean;
	className?: string;
}


// Function component
const Quotes: React.FC<Props> = ({ quotes, centered, className }) => (
	<Wrapper centered={centered} className={className}>
		{quotes.map((quote, i) => (
			<Quote key={i} {...quote} />
		))}
	</Wrapper>
);

export default Quotes;
