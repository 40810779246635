// Imports
import { Outlet, Route } from 'react-router-dom';
import NotFound from './components/pages/not-found';
import Home from './components/sections/home';
import featuresSectionRoutes from './components/sections/features/section-routes';
import Pricing from './components/sections/pricing';
import policiesSectionRoutes from './components/sections/policies/section-routes';
import pricingDetailsSectionRoutes from './components/sections/pricing-details/section-routes';
import websiteSectionRoutes from './components/sections/website/section-routes';
import Careers from './components/sections/careers';
import AboutUs from './components/sections/about-us';
import Invoice from './components/sections/invoice';
import redirectRoutes from './redirect-routes';


// Routes
// LATER: Handle suspense fallbacks
const appRoutes = (
	<Route
		element={
			<main id='main-content'>
				<Outlet />
			</main>
		}
	>
		<Route index element={<Home />} />
		<Route path='pricing' element={<Pricing />} />
		<Route path='careers' element={<Careers />} handle={{ hideCTA: true }} />
		<Route path='about' element={<AboutUs />} handle={{ title: 'About us' }} />
		<Route path='invoice' element={<Invoice />} handle={{ hideCTA: true }} />
		{featuresSectionRoutes}
		{policiesSectionRoutes}
		{pricingDetailsSectionRoutes}
		{websiteSectionRoutes}
		{redirectRoutes}
		<Route path='*' element={<NotFound />} handle={{ hideCTA: true }} />
	</Route>
);

export default appRoutes;
