// Imports
import WebsitePageTemplate from './template';


// Function component
const WebsiteTakenDown: React.FC = () => (
	<WebsitePageTemplate
		heading='Website taken down'
		descriptor='It cannot be accessed'
		subheading='A copyright infringement claim was made'
	>
		<p>
			That community website was taken down due to a copyright infringement claim in accordance with the Digital
			Millennium Copyright Act.
		</p>
	</WebsitePageTemplate>
);

export default WebsiteTakenDown;
