// Imports
import styled, { css } from 'styled-components/macro';
import { View } from '..';
import Button, { pillBorderRadius } from './button';


// Styled components
const Wrapper = styled.div`
	display: flex;
	width: fit-content;
	padding: 0.5rem;
	align-items: center;
	overflow: hidden;
	
	border: 0.125rem solid hsl(206, 25%, 20%);
	
	margin-top: 3.75rem;
	
	position: relative;
	
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		box-shadow: inset 0 0 0 0.5rem hsl(0, 0%, 100%);
		
		${pillBorderRadius}
	}
	
	${pillBorderRadius}
`;

const Discount = styled.span<{ isActive: boolean }>`
	display: flex;
	padding: 0.25rem 0.375rem;
	align-items: center;
	
	font-size: 1rem;
	line-height: 1;
	
	transition: background-color 0.1s ease-out;
	
	${({ isActive }) =>
		isActive
			? css`
					background-color: hsl(96, 65%, 41%);
					transition-delay: 0.1s;
					transition-timing-function: ease-in;
			  `
			: css`
					background-color: transparent;
			  `}
	
	${pillBorderRadius}
`;

const YearlyButton = styled(Button)`
	> span:first-child {
		padding-right: 0.75rem;
	}
`;


// Define the accepted props
interface Props {
	view: View;
	setView: React.Dispatch<React.SetStateAction<View>>;
}


// Function component
const Toggle: React.FC<Props> = ({ view, setView }) => (
	<Wrapper>
		<Button identifier='MONTHLY' view={view} setView={setView}>
			Monthly
		</Button>
		<YearlyButton identifier='YEARLY' view={view} setView={setView}>
			Yearly <Discount isActive={view === 'YEARLY'}>save 10%</Discount>
		</YearlyButton>
	</Wrapper>
);

export default Toggle;
