import React from 'react';
import { Link } from 'react-router-dom';


// Types
export interface QuestionAndAnswer {
	question: string;
	answer: string | JSX.Element;
}


// Questions and answers
const questionsAndAnswers: QuestionAndAnswer[] = [
	{
		question: 'What payment methods are supported for subscriptions?',
		answer:
			'Subscriptions can be paid for using any major credit/debit card. Communities in the United States can also make payments using a bank account or by mailing in checks, if it’s more convenient.',
	},
	{
		question: 'What counts as a household?',
		answer:
			'On our platform, a household is a related set of residents and addresses. Typically, a household entails one or two people along with one address. But a household can accommodate multiple addresses (e.g., if someone owns multiple homes in your community) or more than two people. Households can also list children and pets to display in your directories.',
	},
	{
		question: 'Does HOA Express offer refunds?',
		answer: (
			<React.Fragment>
				We’re confident you’ll love our service! Just in case, we offer a{' '}
				<Link to='/policies/our-guarantee'>30-day money-back guarantee</Link>.
			</React.Fragment>
		),
	},
	{
		question: 'Can we use our community’s existing domain?',
		answer:
			'Absolutely! It’s quick and easy to use your existing domain with our service. When you’re ready to set up your existing domain, our interface will guide you through the process step-by-step.',
	},
	{
		question: 'Do you offer a bulk discount?',
		answer: (
			<React.Fragment>
				Yes! Our reseller program, which is designed for association management companies, neighborhood developers, real
				estate agents, consulting firms, or others who want to purchase multiple websites, offers discounted pricing.{' '}
				<a
					href='https://help.hoa-express.com/en/articles/9294616-about-our-reseller-program'
					rel='noopener noreferrer'
					target='_blank'
				>
					Learn more
				</a>{' '}
				about this program.
			</React.Fragment>
		),
	},
];

export default questionsAndAnswers;
