// Imports
import React from 'react';
import SectionWithStickyNavbar from '../../resources/section-with-sticky-navbar';


// Function component
const Policies: React.FC = () => (
	<SectionWithStickyNavbar
		heading='Policies'
		descriptor='Details about our product & services'
		links={[
			{ to: '/policies/privacy-policy', text: 'Privacy policy' },
			{ to: '/policies/terms-of-service', text: 'Terms of service' },
			{ to: '/policies/our-guarantee', text: 'Our guarantee' },
		]}
		spaceBetweenNavbarAndContent={1.75}
	/>
);

export default Policies;
