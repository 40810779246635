// Imports
import thunkMiddleware, { ThunkMiddleware } from 'redux-thunk';
import { createStore, applyMiddleware, AnyAction } from 'redux';
import rootReducer from '../../../../redux/reducer';


// Type the Thunk middleware to get correct type inferences in the store
const typedThunkMiddleware: ThunkMiddleware<ReturnType<typeof rootReducer>, AnyAction> = thunkMiddleware;


// Create store
const store = createStore(rootReducer, applyMiddleware(typedThunkMiddleware));


// Export
export default store;
