// Imports
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/pro-solid-svg-icons';


// Function component
const Container: React.FC = () => {
	// Return JSX
	return (
		<tr>
			<td colSpan={999} style={{ textAlign: 'center', fontStyle: 'italic' }}>
				<FontAwesomeIcon icon={faArrowsRotate} spin fixedWidth /> Loading…
			</td>
		</tr>
	);
};

const LoadingRow = React.memo(Container);

export default LoadingRow;
