// Imports
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useMatches } from 'react-router-dom';


// Function component
const Title: React.FC = () => {
	// Use React Router functionality
	const matches = useMatches();
	
	
	// Build title
	const isCustomTitle = (handle: unknown): handle is { title: string } =>
		Boolean(typeof handle === 'object' && handle && 'title' in handle);
	
	const title = matches
		.map((match) => ({
			...match,
			pathPiece: match.pathname.slice(match.pathname.lastIndexOf('/') + 1),
		}))
		.filter((match) => {
			// Filter values we never display in the title
			const decodedURIPiece = decodeURIComponent(match.pathPiece);
			
			if (
				!isNaN(parseInt(match.pathPiece)) ||
				decodedURIPiece.includes('https://') ||
				decodedURIPiece.includes('@') ||
				decodedURIPiece.includes('.')
			) {
				return false;
			}
			
			
			// Filter empty strings
			return Boolean(match.pathPiece);
		})
		.map((match) => {
			if (isCustomTitle(match.handle)) {
				return match.handle.title;
			}
			
			return `${match.pathPiece.charAt(0).toUpperCase()}${match.pathPiece.slice(1).replace(/-/g, ' ')}`;
		})
		.reverse()
		.join(' // ');
	
	
	// Return JSX
	return <Helmet title={title} />;
};

export default Title;
