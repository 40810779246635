// Imports
import styled from 'styled-components/macro';
import Dropdown, { Menu, MenuItem, MenuItemIcon, MenuItemTitle } from './dropdown';


// Styled components
const UserDropdown = styled(Dropdown)`
	> ${Menu} {
		left: unset;
		right: 0;
		
		min-width: unset;
		grid-template-columns: unset;
		width: fit-content;
		
		gap: 1rem;
		
		${MenuItem} {
			grid-template-columns: 1.875rem auto;
			grid-template-rows: 1.875rem;
			row-gap: 0;
			
			white-space: nowrap;
			
			${MenuItemTitle} {
				grid-area: 1 / 2 / 2 / 3;
				display: flex;
				align-items: center;
			}
			
			${MenuItemIcon} {
				width: 1.875rem;
				height: 1.875rem;
				
				> svg {
					font-size: 0.84375rem;
					line-height: 1;
				}
			}
		}
	}
	
	@media (max-width: 70rem) {
		display: none;
	}
`;

export default UserDropdown;
