// Imports
import React from 'react';
import Feature from '..';
import feature from './feature';


// Function component
const OnlinePayments: React.FC = () => <Feature {...feature} />;

export default OnlinePayments;
