// Imports
import isObject from 'lodash/isObject';


// Define the types
interface KVStorage {
	[index: string]: string;
}

interface KVStorageFunctionality {
	keyValueSubscribers: {
		[key: string]: ((newValue: string | undefined) => void)[];
	};
	inMemoryStorage: {
		[key: string]: string | null;
	};
}

type KVStorageEnabledWindow = typeof window & KVStorageFunctionality;


// Handles checking if key value storage is initialized
const keyValueStorageInitialized = (
	object: typeof window | KVStorageEnabledWindow
): object is KVStorageEnabledWindow => {
	if (!('keyValueSubscribers' in object) || !isObject(object.keyValueSubscribers)) {
		return false;
	}
	
	if (!('inMemoryStorage' in object) || !isObject(object.inMemoryStorage)) {
		return false;
	}
	
	return true;
};


// Initialize
if (!('inMemoryStorage' in window)) {
	(window as { inMemoryStorage?: KVStorageFunctionality['inMemoryStorage'] }).inMemoryStorage = {};
}

if (!('keyValueSubscribers' in window)) {
	(window as { keyValueSubscribers?: KVStorageFunctionality['keyValueSubscribers'] }).keyValueSubscribers = {};
}

const thirdPartyIndicators = ['canny', 'upscope', 'intercom'];


// Hydrate from local storage
if (localStorageAvailable()) {
	if (!keyValueStorageInitialized(window)) {
		throw new Error('Key value storage not enabled');
	}
	
	for (const key of Object.keys(localStorage)) {
		let thirdParty = false;
		
		for (const indicator of thirdPartyIndicators) {
			if (key.includes(indicator)) {
				thirdParty = true;
			}
		}
		
		if (!thirdParty) {
			window.inMemoryStorage[key] = localStorage.getItem(key);
		}
	}
}


// Trigger callbacks with new values
const triggerSubscriberCallbacks = (name?: string, newValue?: string | undefined) => {
	if (!keyValueStorageInitialized(window)) {
		throw new Error('Key value storage not enabled');
	}
	
	if (!name) {
		for (const key of Object.keys(window.keyValueSubscribers)) {
			for (const callback of window.keyValueSubscribers[key]) {
				if (typeof callback !== 'function') {
					continue;
				}
				
				callback(undefined);
			}
		}
		
		return;
	}
	
	if (!Array.isArray(window.keyValueSubscribers[name])) {
		return;
	}
	
	for (const callback of window.keyValueSubscribers[name]) {
		if (typeof callback !== 'function') {
			continue;
		}
		
		callback(newValue);
	}
};


// Handles checking for support
function localStorageAvailable() {
	try {
		const testKey = 'test-local-storage-support';
		localStorage.setItem(testKey, testKey);
		localStorage.removeItem(testKey);
		return true;
	} catch (e) {
		return false;
	}
}


/** Like localStorage, but it always does everything in memory first, and then replicates the actions in localStorage if possible. This ensures that if localStorage stops working, or is unavailable entirely, the app still behaves appropriately. For more info, see: https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/. */
const keyValueStorage = {
	/** Retrieve an item */
	getItem(name: string): string | null {
		if (!keyValueStorageInitialized(window)) {
			throw new Error('Key value storage not enabled');
		}
		
		if (Object.prototype.hasOwnProperty.call(window.inMemoryStorage, name)) {
			return window.inMemoryStorage[name];
		}
		
		if (localStorageAvailable()) {
			return localStorage.getItem(name);
		}
		
		return null;
	},
	
	
	/** Remove an item */
	removeItem(name: string) {
		if (!keyValueStorageInitialized(window)) {
			throw new Error('Key value storage not enabled');
		}
		
		delete window.inMemoryStorage[name];
		
		if (localStorageAvailable()) {
			localStorage.removeItem(name);
		}
		
		triggerSubscriberCallbacks(name, undefined);
	},
	
	
	/** Store an item */
	setItem(name: string, value: string) {
		if (!keyValueStorageInitialized(window)) {
			throw new Error('Key value storage not enabled');
		}
		
		window.inMemoryStorage[name] = value;
		
		if (localStorageAvailable()) {
			try {
				localStorage.setItem(name, value);
			} catch (e) {
				// Probably exceeded a quota; remove any now-stale data this may have been replacing
				try {
					localStorage.removeItem(name);
				} catch (e) {
					// Do nothing
				}
			}
		}
		
		triggerSubscriberCallbacks(name, value);
	},
	
	
	/** Clear all data */
	clear() {
		if (!keyValueStorageInitialized(window)) {
			throw new Error('Key value storage not enabled');
		}
		
		window.inMemoryStorage = {};
		
		if (localStorageAvailable()) {
			localStorage.clear();
		}
		
		triggerSubscriberCallbacks();
	},
	
	
	/** Clear storage, but leave certain local storage keys untouched */
	clearSelectively(extraKeysToKeep: string[] = []) {
		if (!keyValueStorageInitialized(window)) {
			throw new Error('Key value storage not enabled');
		}
		
		if (localStorageAvailable()) {
			// Keys with these strings we want to keep
			const keepIndicators = ['canny', 'plausible_ignore'];
			
			
			// Find keys we care to key
			const keepData: KVStorage = {};
			
			for (const key of Object.keys(localStorage)) {
				for (const indicator of keepIndicators) {
					if (key.includes(indicator) || extraKeysToKeep.includes(key)) {
						keepData[key] = String(localStorage.getItem(key)) || '';
					}
				}
			}
			
			
			// Clear storage
			localStorage.clear();
			
			
			// Reinstate those keys
			for (const [key, value] of Object.entries(keepData)) {
				localStorage.setItem(key, value);
			}
		}
		
		for (const [key] of Object.entries(window.inMemoryStorage)) {
			if (!extraKeysToKeep.includes(key)) {
				delete window.inMemoryStorage[key];
				triggerSubscriberCallbacks(key, undefined);
			}
		}
	},
	
	
	/** Subscribe to changes to a key */
	subscribe(name: string, callback: (newValue: string | undefined) => void) {
		if (!keyValueStorageInitialized(window)) {
			throw new Error('Key value storage not enabled');
		}
		
		if (!window.keyValueSubscribers[name]) {
			window.keyValueSubscribers[name] = [];
		}
		
		return window.keyValueSubscribers[name].push(callback) - 1;
	},
	
	
	/** Unsubscribe from changes to a key */
	unsubscribe(name: string, index: number) {
		if (!keyValueStorageInitialized(window)) {
			throw new Error('Key value storage not enabled');
		}
		
		if (!Array.isArray(window.keyValueSubscribers[name])) {
			return;
		}
		
		delete window.keyValueSubscribers[name][index];
	},
};

export default keyValueStorage;
