// Types
export interface Rating {
	rating: number;
	site: {
		name: string;
		subtext?: string;
		url?: string;
	};
}


// Ratings
const ratings: Rating[] = [
	{
		site: {
			name: 'Facebook',
			url: 'https://www.facebook.com/HOAExpress/reviews',
		},
		rating: 4.9,
	},
	{
		site: {
			name: 'Capterra',
			url: 'https://www.capterra.com/p/161593/HOA-Express-HOA-Websites/',
		},
		rating: 4.9,
	},
	{
		site: {
			name: 'support ratings',
			subtext: '1,100+ ratings last 6 mo.',
		},
		rating: 4.7,
	},
];

export default ratings;
