// Imports
import Logo from './logo';


// Function component
const TheHaven: React.FC = () => (
	<Logo width={7.3} aria-label='The Haven'>
		<svg
			clipRule='evenodd'
			fillRule='evenodd'
			strokeLinejoin='round'
			strokeMiterlimit='2'
			viewBox='0 0 228 78'
			xmlns='http://www.w3.org/2000/svg'
			aria-hidden
		>
			<g fillRule='nonzero'>
				<path
					d='m.181-.014h.111v-.684h-.058c-.013 0-.028.001-.045.003-.017.003-.033.009-.05.02s-.034.027-.049.05c-.016.023-.03.055-.041.096h-.011v-.183h.608v.183h-.011c-.011-.041-.025-.073-.04-.096-.016-.023-.032-.039-.049-.05s-.034-.017-.051-.02c-.017-.002-.032-.003-.045-.003h-.058v.684h.111v.014h-.322z'
					transform='matrix(34.2096 0 0 34.2096 53.3795172 25.0408026)'
				/>
				<path
					d='m.025-.014h.077v-.693h-.077v-.014h.163v.393h.002c.003-.009.009-.019.017-.033.008-.013.019-.025.033-.037.013-.011.03-.021.05-.03.019-.008.043-.012.07-.012.044 0 .08.011.108.032.027.021.041.055.041.102v.292h.077v.014h-.24v-.014h.077v-.323c0-.026-.006-.047-.018-.064-.013-.017-.032-.025-.058-.025-.021 0-.042.005-.061.014s-.036.022-.051.039-.026.036-.034.059c-.009.023-.013.047-.013.074v.226h.077v.014h-.24z'
					transform='matrix(34.2096 0 0 34.2096 76.7788836 25.0408026)'
				/>
				<path
					d='m.352-.262c.001-.009.002-.018.002-.026v-.025c0-.023-.002-.041-.006-.056-.005-.015-.011-.026-.018-.035-.008-.009-.017-.015-.028-.019-.011-.003-.023-.005-.036-.005-.023 0-.041.004-.056.013s-.026.022-.035.037-.015.033-.019.053c-.005.02-.008.041-.009.063zm-.206.014v.02c-.001.007-.001.016-.001.027 0 .022.001.045.004.069s.009.046.018.065c.008.019.022.035.04.048.017.013.041.019.07.019s.054-.004.073-.013.034-.019.045-.032c.011-.012.019-.024.024-.037s.009-.023.012-.031l.015.003c-.002.007-.006.018-.011.031-.006.014-.015.028-.027.041-.013.014-.029.026-.05.036-.021.011-.049.016-.082.016s-.064-.005-.092-.016c-.029-.011-.053-.026-.074-.046s-.037-.044-.048-.072c-.011-.027-.017-.058-.017-.092 0-.029.006-.058.018-.086.011-.027.027-.052.048-.073.02-.021.044-.038.071-.052.027-.013.057-.019.088-.019.019 0 .039.003.06.01s.041.017.06.032c.018.015.033.035.046.06.012.025.018.055.018.092z'
					transform='matrix(34.2096 0 0 34.2096 97.6809492 25.0408026)'
				/>
				<path
					d='m.03-.014h.111v-.684h-.111v-.014h.322v.014h-.111v.327h.333v-.327h-.111v-.014h.322v.014h-.111v.684h.111v.014h-.322v-.014h.111v-.343h-.333v.343h.111v.014h-.322z'
					transform='matrix(71.95536 0 0 71.95536 -1.80535578 76.75405508)'
				/>
				<path
					d='m.324-.597-.11.291h.22zm-.325.583h.088l.27-.716h.024l.272.716h.089v.014h-.308v-.014h.111l-.105-.278h-.232l-.105.278h.111v.014h-.215z'
					transform='matrix(59.62176 0 0 60.94848 56.93866568 76.7433856)'
				/>
				<path
					d='m-.012-.712h.309v.014h-.111l.222.58.219-.58h-.109v-.014h.216v.014h-.09l-.269.713h-.025l-.272-.713h-.09z'
					transform='matrix(59.62176 0 0 60.94848 101.11838984 76.7433856)'
				/>
				<path
					d='m.03-.014h.111v-.684h-.111v-.014h.572v.173h-.011c-.009-.026-.018-.049-.028-.069-.01-.019-.022-.036-.036-.05-.014-.013-.031-.023-.05-.03s-.043-.01-.07-.01h-.166v.309h.065c.022 0 .041-.002.056-.006s.029-.012.04-.023c.011-.01.02-.026.028-.045.007-.019.015-.043.022-.073h.011v.311h-.011c-.005-.019-.009-.038-.014-.056-.006-.018-.012-.034-.021-.048s-.021-.025-.036-.034c-.015-.008-.035-.012-.059-.012h-.081v.361h.189c.037 0 .067-.008.092-.024.024-.015.044-.034.059-.056.015-.021.027-.044.034-.067s.012-.043.014-.058h.011v.219h-.61z'
					transform='matrix(59.62176 0 0 60.94848 144.16530056 76.7433856)'
				/>
				<path
					d='m.021-.014h.111v-.684h-.111v-.014h.214l.338.545h.002v-.531h-.111v-.014h.237v.014h-.111v.712h-.02l-.421-.671h-.002v.643h.111v.014h-.237z'
					transform='matrix(59.62176 0 0 60.94848 186.1390196 76.7433856)'
				/>
			</g>
		</svg>
	</Logo>
);

export default TheHaven;
