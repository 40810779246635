// Imports
import styled from 'styled-components/macro';
import Item from './item';


// Styled components
const Wrapper = styled.figure`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2.5rem;
	
	width: 100%;
	
	margin-top: 4.56rem;
	padding: 3.75rem 3.125rem;
	
	background-color: hsl(36, 100%, 95%);
	box-shadow: 0 0 0 100vw hsl(36, 100%, 95%);
	clip-path: inset(0 -100vw);
	
	@media (max-width: 48rem) {
		padding: 3.75rem 2.5rem;
	}
`;

const Heading = styled.figcaption`
	color: hsl(36, 56%, 69%);
	text-align: center;
	font-family: Zilla Slab;
	font-size: 3.125rem;
	font-weight: 600;
	line-height: 1;
	
	@media (max-width: 48rem) {
		font-size: 2.5rem;
	}
`;

const Steps = styled.ol`
	display: inline-grid;
	width: 100%;
	grid-auto-flow: column;
	grid-auto-columns: 1fr;
	gap: 2.5rem;
	list-style: none;
	
	@media (max-width: 48rem) {
		grid-auto-flow: row;
		justify-items: center;
		gap: 1.5rem;
	}
`;


// Define the accepted props
export interface BannerListItem {
	prefix?: string;
	label: string;
	description: string;
}

interface Props extends React.PropsWithChildren {
	items: BannerListItem[];
	heading?: string;
	ordered?: boolean;
	className?: string;
}


// Function component
const BannerWithList: React.FC<Props> = ({ items: steps, heading, ordered, className, children }) => (
	<Wrapper className={className}>
		{heading && <Heading>Getting started couldn’t be easier</Heading>}
		
		<Steps as={ordered ? 'ol' : 'ul'}>
			{steps.map((step, i) => (
				<Item key={i} ordered={ordered} {...step} />
			))}
		</Steps>
		
		{children}
	</Wrapper>
);

export default BannerWithList;
