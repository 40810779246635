// Imports
import {
	PageMessage,
	UserDetails,
	ROUTE_CHANGED,
	SIGNED_IN,
	SIGNED_OUT,
	SHOW_PAGE_MESSAGE,
	HIDE_PAGE_MESSAGE,
	INCREMENT_NETWORK_REQUEST_COUNT,
	DECREMENT_NETWORK_REQUEST_COUNT,
	RouteChangedAction,
	SignedInAction,
	SignedOutAction,
	ShowPageMessageAction,
	HidePageMessageAction,
	IncrementNetworkRequestCountAction,
	DecrementNetworkRequestCountAction,
} from './types';


// Exports
const commonActions = {
	// Handles a route change
	routeChanged(): RouteChangedAction {
		return {
			type: ROUTE_CHANGED,
		};
	},
	
	
	// Handles changing state to signed in
	signedIn(details: UserDetails): SignedInAction {
		return {
			type: SIGNED_IN,
			...details,
		};
	},
	
	
	// Handles changing state to signed out
	signedOut(): SignedOutAction {
		return {
			type: SIGNED_OUT,
		};
	},
	
	
	// Show page message
	showPageMessage(details: PageMessage): ShowPageMessageAction {
		return {
			type: SHOW_PAGE_MESSAGE,
			...details,
		};
	},
	
	
	// Hide page message
	hidePageMessage(index: number): HidePageMessageAction {
		return {
			type: HIDE_PAGE_MESSAGE,
			index,
		};
	},
	
	
	// Increment network request count
	incrementActiveRequestCount(): IncrementNetworkRequestCountAction {
		return {
			type: INCREMENT_NETWORK_REQUEST_COUNT,
		};
	},
	
	
	// Decrement network request count
	decrementActiveRequestCount(): DecrementNetworkRequestCountAction {
		return {
			type: DECREMENT_NETWORK_REQUEST_COUNT,
		};
	},
};

export default commonActions;
