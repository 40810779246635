// Imports
import letterPricing from '../../../../shareables/utils/billing/letter-pricing';
import formatCurrency from '../../../../shareables/utils/project-specific/formatting/format-currency';
import Subheading from '../subheading';
import PricingDetailsTable from '../table';
import MailedLettersRow from './row';


// Function component
const MailedLetters: React.FC = () => (
	<div>
		<Subheading>Mailed letter</Subheading>
		
		<p>
			We offer simple per-letter pricing whether you’re sending one letter or thousands. The minimum total price is just{' '}
			{formatCurrency(letterPricing.minimum)}.
		</p>
		
		<PricingDetailsTable>
			<thead>
				<tr>
					<th>Item</th>
					<th>Price</th>
				</tr>
			</thead>
			
			<tbody>
				<MailedLettersRow
					item={{ text: 'Base price', detail: 'Covers postage/supplies/handling' }}
					price={{ price: letterPricing.base }}
				/>
				<MailedLettersRow item={{ text: 'Pages' }} price={{ price: letterPricing.perPage, detail: 'per page' }} />
				<MailedLettersRow
					item={{ text: 'Print in color' }}
					price={{ price: letterPricing.additionalPerPageInColor, detail: 'extra per page' }}
				/>
			</tbody>
		</PricingDetailsTable>
	</div>
);

export default MailedLetters;
