// Imports
import { toWords } from 'number-to-words';
import React from 'react';
import styled from 'styled-components/macro';
import ucfirst from '../../../../shareables/utils/formatting/ucfirst';


// Styled components
const Heading = styled.h2`
	font-size: 2rem;
	font-weight: 600;
	line-height: 1.2;
	margin-bottom: 1rem;
`;

const Story = styled.div`
	max-width: 50rem;
`;


// Function component
const OurStory: React.FC = () => (
	<div>
		<Heading>Our story</Heading>
		
		<Story>
			<p>
				{ucfirst(toWords(new Date(Date.now()).getFullYear() - 2009))} years ago, HOA websites were difficult to create
				and maintain, expensive, or both. HOA Express was built by a website design company looking to solve these
				challenges for some of its clients, in a way no other product on the market at the time did. We launched with a
				compelling set of features at a great price, and it led to rapid growth.{' '}
				<b>We’ve never changed that winning formula.</b>
			</p>
			
			<p>
				Today, we’re actively improving upon the best website builder for HOAs—and have been ever since our initial
				launch. We’ve built up a talented customer success team that provides world class service to our customers. We
				regularly publish articles on our blog to help communities create incredible places to live.
			</p>
			
			<p>
				Thousands of communities in every U.S. state and more than twenty countries across the globe count on HOA
				Express to power their website. We’re proud to serve each and every one.
			</p>
		</Story>
	</div>
);

export default OurStory;
