// Imports
import WebsitePageTemplate from './template';


// Function component
const WebsiteDeleted: React.FC = () => (
	<WebsitePageTemplate
		heading='Website deleted'
		descriptor='It cannot be accessed'
		subheading='That website has been deleted'
	>
		<p>
			The website you tried to view will be permanently deleted after a brief waiting period. If you are an
			administrator of that community website, you can{' '}
			<a rel='noopener' href={`https://app.${process.env.REACT_APP__ENVIRONMENT_HOSTNAME}`}>
				stop it from being permanently deleted here
			</a>
			.
		</p>
	</WebsitePageTemplate>
);

export default WebsiteDeleted;
