// Imports
import WebsitePageTemplate from './template';


// Function component
const WebsiteDisabled: React.FC = () => (
	<WebsitePageTemplate
		heading='Website disabled'
		descriptor='It cannot be accessed'
		subheading='That website has been disabled'
	>
		<p>
			If you are an administrator of that community website, you can{' '}
			<a rel='noopener' href={`https://app.${process.env.REACT_APP__ENVIRONMENT_HOSTNAME}`}>
				reactivate it here
			</a>
			.
		</p>
	</WebsitePageTemplate>
);

export default WebsiteDisabled;
