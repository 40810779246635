// Imports
import { faShieldCheck } from '@fortawesome/pro-regular-svg-icons';
import Feature from '..';


// Banner
const feature: React.ComponentProps<typeof Feature> = {
	banner: {
		label: { icon: faShieldCheck, text: 'Security' },
		heading: 'Robust tools to keep community & resident information safe',
		description:
			'Finely tune who can access information on your website by requiring residents to sign in and restricting content to people who meet specific criteria. Plus, our platform has industry-leading security baked in—never at an extra cost.',
		screenshot: {
			width: 900,
			height: 636,
			src: '/images/screenshots/features/security/banner.webp',
		},
	},
	pitch: { text: 'Rest easy knowing your website is secure', textMaxWidth: 'min(30.0625rem, 40.1vw)' },
	tiles: {
		leftColumn: [
			{
				heading: 'Restrict website content',
				screenshot: {
					width: 980,
					height: 700,
					src: (layout) => `/images/screenshots/features/security/${layout}/restrictions.webp`,
				},
			},
			{
				heading: 'Registrations require approval',
				screenshot: {
					width: 980,
					height: 304,
					src: (layout) => `/images/screenshots/features/security/${layout}/approval.webp`,
				},
			},
			{
				heading: 'Advanced spam protection',
				screenshot: {
					width: 668,
					height: 206,
					src: (layout) => `/images/screenshots/features/security/${layout}/spam-protection.webp`,
				},
			},
			{
				heading: 'Grant specific admin privileges',
				screenshot: {
					width: 980,
					height: 448,
					src: (layout) => `/images/screenshots/features/security/${layout}/admin-privileges.webp`,
				},
			},
		],
		rightColumn: [
			{
				heading: 'Complete HTTPS + HSTS',
				screenshot: {
					width: 643,
					height: 472,
					src: (layout) => `/images/screenshots/features/security/${layout}/https-hsts.webp`,
				},
			},
			{
				heading: 'Detailed privacy preferences',
				screenshot: {
					width: 980,
					height: 766,
					src: (layout) => `/images/screenshots/features/security/${layout}/privacy-preferences.webp`,
				},
			},
			{
				heading: 'Email spoofing prevention',
				screenshot: {
					width: 627,
					height: 206,
					src: (layout) => `/images/screenshots/features/security/${layout}/spoofing-prevention.webp`,
				},
			},
			{
				heading: 'Entropy-based strength meter',
				screenshot: {
					width: 780,
					height: 258,
					src: (layout) => `/images/screenshots/features/security/${layout}/password-strength.webp`,
				},
			},
		],
	},
	testimonials: [
		{
			text: 'Members who don’t want to show their address or phone number can easily update this in their privacy preferences. That’s one of the reasons I like your service so much.',
			source: {
				name: 'Heinz',
				hoa: 'Sea Palms POA',
				url: 'https://www.seapalmspoa.com/',
			},
		},
		{
			text: 'We are looking forward to a more secure site with more features!',
			source: {
				name: 'Tara',
				hoa: 'Innsbruck POA',
				url: 'https://www.innsbruckpoa.org/',
			},
		},
	],
};

export default feature;
