// Imports
import WebsitePageTemplate from './template';


// Function component
const WebsiteNotFound: React.FC = () => (
	<WebsitePageTemplate heading='Website not found' descriptor='It does not exist' subheading='Find your communities'>
		<p>
			<a rel='noopener' href={`https://app.${process.env.REACT_APP__ENVIRONMENT_HOSTNAME}`}>
				Sign in here
			</a>{' '}
			to view the community websites you’re a member of.
		</p>
	</WebsitePageTemplate>
);

export default WebsiteNotFound;
