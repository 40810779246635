// Imports
import { faSquarePollVertical } from '@fortawesome/pro-regular-svg-icons';
import Feature from '..';


// Banner
const feature: React.ComponentProps<typeof Feature> = {
	banner: {
		label: { icon: faSquarePollVertical, text: 'Surveys' },
		heading: 'Give residents a voice with anonymous & customizable surveys',
		description:
			'HOA Express gives you the ability to collect feedback from residents, helping you make more-informed decisions. Use surveys to promote a sense of involvement and ensure resident input is considered.',
		screenshot: {
			width: 900,
			height: 630,
			src: '/images/screenshots/features/surveys/banner.webp',
		},
	},
	pitch: { text: 'Value your residents’ input and foster involvement', textMaxWidth: 'min(36.8125rem, 49.1vw)' },
	tiles: {
		leftColumn: [
			{
				heading: 'Collect open-ended responses',
				screenshot: {
					width: 980,
					height: 400,
					src: (layout) => `/images/screenshots/features/surveys/${layout}/free-response.webp`,
				},
			},
			{
				heading: 'Quickly announce',
				screenshot: {
					width: 980,
					height: 260,
					src: (layout) => `/images/screenshots/features/surveys/${layout}/announce.webp`,
				},
			},
			{
				heading: 'Provide an introduction',
				screenshot: {
					width: 980,
					height: 358,
					src: (layout) => `/images/screenshots/features/surveys/${layout}/introduction.webp`,
				},
			},
			{
				heading: 'Optionally share results with residents',
				screenshot: {
					width: 751,
					height: 512,
					src: (layout) => `/images/screenshots/features/surveys/${layout}/share-results.webp`,
				},
			},
		],
		rightColumn: [
			{
				heading: 'Specify preset choices',
				screenshot: {
					width: 880,
					height: 456,
					src: (layout) => `/images/screenshots/features/surveys/${layout}/preset-choices.webp`,
				},
			},
			{
				heading: 'Limit responses',
				screenshot: {
					width: 827,
					height: 264,
					src: (layout) => `/images/screenshots/features/surveys/${layout}/limit-responses.webp`,
				},
			},
			{
				heading: 'Activate & deactivate at any time',
				screenshot: {
					width: 980,
					height: 260,
					src: (layout) => `/images/screenshots/features/surveys/${layout}/activate-deactivate.webp`,
				},
			},
			{
				heading: 'Export the results',
				screenshot: {
					width: 580,
					height: 172,
					src: (layout) => `/images/screenshots/features/surveys/${layout}/export.webp`,
				},
			},
		],
	},
	testimonials: [
		{
			text: 'We are starting to use the survey feature more and more. I like the idea of keeping residents on the website rather than sending them to SurveyMonkey.',
			source: {
				name: 'Wendy',
				hoa: 'Crystal Creek HOA',
				url: 'https://www.crystalcreek-hoa.com/',
			},
		},
		{
			text: 'Having the survey go to everybody […] reinforces the philosophy we have about asking for member input.',
			source: {
				name: 'Bob',
				hoa: 'Dog & Cranberry Lakes',
				url: 'https://www.dogandcranberrylakes.ca/',
			},
		},
		{
			text: 'Our committees, who were somewhat tied to paper flyers and surveys, are realizing the benefits of online announcements, surveys, and forms.',
			source: {
				name: 'Gina',
				hoa: 'Coldstream Crossing',
				url: 'https://www.coldstreamcrossing.org/',
			},
		},
	],
};

export default feature;
