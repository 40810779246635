// Imports
import ReactDOM from 'react-dom';
import App from './app';
import setUp from './shareables/foundation/front-end/utils/set-up';


// Set up
setUp();


// Disable Plausible if not in production
if (process.env.REACT_APP__ENVIRONMENT !== 'production') {
	localStorage.setItem('plausible_ignore', 'true');
}


// Mount and render
const container = document.getElementById('root');

if (!container) {
	throw new Error('Root container should exist');
}

if (container.hasChildNodes()) {
	ReactDOM.hydrate(<App />, container);
} else {
	ReactDOM.render(<App />, container);
}
