// Imports
import React from 'react';
import { Link } from 'react-router-dom';
import ClauseHeading from '../clause-heading';
import PolicyHeading from '../policy-heading';
import clauses from './clauses';


// Function component
const PrivacyPolicy: React.FC = () => (
	<div>
		<PolicyHeading>Privacy policy</PolicyHeading>
		
		<p>
			The following privacy policy is used to inform users on how their personal information is used and, more
			importantly, kept safe. This policy is not a part of, nor shall it override any statements contained within the{' '}
			<Link to='../terms-of-service'>Terms of Service</Link>; rather, it is used as a tool to clarify what information
			we collect and how it is used.
		</p>
		
		{clauses.map((clause) => (
			<React.Fragment key={clause.label}>
				<ClauseHeading>{clause.label}</ClauseHeading>
				
				{clause.content}
			</React.Fragment>
		))}
	</div>
);

export default PrivacyPolicy;
