// Imports
import React from 'react';
import styled from 'styled-components/macro';
import Features from './features';
import GettingStarted from './getting-started';
import Headline from './headline';
import Pitch from '../../resources/pitch';
import Ratings from './ratings';
import Screenshots from './screenshots';
import Spotlight from '../../resources/spotlight';
import Testimonials from './testimonials';
import Content from '../../resources/content';


// Styled components
const Wrapper = styled(Content)`
	overflow: hidden;
`;

const Container = styled.div`
	padding-top: 5.32rem;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 50rem;
		
		background-color: hsl(206, 60%, 94%);
	}
	
	@media (max-width: 48rem) {
		padding-top: 2.698rem;
	}
`;

const InformedAndEngaged = styled.span`
	white-space: nowrap;
`;


// Function component
const Home: React.FC = () => (
	<Wrapper>
		<Container>
			<Headline />
			
			<Screenshots />
			
			<Spotlight />
			
			<Pitch textMaxWidth='min(45.75rem, 61vw)'>
				Save time & energy while keeping residents <InformedAndEngaged>informed & engaged</InformedAndEngaged>
			</Pitch>
			
			<Features />
			
			<GettingStarted />
			
			<Testimonials />
			
			<Ratings />
		</Container>
	</Wrapper>
);

export default Home;
