// Imports
import React from 'react';
import ClauseHeading from '../clause-heading';
import PolicyHeading from '../policy-heading';
import clauses from './clauses';


// Function component
const TermsOfService: React.FC = () => (
	<div>
		<PolicyHeading>Terms of service</PolicyHeading>
		
		<p>
			The following Terms of Service Agreement (“<b>Agreement</b>”) is an agreement between HOA Express, Inc. (“
			<b>HOA Express</b>”, “<b>we</b>”, “<b>us</b>”, or “<b>our</b>”) and you (“<b>your</b>”, or “<b>user</b>”) that
			governs your use of websites, products, and services provided by or offered through HOA Express (“<b>Services</b>
			”). This Agreement is designed to be as clear as possible, but feel free to get in touch with our{' '}
			<a target='_blank' rel='noopener noreferrer' href='https://help.hoa-express.com/'>
				customer success team
			</a>{' '}
			should you have any questions or suggestions.
		</p>
		
		{clauses.map((clause, i) => (
			<React.Fragment key={clause.label}>
				<ClauseHeading>
					{i + 1}. {clause.label}
				</ClauseHeading>
				
				{clause.content}
			</React.Fragment>
		))}
	</div>
);

export default TermsOfService;
