// Imports
import React from 'react';


// Types
interface Policy {
	label: string;
	content: string | JSX.Element;
}


// Clauses
const clauses: Policy[] = [
	{
		label: 'Information we collect without an account',
		content: (
			<React.Fragment>
				<p>
					Certain information may be collected during your use of our services even if you do not have an account,
					including, but not limited to:
				</p>
				
				<ul>
					<li>IP address</li>
					<li>Operating system</li>
					<li>Browser information</li>
					<li>Visit analytics</li>
				</ul>
				
				<p>
					This information is used solely for development purposes to improve your experience with our services and is
					nearly always used in aggregate.
				</p>
			</React.Fragment>
		),
	},
	{
		label: 'Information we collect if you register for an account',
		content: (
			<React.Fragment>
				<p>
					Personal information is collected during the process of registering for an account, either on our main website
					or on a community website. This information can include, but is not limited to:
				</p>
				
				<ul>
					<li>Full name</li>
					<li>Contact information</li>
					<li>Residency information</li>
					<li>Gender</li>
					<li>Social media information</li>
				</ul>
			</React.Fragment>
		),
	},
	{
		label: 'Cookies',
		content: (
			<p>
				Cookies are used on our websites to improve your visits. These cookies are used to keep you signed in, interact
				with our product and services, and help us develop features to better meet your needs.
			</p>
		),
	},
	{
		label: 'Third parties',
		content: (
			<p>
				We do our absolute best to never share your information with third parties. There are a select few service
				providers that will receive limited information in order for them to help us provide the services we offer. Some
				examples include our payment processing partners, email delivery providers, and our system infrastructure
				providers. We are highly selective of and work closely with these companies to ensure your data remains safe.
			</p>
		),
	},
];

export default clauses;
