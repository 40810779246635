// Imports
import styled from 'styled-components/macro';
import subscriptionSizes from '../../../../shareables/utils/billing/subscription-sizes';
import featureGroupings from './feature-groupings';
import ComparisonTable from './table';


// Styled components
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.875rem;
	
	width: 100%;
	max-width: 43.75rem;
	padding-top: 3.75rem;
`;


// Define the accepted props
interface Props {
	size: (typeof subscriptionSizes)[number];
}


// Function component
const Comparison: React.FC<Props> = ({ size }) => (
	<Wrapper>
		{featureGroupings.map((featureGroup, i) => (
			<ComparisonTable key={i} size={size} {...featureGroup} />
		))}
	</Wrapper>
);

export default Comparison;
