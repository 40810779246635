/** The available website sizes a customer can choose for a standard subscription */
const subscriptionSizes = [25, 50, 75, 125, 200, 300, 500, 1000, 2500, 5000] as const;
export default subscriptionSizes;


/** Determines whether a particular size is a valid subscription size */
export function isValidSubscriptionSize(size: number): size is (typeof subscriptionSizes)[number] {
	// @ts-expect-error
	return subscriptionSizes.includes(size);
}
