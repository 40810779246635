// Imports
import styled from 'styled-components/macro';
import AppleValleyPropertyOwnersAssociation from './logos/apple-valley-property-owners-association';
import LoudounCrossing from './logos/loudoun-crossing';
import Brierfield from './logos/brierfield';
import SoleraDiamondValley from './logos/solera-diamond-valley';
import TheHaven from './logos/the-haven';


// Styled components
const Wrapper = styled.figure`
	all: unset;
	display: grid;
	gap: 1.5rem;
	width: 100%;
	padding-top: 4rem;
	text-align: center;
`;

export const TrustedBy = styled.figcaption`
	color: hsl(206, 69%, 35%);
	font-size: 1.25rem;
`;

export const Logos = styled.ul`
	list-style: none;
	display: flex;
	width: 100%;
	column-gap: 3.125rem;
	row-gap: 1.25rem;
	flex-wrap: wrap;
	justify-content: center;
	
	@media (max-width: 48rem) {
		column-gap: 2.25rem;
	}
`;


// Define the accepted props
interface Props {
	className?: string;
}


// Function component
const Spotlight: React.FC<Props> = ({ className }) => (
	<Wrapper className={className}>
		<TrustedBy>Trusted by thousands of communities in 20+ countries across the globe</TrustedBy>
		
		<Logos>
			<SoleraDiamondValley />
			<TheHaven />
			<AppleValleyPropertyOwnersAssociation />
			<LoudounCrossing />
			<Brierfield />
		</Logos>
	</Wrapper>
);

export default Spotlight;
