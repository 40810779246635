// Imports
import styled from 'styled-components/macro';
import Spotlight, { Logos, TrustedBy } from '../../resources/spotlight';


// Styled components
const FeatureSpotlight = styled(Spotlight)`
	background-color: hsl(206, 83%, 27%);
	box-shadow: 0 0 0 100vw hsl(206, 83%, 27%);
	clip-path: inset(0 -100vw);
	
	padding: 1.88rem 0;
	
	${TrustedBy} {
		color: hsl(206, 100%, 90%);
	}
	
	${Logos} {
		svg {
			fill: hsl(0, 0%, 100%);
		}
	}
`;

export default FeatureSpotlight;
