// Imports
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import LoadingRow from '../../../../shareables/foundation/front-end/components/tables/loading-row';
import { useAppDispatch } from '../../../../shareables/foundation/front-end/redux/hooks';
import apiActions from '../../../../shareables/integrations/api/redux/actions';
import { GetSMSCreditsResponse } from '../../../../shareables/types/api/main/countries/sms-credits/get';
import Subheading from '../subheading';
import PricingDetailsTable from '../table';
import TextMessagesRow from './row';


// Styled components
const Container = styled.div`
	display: flex;
	flex-direction: column;
	
	padding-top: 0.875rem;
	
	gap: 1.875rem;
`;


// Details for countries listed under the common category
const commonCountries: Partial<Record<string, { index: number; customName?: string }>> = {
	US: {
		index: 0,
		customName: 'United States',
	},
	CA: {
		index: 1,
	},
	MX: {
		index: 2,
	},
};


// Define the accepted props
export interface CategorizedSMSCredits {
	common: GetSMSCreditsResponse;
	other: GetSMSCreditsResponse;
}

interface Props {
	storybookSMSCredits?: CategorizedSMSCredits;
}


// Function component
const TextMessages: React.FC<Props> = ({ storybookSMSCredits }) => {
	// Use state
	const [smsCredits, setSMSCredits] = useState<CategorizedSMSCredits | null>(storybookSMSCredits ?? null);
	
	
	// Use Redux functionality
	const dispatch = useAppDispatch();
	
	
	// Load SMS credits on mount
	useEffect(() => {
		void dispatch(
			apiActions.call<undefined, undefined, GetSMSCreditsResponse>({
				action: 'GET',
				uri: '/countries/sms-credits',
				completion: (json) => {
					// Build common and other countries arrays from response
					const common: GetSMSCreditsResponse = [];
					const other: GetSMSCreditsResponse = [];
					
					for (const country of json.response) {
						const commonCountryDetails = commonCountries[country.twoLetterCode];
						
						if (commonCountryDetails) {
							common[commonCountryDetails.index] = { ...country };
							
							if (commonCountryDetails.customName) {
								common[commonCountryDetails.index].name = commonCountryDetails.customName;
							}
						} else {
							other.push(country);
						}
					}
					
					
					// Set state
					setSMSCredits({ common, other });
				},
			})
		);
	}, [dispatch]);
	
	
	// Return JSX
	return (
		<div>
			<Subheading>Text messages</Subheading>
			
			<p>
				Each community is allotted a certain number of monthly credits based on its size, with the ability to purchase
				more as-needed for 2¢ each. Credit costs are updated daily.{' '}
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://help.hoa-express.com/en/articles/2343205-text-message-credits'
				>
					Learn more.
				</a>
			</p>
			
			<Container>
				<PricingDetailsTable>
					<thead>
						<tr>
							<th>Common countries</th>
							<th>Credits</th>
						</tr>
					</thead>
					
					<tbody>
						{smsCredits?.common.map(({ name, credits }) => (
							<TextMessagesRow key={name} country={name} credits={credits} />
						))}
						
						{!smsCredits && <LoadingRow />}
					</tbody>
				</PricingDetailsTable>
				
				<PricingDetailsTable>
					<thead>
						<tr>
							<th>Other countries</th>
							<th>Credits</th>
						</tr>
					</thead>
					
					<tbody>
						{smsCredits?.other.map(({ name, credits }) => (
							<TextMessagesRow key={name} country={name} credits={credits} />
						))}
						
						{!smsCredits && <LoadingRow />}
					</tbody>
				</PricingDetailsTable>
			</Container>
		</div>
	);
};

export default TextMessages;
