// Imports
import React, { useState } from 'react';
import subscriptionSizes from '../../../shareables/utils/billing/subscription-sizes';
import Comparison from './comparison';
import Plans from './plans';
import Toggle from './toggle';
import QuestionsAndAnswers from './questions-and-answers';
import SimplePageTemplate from '../../resources/simple-page-template';


// Types
export type View = 'MONTHLY' | 'YEARLY';


// Define the accepted props
interface Props {
	storybookView?: View;
}


// Function component
const Pricing: React.FC<Props> = ({ storybookView }) => {
	// Use state
	const [view, setView] = useState<View>(storybookView ?? 'YEARLY');
	const [sliderIndex, setSliderIndex] = useState(0);
	
	
	// Return JSX
	return (
		<SimplePageTemplate
			heading='Simple pricing for every community'
			descriptor='Try HOA Express risk-free for 30 days—no credit card needed'
		>
			<Toggle view={view} setView={setView} />
			
			<Plans view={view} sliderIndex={sliderIndex} setSliderIndex={setSliderIndex} />
			
			<Comparison size={subscriptionSizes[sliderIndex]} />
			
			<QuestionsAndAnswers />
		</SimplePageTemplate>
	);
};

export default Pricing;
