// Imports
import { Props } from '../../../resources/quotes/quote';


// Testimonials
const testimonials: Props[] = [
	{
		text: "So far, I've had only positive feedback from the residents who have signed up.",
		source: {
			name: 'Todd',
			hoa: 'Villa Riviera HOA',
			url: 'https://www.villarivierahoa.com/',
		},
	},
	{
		text: "I just can't say enough how easy your interface is. Great job on the website.",
		source: {
			name: 'Ashley',
			hoa: 'Brierfield HOA',
			url: 'https://www.brierfield.org/',
		},
	},
	{
		text: 'The website was a delight to set up and the neighbors are all impressed with how professional it looks.',
		source: {
			name: 'Lina',
			hoa: 'Mountain Laurel Estates',
			url: 'https://www.mountainlaurelestates.info/',
		},
	},
	{
		text: 'I looked at 25 different sites and solutions. HOA Express easily offered the best overall solution.',
		source: {
			name: 'Stuart',
			hoa: 'Beach Walk East COA',
			url: 'https://beachwalkeast.communitysite.com/',
		},
	},
	{
		text: 'Your website building tool is fabulous even for the novice.',
		source: {
			name: 'Emmanuel',
			hoa: 'McCutcheon Crossing',
			url: 'https://www.mccutcheoncrossinghoa.com/',
		},
	},
	{
		text: 'Your tech support is fantastic – responses are almost immediate and spot on!',
		source: {
			name: 'Ed',
			hoa: 'South Pointe CUOA',
			url: 'https://www.spcuoa.org/',
		},
	},
];

export default testimonials;
