// Imports
import React from 'react';
import * as Sentry from '@sentry/browser';
import styled from 'styled-components/macro';
import SimplePageTemplate from '../simple-page-template';


// Styled components
const Container = styled.div`
	text-align: center;
	max-width: 37.5rem;
`;

const Sorry = styled.h2`
	font-size: 2rem;
	font-weight: 600;
	
	padding-top: 3.75rem;
	margin-bottom: 1rem;
`;


// Define the accepted props
interface Props extends React.PropsWithChildren {
	storybookError?: true;
}


// Component class
//  -> There are no Hooks for error boundary functions yet (https://reactjs.org/docs/error-boundaries.html)
class GlobalErrorBoundary extends React.Component<Props, { hasError: boolean }> {
	constructor(props: Props) {
		super(props);
		this.state = { hasError: props.storybookError ?? false };
	}
	
	static getDerivedStateFromError() {
		return { hasError: true };
	}
	
	componentDidCatch(error: Error) {
		// Report to Sentry
		Sentry.captureException(error);
	}
	
	render() {
		if (this.state.hasError) {
			return (
				<SimplePageTemplate heading='Oh snap' descriptor='An unexpected issue occurred'>
					<Container>
						<Sorry>We’re very sorry</Sorry>
						
						<p>The interface crashed for an unknown reason. This issue has been reported to our engineering team.</p>
					</Container>
				</SimplePageTemplate>
			);
		}
		
		return this.props.children;
	}
}


// Export
export default GlobalErrorBoundary;
