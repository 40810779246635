// Imports
import styled from 'styled-components/macro';
import Footer from '../footer';


// Styled component
const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr minmax(0px, 75rem) 1fr;
	width: 100%;
`;

const Container = styled.div`
	grid-area: 1 / 2 / 2 / 3;
	padding: 0 1.25rem;
	padding-bottom: 2.5rem;
	
	@media (max-width: 48rem) {
		padding: 0 0.94rem;
		padding-bottom: 1.25rem;
	}
`;


// Define the accepted props
interface Props extends React.PropsWithChildren {
	className?: string;
}


// Function component
const Content: React.FC<Props> = ({ className, children }) => (
	<Wrapper className={className}>
		<Container>
			{children}
			
			<Footer />
		</Container>
	</Wrapper>
);

export default Content;
