// Imports
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import SimplePageTemplate from '../../resources/simple-page-template';


// Styled components
const Container = styled.div`
	text-align: center;
`;

const Subheading = styled.h2`
	font-size: 2rem;
	font-weight: 600;
	
	padding-top: 3.75rem;
	margin-bottom: 1rem;
`;


// Define the accepted props
interface Props {
	to: string;
}


// Function component
const Redirecting: React.FC<Props> = ({ to }) => {
	useEffect(() => {
		if (navigator.userAgent !== 'ReactSnap' && !(window as typeof window & { STORYBOOK?: true }).STORYBOOK) {
			window.location.href = to;
		}
	}, [to]);
	
	return (
		<SimplePageTemplate heading='Redirecting…' descriptor=''>
			<Container>
				<Subheading>You will be redirected shortly</Subheading>
				
				<p>
					If you are not redirected, click{' '}
					{to.includes('https://') ? (
						<a rel='noopener noreferrer' href={to}>
							here
						</a>
					) : (
						<Link to={to}>here</Link>
					)}
					.
				</p>
			</Container>
		</SimplePageTemplate>
	);
};

export default Redirecting;
