// Imports
import styled from 'styled-components/macro';


// Styled components
const Wrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.9375rem;
	
	line-height: 1.2;
`;

const Price = styled.span`
	display: flex;
	align-items: flex-start;
	
	font-size: 3.75rem;
	font-weight: 600;
	line-height: 1;
`;

const DollarSign = styled.span`
	font-size: 1.875rem;
	font-weight: 600;
	line-height: 1;
`;


// Function component
const FreePlanPricing = () => (
	<Wrapper>
		<Price>
			<DollarSign>$</DollarSign>0
		</Price>
		Free forever
	</Wrapper>
);

export default FreePlanPricing;
