// Imports
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import Feature from '..';


// Banner
const feature: React.ComponentProps<typeof Feature> = {
	banner: {
		label: { icon: faInfoCircle, text: 'Community info' },
		heading: 'Centralize need-to-know information for your residents',
		description:
			'Highlight your board members and committees, publish news articles, share FAQs, showcase community amenities, create interactive maps, and so much more. Your website is the hub for everything residents need to know.',
		screenshot: {
			width: 900,
			height: 568,
			src: '/images/screenshots/features/community-info/banner.webp',
		},
	},
	pitch: { text: 'Every detail residents need, all in one place', textMaxWidth: 'min(29.8rem, 39.67vw)' },
	tiles: {
		leftColumn: [
			{
				heading: 'Highlight board members',
				screenshot: {
					width: 980,
					height: 322,
					src: (layout) => `/images/screenshots/features/community-info/${layout}/board-members.webp`,
				},
			},
			{
				heading: 'Publish news articles',
				screenshot: {
					width: 980,
					height: 382,
					src: (layout) => `/images/screenshots/features/community-info/${layout}/news-articles.webp`,
				},
			},
			{
				heading: 'Answer FAQs',
				screenshot: {
					width: 880,
					height: 344,
					src: (layout) => `/images/screenshots/features/community-info/${layout}/faqs.webp`,
				},
			},
			{
				heading: 'Interactive maps',
				screenshot: {
					width: 980,
					height: 740,
					src: (layout) => `/images/screenshots/features/community-info/${layout}/maps.webp`,
				},
			},
		],
		rightColumn: [
			{
				heading: 'List committees & their purpose',
				screenshot: {
					width: 980,
					height: 456,
					src: (layout) => `/images/screenshots/features/community-info/${layout}/committees.webp`,
				},
			},
			{
				heading: 'Share important documents',
				screenshot: {
					width: 980,
					height: 266,
					src: (layout) => `/images/screenshots/features/community-info/${layout}/documents.webp`,
				},
			},
			{
				heading: 'Community amenities & facilities',
				screenshot: {
					width: 980,
					height: 544,
					src: (layout) => `/images/screenshots/features/community-info/${layout}/facilities.webp`,
				},
			},
			{
				heading: 'Curate useful contact info',
				screenshot: {
					width: 980,
					height: 386,
					src: (layout) => `/images/screenshots/features/community-info/${layout}/useful-contact-info.webp`,
				},
			},
		],
	},
	testimonials: [
		{
			text: 'Dissemination of information to our membership is a breeze… HOA Express does it all!',
			source: {
				name: 'Board',
				hoa: 'Kachess Ridge',
				url: 'https://www.kachessridge.com/',
			},
		},
		{
			text: 'HOA Express has given us the ability to make important documents readily available to everyone. With some documents running to well over 100 pages, we are actually saving money - and trees!',
			source: {
				name: 'Stephen',
				hoa: 'OVMR',
				url: 'https://www.ovmr.org/',
			},
		},
	],
};

export default feature;
