// Imports
import styled from 'styled-components/macro';
import Disclosure from './disclosure';
import questionsAndAnswers from './questions-and-answers';


// Styled components
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.25rem;
	
	width: 100%;
	max-width: 50rem;
	
	padding-top: 3.75rem;
	
	a {
		color: inherit;
		text-decoration: underline;
		
		&:hover {
			text-decoration: none;
		}
	}
`;

const Heading = styled.h2`
	text-align: center;
	font-size: 2.5rem;
	font-weight: 600;
	line-height: 1;
`;

const Container = styled.div`
	width: 100%;
`;


// Define the accepted props
interface Props {
	storybookRevealDisclosures?: boolean;
}


// Function component
const QuestionsAndAnswers: React.FC<Props> = ({ storybookRevealDisclosures }) => (
	<Wrapper>
		<Heading>Questions & answers</Heading>
		
		<Container>
			{questionsAndAnswers.map((questionAndAnswer) => (
				<Disclosure
					key={questionAndAnswer.question}
					{...questionAndAnswer}
					storybookIsOpen={storybookRevealDisclosures}
				/>
			))}
		</Container>
	</Wrapper>
);

export default QuestionsAndAnswers;
