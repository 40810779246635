// Imports
import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import PageMessage from './page-message';


// Define the accepted props
interface Props {
	storybookDisableHiding?: true;
}


// Function component
const PageMessages: React.FC<Props> = ({ storybookDisableHiding }) => {
	// Use Redux functionality
	const pageMessages = useAppSelector((state) => state.common.pageMessages);
	
	
	// Use state
	const [messageHeights, setMessageHeights] = useState<number[]>([]);
	
	
	// Use ref
	const asideRef = useRef<HTMLElement>(null);
	
	
	// Calculate the height of each message
	useEffect(() => {
		if (!asideRef.current) {
			return;
		}
		
		const heights = [];
		
		if (asideRef.current.children.length) {
			for (let i = 0; i < asideRef.current.children.length; i++) {
				const pageMessageElement = asideRef.current.children[i];
				
				if (pageMessages[i].dismissed) {
					heights.push(-25);
				} else {
					heights.push(pageMessageElement.scrollHeight);
				}
			}
		}
		
		setMessageHeights(heights);
	}, [pageMessages]);
	
	
	// Initialize max z-index
	const maxZIndex = 9999 + pageMessages.length;
	
	
	// Return JSX
	return (
		<aside ref={asideRef}>
			{pageMessages.map((pageMessage, i) => {
				// Calculate offset
				let offset = 25;
				
				for (let x = i + 1; x < pageMessages.length && x < messageHeights.length; x++) {
					offset += 25 + messageHeights[x];
				}
				
				
				// Return JSX
				return (
					<PageMessage
						key={i}
						index={i}
						offset={offset}
						storybookDisableHiding={storybookDisableHiding}
						maxZIndex={maxZIndex}
					/>
				);
			})}
		</aside>
	);
};

export default PageMessages;
