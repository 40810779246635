// Imports
import { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components/macro';
import { View } from '..';


// Styled components
export const pillBorderRadius = css`
	border-radius: 624.9375rem;
`;

const Wrapper = styled.button<{ isActive: boolean }>`
	border: 0;
	margin: 0;
	padding: 0;
	height: 2.5rem;
	
	background: transparent;
	position: relative;
	
	cursor: pointer;
	
	transition: color 0.3s ease;
	
	font-size: 1.25rem;
	line-height: 1;
	
	${({ isActive }) =>
		isActive
			? css`
					color: hsl(0, 0%, 100%);
			  `
			: css`
					color: hsl(206, 25%, 20%);
			  `}
	
	&::before {
		content: '';
		background: hsl(96, 54%, 59%);
		display: block;
		height: 100%;
		position: absolute;
		left: 0px;
		top: 0px;
		transition: translate 0.3s ease 0s;
		width: 100%;
		
		${pillBorderRadius}
	}
	
	&:first-child {
		&::before {
			${({ isActive }) =>
				isActive
					? css`
							translate: 0;
					  `
					: css`
							translate: 100%;
					  `}
		}
	}
	
	&:last-child {
		&::before {
			${({ isActive }) =>
				isActive
					? css`
							translate: 0;
					  `
					: css`
							translate: -100%;
					  `}
		}
	}
	
	&:focus-visible {
		z-index: 2;
		
		&::before {
			${({ isActive }) =>
				isActive
					? ''
					: css`
							display: none;
					  `}
		}
	}
`;

const Content = styled.span`
	z-index: 1;
	
	position: relative;
	display: flex;
	padding: 0.5rem 1rem;
	align-items: center;
	gap: 0.3125rem;
`;


// Define the accepted props
interface Props extends PropsWithChildren {
	identifier: View;
	view: View;
	setView: React.Dispatch<React.SetStateAction<View>>;
	className?: string;
}


// Function component
const Button: React.FC<Props> = ({ identifier, view, setView, className, children }) => (
	<Wrapper isActive={identifier === view} onClick={() => setView(identifier)} className={className}>
		<Content>{children}</Content>
	</Wrapper>
);

export default Button;
