// Imports
import { faPartyHorn } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';


// Styled components
const PartyHorn = styled.span`
	color: hsl(96, 46%, 74%);
	
	@media (max-width: 30rem) {
		font-size: 2rem;
	}
`;

const Wrapper = styled.div`
	padding-top: 6.25rem;
	
	display: grid;
	grid-auto-flow: column;
	gap: clamp(1.25rem, 4.16vw, 3.125rem);
	justify-content: center;
	align-items: center;
	font-size: clamp(1.25rem, 4.16vw, 3.125rem);
	line-height: 1.1;
	
	@media (max-width: 48rem) {
		padding-top: 2.8625rem;
	}
	
	@media (max-width: 30rem) {
		width: 100%;
		max-width: 100%;
		overflow: hidden;
		grid-template-columns: auto 1fr auto;
	}
`;

const Text = styled.span<{ maxWidth: string }>`
	color: hsl(206, 69%, 35%);
	font-family: Zilla Slab;
	font-weight: 600;
	text-align: center;
	width: max-content;
	max-width: ${({ maxWidth }) =>
		maxWidth}; // This is required because the text container would take up more space than the text actually requires because of the way text wrapping works
	
	@media (max-width: 30rem) {
		grid-area: 1 / 2 / 2 / 3;
		width: 100%;
		max-width: 100%;
		font-size: max(1.5rem, 7vw);
	}
`;


// Define the accepted props
interface Props extends React.PropsWithChildren {
	/** The max width the text of the pitch should take up */
	textMaxWidth: string;
	
	
	/** For use by styled-components to inject its class onto the component to enable extending the styles easily */
	className?: string;
}


// Function component
const Pitch: React.FC<Props> = ({ children, className, textMaxWidth }) => (
	<Wrapper className={className}>
		<PartyHorn>
			<FontAwesomeIcon icon={faPartyHorn} flip='horizontal' />
		</PartyHorn>
		
		<Text maxWidth={textMaxWidth}>{children}</Text>
		
		<PartyHorn>
			<FontAwesomeIcon icon={faPartyHorn} />
		</PartyHorn>
	</Wrapper>
);

export default Pitch;
