// Imports
import subscriptionSizes from './subscription-sizes';


/** A mapping of website sizes to their respective monthly subscription price */
const subscriptionAmountMapping: {
	deluxePlan: {
		[size in (typeof subscriptionSizes)[number]]: number;
	};
} = {
	deluxePlan: {
		5000: 79,
		2500: 74,
		1000: 69,
		500: 64,
		300: 58,
		200: 52,
		125: 46,
		75: 38,
		50: 30,
		25: 22,
	},
};

export default subscriptionAmountMapping;
