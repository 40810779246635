// Imports
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import theme from '../../../shareables/theme';


// Styled components
export const styling = css`
	font-family: inherit;
	position: relative;
	font-size: 1.125rem;
	line-height: 1;
	text-decoration: none;
	color: hsl(206, 83%, 27%);
	z-index: 10;
	
	&::after {
		content: '';
		position: absolute;
		will-change: transform;
		width: 100%;
		height: 0.125rem;
		background-color: ${theme.colors.info};
		bottom: -0.3rem;
		left: 0;
		opacity: 0;
		transform: scaleX(0.5);
		transition:
			opacity 0.2s ease-in-out,
			transform 0.2s ease-in-out;
	}
	
	&:hover::after,
	&[data-hover]::after {
		opacity: 1;
		transform: scaleX(1);
	}
`;

const NavbarLink = styled(NavLink)`
	${styling}
	
	width: fit-content;
`;

export default NavbarLink;
