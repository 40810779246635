// Imports
import { faCircle, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components/macro';
import { View } from '..';
import Button from '../../../resources/button';


// Styled components
const Wrapper = styled.div<{ containerWidth: string; backgroundColor: string; mostPopular?: boolean }>`
	display: flex;
	width: ${({ containerWidth }) => containerWidth};
	padding: 1.875rem;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.25rem;
	
	border-radius: 1.25rem;
	background-color: ${({ backgroundColor }) => backgroundColor};
	color: hsl(206, 25%, 20%);
	
	position: relative;
	
	> a:last-child {
		align-self: center;
	}
	
	@media (max-width: 48rem) {
		width: 100%;
		
		> a:last-child {
			width: 100%;
			justify-content: center;
		}
	}
	
	@media (max-width: 30rem) {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}
	
	${({ mostPopular }) =>
		mostPopular
			? css`
					padding-top: 3.125rem;
			  `
			: ''}
`;

const MostPopular = styled.div<{ backgroundColor: string; fontColor: string }>`
	position: absolute;
	top: 0;
	left: 0;
	
	width: 100%;
	
	text-align: center;
	border-radius: 1.25rem 1.25rem 0 0;
	padding: 0.5rem 0;
	
	background-color: ${({ backgroundColor }) => backgroundColor};
	color: ${({ fontColor }) => fontColor};
	
	font-size: 0.875rem;
	font-weight: 600;
	line-height: 1;
	text-transform: uppercase;
`;

const HeadingContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.625rem;
	width: 100%;
`;

const Heading = styled.h2<{ color: string }>`
	color: ${({ color }) => color};
	font-size: 2.5rem;
	font-weight: 600;
	line-height: 1;
`;

const Description = styled.p`
	font-size: 1.25rem;
	line-height: 1.2;
`;

const FeaturesContainer = styled.figure`
	display: block;
`;

const FeaturesHeading = styled.figcaption`
	color: hsl(0, 0%, 0%);
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1;
	text-transform: uppercase;
	margin-bottom: 0.625rem;
`;

const Features = styled.ul`
	display: flex;
	gap: 0.625rem;
	list-style: none;
	flex-direction: column;
`;

const Feature = styled.li`
	display: flex;
	align-items: center;
	gap: 0.625rem;
`;

const IconLayers = styled.span`
	position: absolute;
	top: 0;
	left: -0.129rem;
	
	font-size: 1.375rem;
`;

const Checkbox = styled.div`
	position: relative;
	
	width: 1.375rem;
	height: 1.375rem;
	min-width: 1.375rem;
`;


// Define the accepted props
export interface Props {
	name: string;
	description: string;
	backgroundColor: string;
	containerWidth: string;
	accentColor: string;
	pricing: (state: {
		view: View;
		sliderIndex: number;
		setSliderIndex: React.Dispatch<React.SetStateAction<number>>;
	}) => JSX.Element;
	mostPopular?: {
		backgroundColor: string;
		fontColor: string;
	};
	features: {
		label?: string;
		features: string[];
	};
	callToAction: React.ComponentProps<typeof Button> & { text: string };
}

interface CompleteProps extends Props {
	view: View;
	sliderIndex: number;
	setSliderIndex: React.Dispatch<React.SetStateAction<number>>;
}


// Function component
const Plan: React.FC<CompleteProps> = ({
	name,
	description,
	backgroundColor,
	containerWidth,
	accentColor,
	pricing,
	mostPopular,
	features,
	callToAction: { text: callToActionText, ...linkButtonProps },
	...state
}) => (
	<Wrapper containerWidth={containerWidth} backgroundColor={backgroundColor} mostPopular={Boolean(mostPopular)}>
		{mostPopular && <MostPopular {...mostPopular}>Most popular</MostPopular>}
		
		<HeadingContainer>
			<Heading color={accentColor}>{name}</Heading>
			
			<Description>{description}</Description>
		</HeadingContainer>
		
		{pricing(state)}
		
		<FeaturesContainer>
			{features.label && <FeaturesHeading>{features.label}</FeaturesHeading>}
			
			<Features>
				{features.features.map((feature, i) => (
					<Feature key={i}>
						<Checkbox>
							<IconLayers className='fa-layers fa-fw'>
								<FontAwesomeIcon icon={faCircle} color={accentColor} />
								<FontAwesomeIcon icon={faCheck} color='hsl(0, 0%, 100%)' transform='shrink-6.36' />
							</IconLayers>
						</Checkbox>
						{feature}
					</Feature>
				))}
			</Features>
		</FeaturesContainer>
		
		<Button {...linkButtonProps}>{callToActionText}</Button>
	</Wrapper>
);

export default Plan;
