// Imports
import React from 'react';
import styled from 'styled-components/macro';
import { View } from '..';
import Plan from './plan';
import plans from './plans';


// Styled components
const Wrapper = styled.div`
	display: grid;
	align-items: end;
	justify-content: center;
	gap: 1.25rem;
	grid-auto-flow: column;
	
	padding-top: 1.875rem;
	
	@media (max-width: 48rem) {
		grid-auto-flow: row;
		align-items: center;
	}
`;


// Define the accepted props
interface Props {
	view: View;
	sliderIndex: number;
	setSliderIndex: React.Dispatch<React.SetStateAction<number>>;
}


// Function component
const Plans: React.FC<Props> = (props) => (
	<Wrapper>
		{plans.map((plan) => (
			<Plan key={plan.name} {...plan} {...props} />
		))}
	</Wrapper>
);

export default Plans;
