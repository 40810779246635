// Imports
import { State, INITIATE_API_CALL, Actions } from './types';


// Define initial state
const initialState: State = {
	endpointsInitiated: {},
};


// The root reducer
export default function reducer(state = initialState, action: Actions): State {
	switch (action.type) {
		case INITIATE_API_CALL:
			return {
				...state,
				endpointsInitiated: {
					...state.endpointsInitiated,
					[action.endpoint]: action.initiated,
				},
			};
		default:
			return state;
	}
}
