// Imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';
import values from './values';


// Styled components
const Wrapper = styled.div`
	width: 100%;
	max-width: 50rem;
`;

const Heading = styled.h2`
	font-size: 2rem;
	font-weight: 600;
	line-height: 1.2;
	margin-bottom: 1rem;
`;

const backgroundColors = ['hsl(96, 100%, 90%)', 'hsl(206, 100%, 90%)', 'hsl(36, 100%, 90%)'];
const fontColors = ['hsl(96, 58%, 35%)', 'hsl(206, 69%, 35%)', 'hsl(36, 44%, 52%)'];

const List = styled.ul`
	list-style: none;
	
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.25rem;
	
	width: 100%;
`;

const ProductValue = styled.li<{ emphasisColor: string }>`
	display: flex;
	align-items: center;
	gap: 1.25rem;
	
	width: 100%;
	
	.emphasis {
		color: ${({ emphasisColor }) => emphasisColor};
	}
	
	@media (max-width: 30rem) {
		> p {
			display: none;
		}
	}
`;

const Tile = styled.figure<{ fontColor: string; backgroundColor: string }>`
	display: flex;
	min-width: 18.75rem;
	padding: 1.25rem;
	flex-direction: column;
	align-items: center;
	gap: 0.625rem;
	
	border-radius: 0.625rem;
	
	font-size: 3.125rem;
	line-height: 1.2;
	text-align: center;
	
	color: ${({ fontColor }) => fontColor};
	background-color: ${({ backgroundColor }) => backgroundColor};
	
	> figcaption {
		font-size: 1.5rem;
		font-weight: 600;
	}
	
	@media (max-width: 30rem) {
		width: 100%;
	}
`;


// Function component
const ProductValues: React.FC = () => (
	<Wrapper>
		<Heading>Product values</Heading>
		
		<List>
			{values.map((value, i) => (
				<ProductValue key={value.heading} emphasisColor={fontColors[i % fontColors.length]}>
					<Tile
						fontColor={fontColors[i % fontColors.length]}
						backgroundColor={backgroundColors[i % backgroundColors.length]}
					>
						<FontAwesomeIcon icon={value.icon} />
						<figcaption>{value.heading}</figcaption>
					</Tile>
					
					{value.text}
				</ProductValue>
			))}
		</List>
	</Wrapper>
);

export default ProductValues;
