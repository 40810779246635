// Imports
import { useEffect } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { useLocation } from 'react-router-dom';
import commonActions from '../../redux/actions';
import styled from 'styled-components/macro';


// Styled components
const NavigationAnnouncement = styled.span`
	position: absolute;
	top: -9999px;
`;


// Let TypeScript know about third party tools
declare global {
	function Canny(command: 'closeChangelog'): void;
	
	interface Window {
		Canny?: typeof Canny;
	}
}


// Define the accepted props
interface Props {
	title?: string;
}


// Function component
const RouteChangeListener: React.FC<Props> = ({ title }) => {
	// Use React Router functionality
	const location = useLocation();
	
	
	// Use Redux functionality
	const dispatch = useAppDispatch();
	
	
	// Special side effects when route changes
	useEffect(() => {
		// Remember when the route last changed
		dispatch(commonActions.routeChanged());
		
		
		// Update Intercom
		if (window.Intercom) {
			window.Intercom('update');
		}
		
		
		// Close Canny widget
		if (window.Canny) {
			window.Canny('closeChangelog');
		}
	}, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps
	
	
	// Return JSX
	return <NavigationAnnouncement aria-live='polite'>{title ? `Navigated to ${title}` : ''}</NavigationAnnouncement>;
};

export default RouteChangeListener;
