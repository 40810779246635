// Imports
import { BannerListItem } from '../../../resources/banner-with-list';


// Items
const items: BannerListItem[] = [
	{
		prefix: '2009',
		label: 'Founded',
		description: 'A long history of serving our customers.',
	},
	{
		prefix: '100+',
		label: 'Releases every year',
		description: 'Frequent product updates driven by customer feedback.',
	},
	{
		prefix: 'Zero',
		label: 'Outside investors',
		description: 'Committed to our customers first, not investor returns.',
	},
];

export default items;
