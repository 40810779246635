// Imports
import styled from 'styled-components/macro';
import Quotes from '../../resources/quotes';


// Styled components
const FeatureQuotes = styled(Quotes)`
	max-width: 68.75rem;
	margin-top: 3.75rem;
	padding: 0.67rem 1.625rem;
	
	> li {
		flex: 1 1 21.25rem;
		max-width: 43.75rem;
		
		> blockquote {
			max-width: 100%;
		}
	}
`;

export default FeatureQuotes;
