// Imports
import styled from 'styled-components/macro';


// Styled components
const Wrapper = styled.div`
	width: 100%;
	max-width: 100%;
	overflow: auto;
	overflow-y: hidden;
`;

const Container = styled.table`
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	
	th {
		border-bottom: 0.0625rem solid hsl(205, 69%, 87%);
		font-size: 1.5rem;
		font-weight: 600;
		line-height: 1.2;
		
		padding-bottom: 0.625rem;
	}
	
	td {
		font-size: 1.125rem;
		line-height: 1.2;
		padding-top: 0.625rem;
		
		white-space: nowrap;
	}
	
	td:first-child {
		padding-right: 0.5rem;
	}
	
	th:first-child {
		width: 100%;
		text-align: left;
	}
	
	th:last-child,
	td:last-child {
		text-align: center;
	}
`;


// Function component
const PricingDetailsTable: React.FC<React.PropsWithChildren> = ({ children }) => (
	<Wrapper>
		<Container>{children}</Container>
	</Wrapper>
);

export default PricingDetailsTable;
