// Imports
import { Route } from 'react-router-dom';
import Redirecting from './components/pages/redirecting';


// Build redirect routes
const redirectData = [
	{
		from: [
			'/benefits',
			'/community-websites',
			'/condo-websites',
			'/hoa-website',
			'/hoa-websites',
			'/homeowners-association-website',
			'/neighborhood-websites',
			'/services',
			'/testimonials',
			'/why-us',
			'/features/perfected-designs',
		],
		to: '/',
	},
	{
		from: ['/forgot', '/account/forgot', '/account/reset'],
		to: `https://sso.${process.env.REACT_APP__ENVIRONMENT_HOSTNAME}/forgot`,
	},
	{ from: ['/guarantee'], to: '/policies/our-guarantee' },
	{
		from: ['/letter-pricing'],
		to: '/pricing-details/mailed-letters',
	},
	{
		from: ['/online-payments-fees'],
		to: '/pricing-details/online-payments',
	},
	{
		from: ['/privacy-and-terms', '/privacy-policy'],
		to: '/policies/privacy-policy',
	},
	{
		from: ['/resellers-and-management-companies'],
		to: 'https://help.hoa-express.com/en/articles/9294616-about-our-reseller-program',
	},
	{
		from: ['/security'],
		to: 'https://help.hoa-express.com/en/articles/2340910-how-we-keep-data-safe-and-secure',
	},
	{ from: ['/terms-of-service'], to: '/policies/terms-of-service' },
	{
		from: ['/text-message-credits'],
		to: '/pricing-details/text-messages',
	},
	{
		from: ['/account/settings', '/account/websites', '/website/delete', '/website/disable', '/website/reactivate'],
		to: `https://app.${process.env.REACT_APP__ENVIRONMENT_HOSTNAME}/`,
	},
	{
		from: ['/features/all-inclusive'],
		to: '/features/domain-and-email',
	},
	{
		from: ['/features/communication'],
		to: '/features/blast-communications',
	},
	{ from: ['/features/information'], to: '/features/community-info' },
	{
		from: ['/toa/request'],
		to: `https://app.${process.env.REACT_APP__ENVIRONMENT_HOSTNAME}/toa/request`,
	},
	{
		from: ['/about-us'],
		to: '/about',
	},
];


// Function component
const redirectRoutes = (
	<Route>
		{redirectData
			.map(({ from, to }) =>
				from.map((fromPath) => (
					<Route
						key={`${fromPath}-${to}`}
						path={fromPath}
						element={<Redirecting to={to} />}
						handle={{ hideCTA: true }}
					/>
				))
			)
			.flat()}
	</Route>
);

export default redirectRoutes;
