// Imports
import { Identifiers } from './tabs';
import styled, { css } from 'styled-components/macro';
import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// Styled components
const Wrapper = styled.button<{ isActive: boolean }>`
	all: unset;
	font-size: 1.25rem;
	line-height: 1.1;
	transition: color 0.2s ease-in-out;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
	padding-bottom: 0.5rem;
	cursor: pointer;
	white-space: nowrap;
	
	&:hover {
		color: hsl(206, 69%, 43%);
	}
	
	&:focus-visible {
		outline: -webkit-focus-ring-color auto 0.0625rem;
	}
	
	&::after {
		content: '';
		position: absolute;
		will-change: transform;
		width: 100%;
		height: 0.125rem;
		background-color: hsl(206, 69%, 43%);
		bottom: 0;
		left: 0;
		opacity: 0;
		transform: scaleY(0);
		transform-origin: bottom center;
	}
	
	${({ isActive }) =>
		isActive
			? css`
					color: hsl(206, 69%, 43%);
					
					&::after {
						transition: transform 0.2s ease-in-out;
						transform: none;
						opacity: 1;
					}
			  `
			: ''}
`;

const Icon = styled.span`
	font-size: 1rem;
	line-height: 1;
	color: hsl(36, 56%, 69%);
`;


// Define the accepted props
interface Props {
	identifier: Identifiers;
	icon: IconDefinition;
	text: string;
	activeTab: Identifiers;
	setActiveTab: React.Dispatch<React.SetStateAction<Identifiers>>;
}


// Function component
const Tab: React.FC<Props> = ({ identifier, icon, text, activeTab, setActiveTab }) => {
	// Prevent some problematic usages
	if (icon.prefix !== 'fad') {
		throw new Error('A tab’s icon should come from the "duotone" package');
	}
	
	
	// Return JSX
	return (
		<Wrapper type='button' onClick={() => setActiveTab(identifier)} isActive={identifier === activeTab}>
			<Icon>
				<FontAwesomeIcon icon={icon} />
			</Icon>
			
			{text}
		</Wrapper>
	);
};

export default Tab;
