// Imports
import React, { useEffect, useRef } from 'react';


/** A custom Hook that behaves just like `useEffect()`, except that it only runs when the provided dependencies are updated, rather than on mount as well. */
export default function useUpdateOnlyEffect(effect: React.EffectCallback, deps?: unknown[]) {
	// Track if the component is mounted
	const isMounted = useRef(false);
	
	useEffect(() => {
		isMounted.current = true;
		
		return () => {
			isMounted.current = false;
		};
	}, []);
	
	
	// When updates occur, trigger the effect
	const hasRun = useRef(false);
	
	useEffect(() => {
		// Initialize cleanup variable
		let cleanup: null | ReturnType<React.EffectCallback> = null;
		
		
		// Run the effect only if it has already run once
		if (hasRun.current) {
			cleanup = effect();
		} else {
			hasRun.current = true;
		}
		
		
		// Cleanup
		return () => {
			// If the component is unmounted, reset flag indicating the effect has run
			if (!isMounted.current) {
				hasRun.current = false;
			}
			
			
			// Run cleanup if it exists
			cleanup?.();
		};
	}, [...(deps ?? [])]); // eslint-disable-line react-hooks/exhaustive-deps
}
