// Imports
import { Props } from '../../../resources/quotes/quote';


// Testimonials
const testimonials: Props[] = [
	{
		text: 'After implementing HOA Express at our association, I was amazed by its capabilities and the positive impact it had on our community. The platform’s features are not only impressive but also user friendly, making it an invaluable asset for effective communication.',
		source: {
			name: 'Derek',
			hoa: 'Three Seasons Association 2',
			url: 'https://www.threeseasons2.org/',
		},
	},
	{
		text: 'I have used HOA Express for two previous websites with great success, and I hope to do the same again. I like the simplicity of your software, the ease of keeping the website current for a non-techie, and the breadth of features to meet this HOA’s needs!',
		source: {
			name: 'Al',
			hoa: 'The Gables',
			url: 'https://gableshoaweston.communitysite.com/',
		},
	},
];

export default testimonials;
