// Imports
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import LoadingImage from '../../resources/loading-image';


// Styled components
const Wrapper = styled.div`
	width: 100%;
	max-width: 68.75rem;
	
	padding: 6.25rem 0;
	
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 3.125rem;
	
	background-color: hsl(206, 60%, 94%);
	box-shadow: 0 0 0 100vmax hsl(206, 60%, 94%);
	clip-path: inset(-100vmax -100vmax 0 -100vmax);
	
	@media (max-width: 57.5rem) {
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		gap: 3.2rem;
	}
`;

const LeftPortion = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.25rem;
	max-width: 37.5rem;
	
	@media (max-width: 57.5rem) {
		width: 100%;
		max-width: 100%;
	}
`;

const Screenshot = styled(LoadingImage)`
	width: 41%;
	min-width: 41%;
	height: auto;
	
	border-radius: 0.625rem;
	border: 0.125rem solid hsl(206, 25%, 20%);
	box-shadow: inset hsl(206, 25%, 20%) 0 0 0 0.0625rem;
	
	@media (max-width: 57.5rem) {
		width: 100%;
		min-width: 100%;
	}
`;

const Heading = styled.h2`
	> span {
		display: flex;
		align-items: center;
		font-weight: 400;
		gap: 0.625rem;
		font-size: 1.875rem;
		color: hsl(206, 69%, 43%);
		
		> svg {
			color: hsl(96, 54%, 59%);
		}
		
		@media (max-width: 57.5rem) {
			font-size: 1.25rem;
		}
	}
	
	> strong {
		display: block;
		color: hsl(206, 69%, 35%);
		font-family: Zilla Slab;
		font-size: 3.75rem;
		font-weight: 600;
		line-height: 1;
		
		@media (max-width: 57.5rem) {
			font-size: 2.5rem;
		}
	}
`;

const Description = styled.p`
	font-size: 1.3125rem;
	line-height: 1.5;
`;


// Define the accepted props
interface Props {
	label: {
		icon: IconDefinition;
		text: string;
	};
	heading: string;
	description: string;
	screenshot: {
		src: string;
		width: number;
		height: number;
	};
}


// Function component
const Banner: React.FC<Props> = ({ label, heading, description, screenshot }) => (
	<Wrapper>
		<LeftPortion>
			<Heading>
				<span>
					<FontAwesomeIcon icon={label.icon} />
					{label.text}
				</span>
				
				<strong>{heading}</strong>
			</Heading>
			
			<Description>{description}</Description>
		</LeftPortion>
		
		<Screenshot
			width={screenshot.width}
			height={screenshot.height}
			src={screenshot.src}
			alt={`“${label.text}” screenshot`}
		/>
	</Wrapper>
);

export default Banner;
