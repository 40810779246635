// Imports
import store from './store';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { State } from '../../../../redux/types';


// Typed dispatch hook
export const useAppDispatch: () => typeof store.dispatch = useDispatch;


// Typed selector hook
export const useAppSelector: TypedUseSelectorHook<State> = useSelector;
