// Imports
import styled, { css } from 'styled-components/macro';
import House from './house';


// Styled components
interface WrapperProps {
	mirrored?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
	display: grid;
	width: 13.75rem;
	height: 22.7rem;
	grid-template-columns: repeat(5, 1fr);
	grid-template-rows: repeat(7, 1fr);
	
	position: absolute;
	top: 0;
	right: 100%;
	
	${({ mirrored }) =>
		mirrored
			? css`
					transform: scaleX(-1);
					right: unset;
					left: 100%;
			  `
			: ''}
	
	@media (max-width: 60rem) {
		display: none;
	}
`;


// Function component
const Houses: React.FC<WrapperProps> = (props) => (
	<Wrapper {...props}>
		<House gridArea='1 / 1 / 2 / 2' scale={2.3} rotation={4.2} />
		<House gridArea='2 / 4 / 3 / 5' scale={2.6} rotation={-21.5} />
		<House gridArea='4 / 2 / 5 / 3' scale={2.7} rotation={-11.8} />
		<House gridArea='5 / 5 / 6 / 6' scale={3.6} rotation={2.7} />
		<House gridArea='6 / 1 / 7 / 2' scale={3.4} rotation={-7.7} />
		<House gridArea='7 / 4 / 8 / 5' scale={6} rotation={18.4} />
	</Wrapper>
);

export default Houses;
