// Imports
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faBolt, faCircleCheck, faFaceSmileHearts } from '@fortawesome/pro-regular-svg-icons';


// Values
export interface Value {
	heading: string;
	text: JSX.Element;
	icon: IconDefinition;
}

const values: Value[] = [
	{
		heading: 'Ease-of-use',
		text: (
			<p>
				Customers consistently cite <span className='emphasis'>how easy it is to use our product</span> as one of their
				favorite benefits. We achieve this by meticulously crafting our software to be simple and intuitive.
			</p>
		),
		icon: faFaceSmileHearts,
	},
	{
		heading: 'Reliability',
		text: (
			<p>
				We place a strong emphasis on <span className='emphasis'>ensuring our product is reliable</span>, by designing
				with redundancy, extensively testing each feature, and prioritizing bug fixes.
			</p>
		),
		icon: faCircleCheck,
	},
	{
		heading: 'Performance',
		text: (
			<p>
				Built on the same technologies that power the world’s largest websites,{' '}
				<span className='emphasis'>our product is designed to be fast</span>—even with thousands of residents or older
				devices.
			</p>
		),
		icon: faBolt,
	},
];

export default values;
