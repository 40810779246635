// Imports
import styled from 'styled-components/macro';
import Pitch from '../../resources/pitch';


// Styled components
const FeaturePitch = styled(Pitch)`
	padding-top: 3.75rem;
`;

export default FeaturePitch;
