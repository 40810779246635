// Imports
import styled from 'styled-components/macro';
import LoadingImage from '../../../resources/loading-image';


// Styled components
const Wrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;

const Container = styled.div`
	position: relative;
	padding: 0 0.875rem;
	padding-top: 3rem;
	padding-bottom: 0.94rem;
	width: 100%;
	max-width: 68.75rem;
	
	&& {
		box-sizing: content-box;
	}
	
	@media (max-width: 48rem) {
		max-width: 16.5625rem;
	}
`;

const AdminPortal = styled(LoadingImage)`
	width: 100%;
	height: auto;
	
	border-radius: 0.625rem;
	border: 2px solid hsl(206, 25%, 20%);
	box-shadow: inset hsl(206, 25%, 20%) 0 0 0 0.0625rem;
	
	@media (max-width: 48rem) {
		display: none;
	}
`;

const CommunitySite = styled(LoadingImage)`
	width: 17.82%;
	max-width: 12.25rem;
	height: auto;
	
	border-radius: 1.25rem;
	border: 2px solid hsl(206, 25%, 20%);
	box-shadow: inset hsl(206, 25%, 20%) 0 0 0 0.0625rem;
	
	position: absolute;
	bottom: 0;
	right: 0;
	
	@media (max-width: 48rem) {
		width: 100%;
		max-width: 100%;
		position: unset;
	}
`;


// Function component
const Screenshots: React.FC = () => (
	<Wrapper>
		<Container>
			<AdminPortal
				src='/images/screenshots/admin-portal-desktop.webp'
				alt='A section in our Admin Portal used to administer pages on your community website'
				width={2350}
				height={1536}
			/>
			<CommunitySite
				src='/images/screenshots/community-site-mobile.webp'
				alt='A community’s homepage created using our website builder'
				width={784}
				height={1432}
			/>
		</Container>
	</Wrapper>
);

export default Screenshots;
