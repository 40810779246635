// Imports
import { Outlet, Route } from 'react-router-dom';
import BlastCommunications from './blast-communications';
import StoreResidents from './store-residents';
import DomainAndEmail from './domain-and-email';
import Content from '../../resources/content';
import CustomForms from './custom-forms';
import CommunityInfo from './community-info';
import Surveys from './surveys';
import OnlinePayments from './online-payments';
import Security from './security';
import Calendars from './calendars';


// Function component
const sectionRoutes = (
	<Route
		path='features'
		element={
			<Content>
				<Outlet />
			</Content>
		}
	>
		<Route path='blast-communications' element={<BlastCommunications />} handle={{ title: 'Blast emails & texts' }} />
		<Route path='store-residents' element={<StoreResidents />} />
		<Route path='domain-and-email' element={<DomainAndEmail />} handle={{ title: 'Domain & email' }} />
		<Route path='custom-forms' element={<CustomForms />} />
		<Route path='community-info' element={<CommunityInfo />} />
		<Route path='surveys' element={<Surveys />} />
		<Route path='online-payments' element={<OnlinePayments />} />
		<Route path='security' element={<Security />} />
		<Route path='calendars' element={<Calendars />} />
	</Route>
);

export default sectionRoutes;
