// Imports
import { useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import ScrollBehaviorContext, { ContextDetails } from '../../../../contexts/scroll-behavior';
import useUpdateOnlyEffect from '../../utils/use-update-only-effect';


// Function component
const ScrollBehaviorHandler: React.FC<React.PropsWithChildren> = ({ children }) => {
	// Use React Router functionality
	const location = useLocation();
	
	
	// Use ref
	const scrollOnRouteChangeHandler = useRef<() => void>(() => window.scrollTo(0, 0));
	
	
	// Function that handles overriding the scroll on route change behavior
	const overrideScrollBehavior: NonNullable<ContextDetails>['overrideScrollBehavior'] = useCallback((handler) => {
		scrollOnRouteChangeHandler.current = handler;
		
		return () => {
			scrollOnRouteChangeHandler.current = () => window.scrollTo(0, 0);
		};
	}, []);
	
	
	// Special side effects when route changes
	useUpdateOnlyEffect(() => {
		// Call scroll on route change handler
		scrollOnRouteChangeHandler.current();
	}, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps
	
	
	// Return JSX
	return <ScrollBehaviorContext.Provider value={{ overrideScrollBehavior }}>{children}</ScrollBehaviorContext.Provider>;
};

export default ScrollBehaviorHandler;
