// Imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedinIn, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import styled from 'styled-components/macro';
import company from '../../../shareables/company';
import Logo from './logo';


// Styled components
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.9375rem;
	color: hsl(206, 25%, 20%);
	
	margin: 0 auto;
`;

const Socials = styled.div`
	display: flex;
	align-items: center;
	gap: 0.9375rem;
`;

const SocialIcon = styled.a`
	color: inherit;
	text-align: center;
	font-size: 1.5rem;
	line-height: 1;
`;

const SparksuiteCompany = styled.span`
	font-size: 0.875rem;
	line-height: 1;
`;

const Sparksuite = styled.a`
	color: inherit;
	text-decoration-line: underline;
	
	&:hover {
		text-decoration: none;
	}
`;


// Function component
const BrandLinks = () => (
	<Wrapper>
		<Logo />
		
		<Socials>
			<SocialIcon
				href={`https://www.facebook.com/${company.socialSlugs.facebook}`}
				target='_blank'
				rel='noopener noreferrer'
				aria-label={`${company.name} on Facebook`}
			>
				<FontAwesomeIcon icon={faFacebook} />
			</SocialIcon>
			
			<SocialIcon
				href={`https://www.linkedin.com/company/${company.socialSlugs.linkedIn}`}
				target='_blank'
				rel='noopener noreferrer'
				aria-label={`${company.name} on LinkedIn`}
			>
				<FontAwesomeIcon icon={faLinkedinIn} />
			</SocialIcon>
			
			<SocialIcon
				href={`https://twitter.com/${company.socialSlugs.x}`}
				target='_blank'
				rel='noopener noreferrer'
				aria-label={`${company.name} on X`}
			>
				<FontAwesomeIcon icon={faXTwitter} />
			</SocialIcon>
		</Socials>
		
		<SparksuiteCompany>
			A{' '}
			<Sparksuite target='_blank' rel='noopener' href='https://www.sparksuite.com/'>
				Sparksuite
			</Sparksuite>{' '}
			company
		</SparksuiteCompany>
	</Wrapper>
);

export default BrandLinks;
