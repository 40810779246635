// Imports
import company from '../../../shareables/company';
import { Props } from './list';


// Links
const productionDomain = company.productionDomain ?? '';

const lists: Props[] = [
	{
		label: 'Features',
		links: [
			{
				text: 'Blast emails & texts',
				to: '/features/blast-communications',
			},
			{
				text: 'Store residents',
				to: '/features/store-residents',
			},
			{
				text: 'Online payments',
				to: '/features/online-payments',
			},
			{
				text: 'Custom forms',
				to: '/features/custom-forms',
			},
			{
				text: 'Calendars',
				to: '/features/calendars',
			},
			{
				text: 'Community info',
				to: '/features/community-info',
			},
		],
	},
	{
		label: 'Resources',
		links: [
			{
				text: 'Demo',
				href: 'https://demo.communitysite.com/',
			},
			{
				text: 'Blog',
				href: `https://blog.${productionDomain}/`,
			},
			{
				text: 'Changelog',
				href: `https://feedback.${productionDomain}/changelog`,
			},
			{
				text: 'Status',
				href: `https://status.${productionDomain}/`,
			},
		],
	},
	{
		label: 'Support',
		links: [
			{
				text: 'Help center',
				href: `https://help.${productionDomain}/`,
			},
			{
				text: 'Contact us',
				type: 'button',
				onClick: () => {
					window.Intercom?.('showNewMessage');
				},
			},
			{
				text: 'Ideas & feedback',
				href: `https://feedback.${productionDomain}/`,
			},
		],
	},
	{
		label: 'Company',
		links: [
			{
				text: 'About',
				to: '/about',
			},
			{
				text: 'Careers',
				to: '/careers',
			},
			{
				text: 'Privacy policy',
				to: '/policies/privacy-policy',
			},
			{
				text: 'Terms of service',
				to: '/policies/terms-of-service',
			},
		],
	},
];

export default lists;
