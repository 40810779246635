// Imports
import Logo from './logo';


// Function component
const LoudounCrossing: React.FC = () => (
	<Logo width={11.4} aria-label='Loudoun Crossing'>
		<svg
			clipRule='evenodd'
			fillRule='evenodd'
			strokeLinejoin='round'
			strokeMiterlimit='2'
			viewBox='0 0 282 62'
			xmlns='http://www.w3.org/2000/svg'
			aria-hidden
		>
			<g fillRule='nonzero'>
				<path
					d='m.596-.195-.005.195h-.563v-.022h.02c.028 0 .048-.004.059-.011s.018-.019.021-.034.004-.05.004-.105v-.325c0-.053-.001-.087-.004-.102s-.01-.026-.02-.032-.032-.009-.065-.009h-.015v-.022h.318v.022h-.029c-.033 0-.054.003-.064.008-.01.006-.017.016-.02.03-.004.015-.005.05-.005.105v.347c0 .047.001.077.003.089.003.012.01.022.022.029.011.006.036.01.074.01h.05c.041 0 .071-.004.091-.011s.038-.023.056-.047.034-.062.049-.115z'
					transform='matrix(48.22776 0 0 46.58688 -1.321351638 32.02785408)'
				/>
				<path
					d='m.051-.335c0-.103.034-.185.103-.247.068-.061.153-.092.256-.092s.189.03.258.091c.069.062.104.145.104.251 0 .103-.033.186-.099.249-.067.063-.154.095-.262.095-.105 0-.191-.032-.259-.095-.067-.063-.101-.148-.101-.252zm.112.003c0 .106.021.186.064.24.042.053.106.08.191.08.075 0 .133-.027.177-.08.043-.054.065-.133.065-.239 0-.103-.022-.182-.064-.237-.043-.054-.105-.082-.186-.082-.08 0-.141.027-.183.08-.043.054-.064.133-.064.238z'
					transform='matrix(48.22776 0 0 46.58688 31.712654472 32.02785408)'
				/>
				<path
					d='m.528-.662h.222v.022c-.03.002-.052.009-.066.02-.015.011-.024.024-.027.038s-.005.041-.005.082v.22c0 .056-.002.098-.006.125-.005.028-.017.054-.038.081-.021.026-.051.047-.089.063-.038.015-.082.023-.132.023-.059 0-.109-.01-.149-.03-.041-.02-.07-.046-.088-.077-.017-.032-.026-.075-.026-.128l.001-.068v-.206c0-.053-.002-.087-.005-.102s-.01-.026-.02-.032-.031-.009-.065-.009h-.015v-.022h.302v.022h-.013c-.032 0-.054.003-.064.008-.01.006-.017.016-.02.03-.004.015-.005.05-.005.105v.208c0 .073.003.124.01.154.006.031.025.057.056.08.031.022.072.034.124.034.04 0 .077-.008.11-.023s.057-.034.074-.057c.016-.022.025-.046.029-.07s.005-.061.005-.113v-.225c0-.038-.002-.064-.006-.079s-.013-.027-.027-.036c-.014-.008-.036-.014-.067-.016z'
					transform='matrix(48.22776 0 0 46.58688 76.215020961 32.02785408)'
				/>
				<path
					d='m.023-.657.109-.005c.091-.005.152-.007.184-.007.098 0 .175.015.23.045s.096.071.123.122.04.11.04.177c0 .049-.007.093-.022.133-.015.041-.035.074-.06.1-.026.027-.055.047-.089.061s-.066.022-.096.026c-.03.003-.073.005-.128.005h-.293v-.022h.027c.028 0 .048-.004.059-.011s.018-.019.021-.034.004-.05.004-.105v-.32c0-.052-.001-.087-.004-.102s-.01-.026-.02-.032c-.01-.005-.032-.008-.065-.008h-.02zm.205.014v.442c0 .066.001.108.003.127.003.019.01.032.022.04.012.007.038.011.078.011.057 0 .105-.008.142-.024.037-.015.068-.047.093-.093s.037-.105.037-.177c0-.056-.007-.106-.022-.15-.014-.044-.035-.08-.062-.107-.027-.028-.057-.047-.09-.057s-.075-.015-.126-.015c-.025 0-.05.001-.075.003z'
					transform='matrix(48.22776 0 0 46.58688 118.22160087 32.02785408)'
				/>
				<path
					d='m.051-.335c0-.103.034-.185.103-.247.068-.061.153-.092.256-.092s.189.03.258.091c.069.062.104.145.104.251 0 .103-.033.186-.099.249-.067.063-.154.095-.262.095-.105 0-.191-.032-.259-.095-.067-.063-.101-.148-.101-.252zm.112.003c0 .106.021.186.064.24.042.053.106.08.191.08.075 0 .133-.027.177-.08.043-.054.065-.133.065-.239 0-.103-.022-.182-.064-.237-.043-.054-.105-.082-.186-.082-.08 0-.141.027-.183.08-.043.054-.064.133-.064.238z'
					transform='matrix(48.22776 0 0 46.58688 159.70953141 32.02785408)'
				/>
				<path
					d='m.528-.662h.222v.022c-.03.002-.052.009-.066.02-.015.011-.024.024-.027.038s-.005.041-.005.082v.22c0 .056-.002.098-.006.125-.005.028-.017.054-.038.081-.021.026-.051.047-.089.063-.038.015-.082.023-.132.023-.059 0-.109-.01-.149-.03-.041-.02-.07-.046-.088-.077-.017-.032-.026-.075-.026-.128l.001-.068v-.206c0-.053-.002-.087-.005-.102s-.01-.026-.02-.032-.031-.009-.065-.009h-.015v-.022h.302v.022h-.013c-.032 0-.054.003-.064.008-.01.006-.017.016-.02.03-.004.015-.005.05-.005.105v.208c0 .073.003.124.01.154.006.031.025.057.056.08.031.022.072.034.124.034.04 0 .077-.008.11-.023s.057-.034.074-.057c.016-.022.025-.046.029-.07s.005-.061.005-.113v-.225c0-.038-.002-.064-.006-.079s-.013-.027-.027-.036c-.014-.008-.036-.014-.067-.016z'
					transform='matrix(48.22776 0 0 46.58688 204.21169695 32.02785408)'
				/>
				<path
					d='m.616.015-.469-.62v.459c0 .038.002.063.006.075s.014.023.032.033.044.016.079.016v.022h-.247v-.022c.032-.003.056-.009.072-.018.017-.009.027-.019.03-.03s.005-.036.005-.076v-.351c0-.051-.003-.085-.008-.102-.004-.017-.012-.028-.022-.033s-.033-.008-.069-.008v-.022h.196l.392.518v-.396c0-.029-.003-.05-.009-.062-.006-.011-.019-.021-.037-.027-.019-.007-.041-.011-.067-.011v-.022h.24v.022c-.028.003-.05.008-.066.016-.016.009-.026.019-.031.031-.004.012-.006.032-.006.06v.548z'
					transform='matrix(48.22776 0 0 46.58688 246.21807591 32.02785408)'
				/>
				<path
					d='m.632-.249h.021v.232h-.021c-.006-.026-.017-.049-.032-.069-.058.065-.133.098-.223.098-.096 0-.174-.03-.235-.091-.06-.06-.091-.14-.091-.24 0-.065.014-.127.042-.185.028-.057.067-.1.117-.128.051-.028.106-.042.165-.042.05 0 .095.01.135.03.04.021.07.043.09.068.014-.028.023-.056.025-.086h.022l.006.251h-.021c-.015-.089-.045-.151-.09-.186s-.096-.052-.152-.052c-.07 0-.125.028-.166.084-.04.056-.061.137-.061.242 0 .099.022.175.067.228s.1.079.166.079c.036 0 .072-.008.109-.026.037-.017.067-.046.089-.087.021-.041.034-.081.038-.12z'
					transform='matrix(23.406528 0 0 24.15696 23.4315694736 60.73114936)'
				/>
				<path
					d='m.727-0h-.178c-.032-.022-.08-.08-.145-.174-.021-.03-.043-.059-.065-.086-.023-.028-.038-.044-.047-.05-.008-.006-.021-.009-.039-.009h-.026v.147c0 .052.001.086.003.102.003.016.009.028.02.036.01.008.031.012.061.012h.031v.022h-.317v-.022h.022c.028 0 .048-.004.059-.011s.018-.019.02-.034c.003-.015.005-.05.005-.105v-.325c0-.053-.002-.087-.005-.102-.002-.015-.009-.026-.019-.032s-.032-.009-.065-.009h-.012v-.022l.095-.005c.063-.003.113-.004.15-.004.064 0 .115.005.154.017.04.012.072.031.096.058.025.027.037.058.037.096 0 .043-.016.08-.048.11-.032.031-.079.05-.14.057.027.014.072.062.133.145.05.067.09.112.118.132.029.021.063.032.102.034zm-.5-.342c.024 0 .049-.001.075-.003.026-.001.049-.005.068-.01.019-.006.035-.015.047-.026s.021-.026.028-.044c.007-.019.01-.041.01-.068 0-.046-.012-.083-.035-.111s-.074-.042-.152-.042c-.012 0-.026 0-.041.001z'
					transform='matrix(23.406528 0 0 24.15696 56.6395810736 60.73114936)'
				/>
				<path
					d='m.051-.335c0-.103.034-.185.103-.247.068-.061.153-.092.256-.092s.189.03.258.091c.069.062.104.145.104.251 0 .103-.033.186-.099.249-.067.063-.154.095-.262.095-.105 0-.191-.032-.259-.095-.067-.063-.101-.148-.101-.252zm.112.003c0 .106.021.186.064.24.042.053.106.08.191.08.075 0 .133-.027.177-.08.043-.054.065-.133.065-.239 0-.103-.022-.182-.064-.237-.043-.054-.105-.082-.186-.082-.08 0-.141.027-.183.08-.043.054-.064.133-.064.238z'
					transform='matrix(23.406528 0 0 24.15696 88.144962816 60.73114936)'
				/>
				<path
					d='m.058-.007v-.213h.024c.028.139.093.208.197.208.046 0 .082-.013.108-.04.026-.026.038-.056.038-.089s-.011-.061-.033-.083c-.023-.022-.064-.046-.123-.071-.054-.023-.094-.043-.121-.06s-.048-.04-.063-.067-.022-.056-.022-.087c0-.047.016-.087.049-.118.032-.032.074-.047.124-.047.028 0 .054.004.079.013s.05.028.076.057c.015-.018.024-.037.026-.058h.021v.173h-.021c-.013-.05-.035-.09-.066-.119-.032-.029-.068-.043-.109-.043-.04 0-.069.01-.087.032-.018.021-.028.044-.028.07 0 .03.012.056.035.077.023.022.064.046.124.073.057.025.098.045.124.061.026.015.047.037.062.064.016.027.024.059.024.095 0 .056-.019.103-.057.138-.038.035-.088.053-.151.053-.073 0-.134-.028-.182-.083-.011.015-.019.036-.024.064z'
					transform='matrix(23.406528 0 0 24.15696 123.787253328 60.73114936)'
				/>
				<path
					d='m.058-.007v-.213h.024c.028.139.093.208.197.208.046 0 .082-.013.108-.04.026-.026.038-.056.038-.089s-.011-.061-.033-.083c-.023-.022-.064-.046-.123-.071-.054-.023-.094-.043-.121-.06s-.048-.04-.063-.067-.022-.056-.022-.087c0-.047.016-.087.049-.118.032-.032.074-.047.124-.047.028 0 .054.004.079.013s.05.028.076.057c.015-.018.024-.037.026-.058h.021v.173h-.021c-.013-.05-.035-.09-.066-.119-.032-.029-.068-.043-.109-.043-.04 0-.069.01-.087.032-.018.021-.028.044-.028.07 0 .03.012.056.035.077.023.022.064.046.124.073.057.025.098.045.124.061.026.015.047.037.062.064.016.027.024.059.024.095 0 .056-.019.103-.057.138-.038.035-.088.053-.151.053-.073 0-.134-.028-.182-.083-.011.015-.019.036-.024.064z'
					transform='matrix(23.406528 0 0 24.15696 152.84645784 60.73114936)'
				/>
				<path
					d='m.026-.64v-.022h.303v.022h-.015c-.032 0-.053.003-.064.008-.01.006-.016.016-.02.03-.003.015-.005.05-.005.105v.325c0 .052.001.086.004.102.002.016.009.028.019.036.011.008.031.012.061.012h.02v.022h-.303v-.022h.019c.029 0 .048-.004.059-.011s.018-.019.021-.034.004-.05.004-.105v-.325c0-.053-.001-.087-.004-.102s-.009-.026-.02-.032c-.01-.006-.031-.009-.065-.009z'
					transform='matrix(23.406528 0 0 24.15696 181.905662352 60.73114936)'
				/>
				<path
					d='m.616.015-.469-.62v.459c0 .038.002.063.006.075s.014.023.032.033.044.016.079.016v.022h-.247v-.022c.032-.003.056-.009.072-.018.017-.009.027-.019.03-.03s.005-.036.005-.076v-.351c0-.051-.003-.085-.008-.102-.004-.017-.012-.028-.022-.033s-.033-.008-.069-.008v-.022h.196l.392.518v-.396c0-.029-.003-.05-.009-.062-.006-.011-.019-.021-.037-.027-.019-.007-.041-.011-.067-.011v-.022h.24v.022c-.028.003-.05.008-.066.016-.016.009-.026.019-.031.031-.004.012-.006.032-.006.06v.548z'
					transform='matrix(23.406528 0 0 24.15696 206.576142864 60.73114936)'
				/>
				<path
					d='m.461-.283h.286v.024c-.032 0-.053.003-.064.009-.011.007-.019.017-.022.032-.004.015-.006.051-.006.108v.07c-.008-.001-.015-.002-.021-.002-.025 0-.058.008-.098.023-.056.021-.107.031-.154.031-.095 0-.174-.031-.236-.093-.063-.063-.095-.142-.095-.237s.03-.178.09-.249c.06-.072.141-.107.244-.107.089 0 .162.032.22.097.011-.022.018-.047.02-.073h.024v.221h-.024c-.009-.068-.035-.122-.077-.162s-.093-.059-.153-.059c-.063 0-.117.023-.163.069s-.069.128-.069.246.022.201.066.25c.045.049.1.073.167.073.033 0 .064-.007.092-.02.027-.014.046-.031.056-.051.011-.02.016-.045.016-.075 0-.036-.003-.059-.008-.071-.004-.012-.011-.02-.021-.024s-.028-.006-.054-.006h-.016z'
					transform='matrix(23.406528 0 0 24.15696 240.515608464 60.73114936)'
				/>
			</g>
		</svg>
	</Logo>
);

export default LoudounCrossing;
