// Imports
import styled from 'styled-components/macro';
import Rating from './rating';
import ratings from './ratings';


// Styled components
const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	gap: 6.25rem;
	flex-wrap: wrap;
	
	margin-top: 2.5rem;
	
	@media (max-width: 48rem) {
		gap: 3.125rem;
	}
`;


// Function component
const Ratings: React.FC = () => (
	<Wrapper>
		{ratings.map((rating, i) => (
			<Rating key={i} {...rating} />
		))}
	</Wrapper>
);

export default Ratings;
