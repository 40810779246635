// Imports
import {
	faCircleCheck,
	faHandWave,
	faLightbulbOn,
	faListTimeline,
	faMessageLines,
	faBook,
} from '@fortawesome/pro-duotone-svg-icons';
import Dropdown from './dropdown';


// Define links
const resourceLinks: React.ComponentProps<typeof Dropdown>['items'] = [
	{
		title: 'Blog',
		href: 'https://blog.hoa-express.com/',
		description: 'Announcements, tips, & tricks from our company',
		icon: faMessageLines,
	},
	{
		title: 'About us',
		to: '/about',
		description: 'Learn our story and what we’re passionate about',
		icon: faHandWave,
	},
	{
		title: 'Changelog',
		href: 'https://feedback.hoa-express.com/changelog',
		description: 'Our product keeps getting better and better',
		icon: faListTimeline,
	},
	{
		title: 'Help center',
		href: 'https://help.hoa-express.com/',
		description: 'An extensive library of guides, tutorials, and answers',
		icon: faBook,
	},
	{
		title: 'Status',
		href: 'https://status.hoa-express.com/',
		description: 'Transparent history of reliability and performance',
		icon: faCircleCheck,
	},
	{
		title: 'Ideas & feedback',
		href: 'https://feedback.hoa-express.com/',
		description: 'Share great ideas for new features and improvements',
		icon: faLightbulbOn,
	},
];

export default resourceLinks;
