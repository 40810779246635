// Imports
import { combineReducers } from 'redux';
import apiReducer from '../shareables/integrations/api/redux/reducer';
import commonReducer from '../shareables/foundation/front-end/redux/reducer';
import { AppCommonActions, State } from './types';
import { SIGNED_IN } from '../shareables/foundation/front-end/redux/types';


// Define initial state
const initialState: State['common'] = {
	route: {
		lastChange: 0,
	},
	user: {
		signedIn: false,
		id: null,
		name: null,
		firstName: null,
		lastName: null,
		email: null,
		profilePhotoURL: null,
		reseller: false,
		preferenceID: null,
	},
	pageMessages: [],
	activeNetworkRequestCount: 0,
};


// Customize common reducer
const appCommonReducer = (state = initialState, action: AppCommonActions): State['common'] => {
	switch (action.type) {
		case SIGNED_IN:
			return {
				...state,
				user: {
					...state.user,
					signedIn: true,
					id: action.id,
					name: action.name || state.user.name,
					firstName: action.firstName || state.user.firstName,
					lastName: action.lastName || state.user.lastName,
					email: action.email || state.user.email,
					profilePhotoURL: action.profilePhotoURL || state.user.profilePhotoURL,
					reseller: action.reseller || state.user.reseller,
					preferenceID: action.preferenceID || state.user.preferenceID,
				},
			};
		
		default: {
			const newState = commonReducer(state, action);
			
			return {
				...newState,
				user: {
					...newState.user,
					reseller: state.user.reseller,
					preferenceID: state.user.preferenceID,
				},
			};
		}
	}
};


// The root reducer
export default combineReducers({
	api: apiReducer,
	common: appCommonReducer,
});
