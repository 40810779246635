// Imports
import React from 'react';


// Define what the context looks like
export type ContextDetails = {
	/** A function that overrides the default scroll to top behavior on route change with a provided handler, returns cleanup function */
	overrideScrollBehavior: (handler: () => void) => void;
} | null;


// Create context
const ScrollBehaviorContext = React.createContext<ContextDetails>(null);

export default ScrollBehaviorContext;
