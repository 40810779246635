// Interfaces
interface Options {
	/** The ISO 4217 currency code of the currency to format to, defaults to `USD` */
	currency: string;
	
	
	/** Whether to display the specified currency code on the end of the formatted string; only has an effect if `currency` is provided as an option, if true, the currency code will not be appended at the end of the formatted string */
	hideCurrencyCode?: boolean;
	
	
	/** Hide the cents (instead of displaying .00) if the number is an integer */
	hideCentsIfInteger?: boolean;
}


/** Takes a plain number and turns it into a human-friendly currency string (e.g., 12345.67 -> $12,345.67) */
export default function formatCurrency(number: number, options?: Partial<Options>): string {
	return `${number.toLocaleString('en-US', {
		style: 'currency',
		currency: options?.currency ?? 'USD',
		maximumFractionDigits: options?.hideCentsIfInteger && Number.isInteger(number) ? 0 : 2,
		minimumFractionDigits: options?.hideCentsIfInteger && Number.isInteger(number) ? 0 : 2,
		currencyDisplay: 'narrowSymbol',
	})}${options?.currency && !options.hideCurrencyCode ? ` ${options.currency}` : ''}`;
}
