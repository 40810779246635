import React from 'react';
import { Link } from 'react-router-dom';


// Types
interface Clause {
	label: string;
	content: string | JSX.Element;
}


// Clauses
const clauses: Clause[] = [
	{
		label: 'Agreement',
		content: (
			<React.Fragment>
				<p>
					Using HOA Express’s Services or by using our websites in any way shall constitute your agreement to this Terms
					of Service Agreement.
				</p>
				
				<p>
					This Agreement represents the entire agreement between you and us and supersedes any other agreements or
					communications between you and us.
				</p>
				
				<p>
					This Agreement is valid for an indefinite term. Should you have a HOA Express account, this Agreement will
					remain in full force regardless of whether you are actively accessing the Services.
				</p>
				
				<p>
					Some services may be offered through third parties; in which case, your use of those services may also entail
					an agreement to their terms of service.
				</p>
			</React.Fragment>
		),
	},
	{
		label: 'Accounts',
		content: (
			<React.Fragment>
				<p>
					You do not need to have a HOA Express account to view the HOA Express website, however, to use some of our
					Services, an account is required. To create an account, you must have a valid email address and you’ll need to
					be 18 years of age or older. The information you provide while creating or updating your account must be
					accurate.
				</p>
				
				<p>
					While we employ a number of security techniques on our end, the security of your email address and password (“
					<b>Login Credentials</b>”) lies solely with you. You agree to not hold HOA Express responsible for any
					unauthorized access of your account.
				</p>
				
				<p>
					At our sole discretion, your account is subject to removal / deactivation / suspension / etc. at any time,
					without prior notice, and without refund.
				</p>
			</React.Fragment>
		),
	},
	{
		label: 'Personal Information',
		content: (
			<React.Fragment>
				<p>
					HOA Express may collect information while using this site, including, but not limited to, when you sign up for
					an account with HOA Express. Our use of such information is explained in our{' '}
					<Link to='../privacy-policy'>Privacy Policy</Link>.
				</p>
			</React.Fragment>
		),
	},
	{
		label: 'Restricted Uses',
		content: (
			<React.Fragment>
				<p>
					You are free to use our Services for most community-related purposes, with certain restrictions. By using our
					Services, you agree not to use our Services to:
				</p>
				
				<ul>
					<li>Engage in illegal activities</li>
					<li>Upload, distribute, or display content that is abusive, obscene, threatening, or illegal in any way</li>
					<li>Upload, distribute, or display adult content including, but not limited to, pornography</li>
					<li>
						Upload, distribute, or display content that infringes on any copyright, patent, trademark, or intellectual
						property
					</li>
					<li>Create websites with the primary purpose of file-sharing</li>
				</ul>
				
				<p>
					You understand that the above list is not exhaustive, and that other activities or uses of our Services may be
					deemed prohibited by HOA Express. We maintain a zero-tolerance policy toward these restrictions; any
					infringement of these restrictions will lead to immediate action by HOA Express including but not limited to
					deletion of your account, content, files, etc. and blocking current or future access to our Services.
				</p>
			</React.Fragment>
		),
	},
	{
		label: 'Community Designated Representatives',
		content: (
			<React.Fragment>
				<p>
					Each community using our Services designates at least one representative who has expanded responsibilities, as
					described in this Agreement. The responsibilities may include overseeing the billing, moderating content, and
					more. Whoever the current designated representatives are at a given time have the exclusive authority to
					manage the website. The community may also designate representatives who have limited expanded
					responsibilities, such as just overseeing the billing. Our Services make it clear when a user is given these
					responsibilities.
				</p>
				
				<p>
					If you are designated a representative for your community, you agree to fulfill these expanded
					responsibilities as described in this Agreement.
				</p>
			</React.Fragment>
		),
	},
	{
		label: 'Copyright Infringements',
		content: (
			<React.Fragment>
				<p>
					By uploading, distributing, or displaying information, files, data, etc. of any kind, you agree that the
					content is your property or that you have the proper authority to use such content, and that the content is
					not infringing on any copyrights, patents, trademarks, trade secrets, or intellectual property of any sort. If
					you have a concern about a copyright infringement on a HOA Express website, please contact HOA Express
					immediately.
				</p>
				
				<p>
					If a community is a repeat infringer of copyrighted material, we reserve the right to terminate all services
					provided to the community at any time.
				</p>
				
				<p>
					For DMCA takedown notices, please contact our{' '}
					<a
						href='https://dmca.copyright.gov/osp/publish/history.html?id=e0c38cbc68a47f8699d8400bb4f29c4f'
						target='_bank'
						rel='noopener noreferrer'
					>
						designated agent
					</a>
					.
				</p>
			</React.Fragment>
		),
	},
	{
		label: 'Free Trial',
		content: (
			<p>
				HOA Express offers one 30 day free trial of our deluxe plan per website to allow you to try out the features of
				our website building platform without obligation to purchase. No payment information is collected during the
				free trial, but to continue using the deluxe plan after the free trial expires, you’ll need to subscribe and
				provide payment information. If you do not subscribe, you understand that your website will be downgraded to the
				free plan, and you’ll lose access to some deluxe-only features.
			</p>
		),
	},
	{
		label: 'Billing',
		content: (
			<React.Fragment>
				<p>
					Billing is in the form of electronic payments or mailed checks. Most of our Services are subscription based,
					meaning that we will continue to charge the payment method associated with that subscription on a regularly
					recurring interval. Checks must be mailed in time for it to be received by the date it is due. The community’s
					designated representatives agree to pay the fees accrued by using our Services on the date they are due, and
					acknowledge that failure to do so may entail suspension or deactivation of Services. We reserve the right to
					indefinitely attempt subsequent charges if the first charge fails.
				</p>
				
				<p>
					When making payments, the payee does not gain any right to become a designated representative with expanded
					authority, whether at the time of the payment or in the future. Similarly, if the payee is already a
					designated representative at the time of their payment, this user does not gain any right to remain a
					designated representative going forward. In addition, because our services are licensed, not sold (see
					“Intellectual Property” section), no payment establishes any concept of ownership over the website.
				</p>
				
				<p>
					To ensure that billing is not interrupted, the community’s designated representatives are responsible for
					updating the payment information on file with us.
				</p>
				
				<p>
					You agree to not hold HOA Express responsible for any fee(s) incurred from a banking institution or third
					party as a result of our billing.
				</p>
			</React.Fragment>
		),
	},
	{
		label: 'Refunds',
		content: (
			<p>
				Website subscription payments are refundable within 30 days of making the payment. Other payments are
				non-refundable. However, HOA Express still reserves the right to issue a refund at any time, for any amount, and
				for any reason. For refund assistance, please contact our customer success team.
			</p>
		),
	},
	{
		label: 'Domains',
		content: (
			<React.Fragment>
				<p>
					Domains under the management of HOA Express are owned by the community to which the domain is assigned. In the
					context of domains managed by HOA Express, “owned” refers to the assigned community’s exclusive right to use
					the domain name with its HOA Express powered community website and the exclusive right for a designated
					representative of the community to transfer management of the domain to another registrar. If a domain name
					expires, the domain will be no longer be assigned to any community and all rights to the use and transfer of
					the domain will be revoked.
				</p>
				
				<p>
					For communities with an active website subscription, one domain purchase is included at no additional cost. In
					the event that a designated representative decides to change their community’s domain, one replacement domain
					can be purchased at no additional cost as well. In the context of domains, a “purchase” refers to either a
					completed transfer-in of an existing domain, or a registration of a new domain. After two domains have been
					purchased, future domain setups will require a $20 payment to cover the costs we incur when setting up
					domains. A “domain setup” refers to the process of associating a domain with a community using our service,
					even if the setup does not result in a domain purchase.
				</p>
			</React.Fragment>
		),
	},
	{
		label: 'Administration Transfer/Removal Policy',
		content: (
			<React.Fragment>
				<p>
					Occasionally situations arise where administrative privileges need to be transferred from one person to
					another, or an administrator needs to have their privileges removed. If a person without sufficient
					administrative privileges to complete this action themselves makes this request for a valid reason, HOA
					Express will first attempt to contact the user in question for approval or denial of the request using the
					email address currently on file with that user’s account. If no response is received after a reasonable amount
					of time, HOA Express will make a second attempt to contact the user. If a response is still not received after
					a reasonable amount of time, administration will be transferred or removed as requested.
				</p>
				
				<p>
					What constitutes a “valid reason” and a “reasonable amount of time” will be at the sole discretion of HOA
					Express. Reasons deemed valid are typically factual reasons such as the administrator has moved out of the
					community or has passed away. HOA Express will not engage in mediation of, arbitration of, or otherwise
					settlement of disputes within a community over administration.
				</p>
			</React.Fragment>
		),
	},
	{
		label: 'Technical Difficulties',
		content: (
			<React.Fragment>
				<p>
					HOA Express will make every commercially reasonable effort to keep community websites running free of error.
					However, you may still run into bugs, downtime, unexpected behavior, etc. In this event, you agree to not hold
					HOA Express liable for these issues, or any consequences of these issues.
				</p>
				
				<p>
					While we have multiple layers of redundancy and backup websites and content frequently, the loss of data can
					still occur. In the event of this, you agree to hold HOA Express harmless for any loss of information or any
					damages incurred.
				</p>
			</React.Fragment>
		),
	},
	{
		label: 'Content Monitoring',
		content: (
			<React.Fragment>
				<p>
					HOA Express reserves the right to monitor and/or moderate any content uploaded, distributed, or displayed via
					our Services. This includes, but is not limited to, monitoring content for inappropriate usage and
					editing/removing any content at any time and without notice.
				</p>
				
				<p>
					In addition, the community’s designated representatives agree to regularly monitor information added by their
					site’s members. We ask that they remove any offensive, abusive, inappropriate, or otherwise unacceptable
					content from member submitted portions of their sites.
				</p>
			</React.Fragment>
		),
	},
	{
		label: 'Intellectual Property',
		content: (
			<p>
				HOA Express’s services are licensed, not sold. You agree and understand that HOA Express owns and retains all
				copyrights to content that is produced by HOA Express, unless otherwise stated, including, but not limited to,
				files, source code, designs, and images. HOA Express agrees that all content added to a website by a user,
				remains the property of the respective user.
			</p>
		),
	},
	{
		label: 'Letter Mailing Service',
		content: (
			<p>
				Automated letter mailing is a service available to eligible communities. This service allows you to send
				physical mail to multiple or individual residents. Using this service incurs extra fees in addition to the
				subscription fee, to cover the cost of postage, printing, and handling. These fees are charged immediately upon
				sending a mailed letter. Fees are non-refundable. Although very rare, you agree not to hold HOA Express liable
				in the event that a carrier (e.g., USPS) does not successfully deliver a letter.
			</p>
		),
	},
	{
		label: 'Text Messaging Service',
		content: (
			<p>
				Blast text messaging is a service available to eligible communities. This service allows you to send SMS
				messages to multiple or individual residents. Using this service requires text messages credits. Some number of
				credits may be provided with your subscription, and additional credits can be purchased for an additional fee.
				These fees are charged immediately upon purchasing additional credits. Fees are non-refundable. Although very
				rare, you agree not to hold HOA Express liable in the event that a carrier (e.g., AT&T, T-Mobile, Verizon, etc.)
				does not successfully deliver a text message.
			</p>
		),
	},
	{
		label: 'Payment Processing',
		content: (
			<React.Fragment>
				<p>
					We’ve partnered with Stripe to provide payment processing services for communities. These services are subject
					to the{' '}
					<a href='https://stripe.com/connect-account/legal' target='_blank' rel='noopener noreferrer'>
						Stripe Connected Account Agreement
					</a>
					, which includes the{' '}
					<a href='https://stripe.com/legal' target='_blank' rel='noopener noreferrer'>
						Stripe Terms of Service
					</a>{' '}
					(collectively, the “Stripe Services Agreement”). By setting up or continuing to use the online payments
					feature, you agree to be bound by the Stripe Services Agreement, as the same may be modified by Stripe from
					time to time. As a condition of HOA Express enabling payment processing services through Stripe, you agree to
					provide us with accurate and complete information about you and your community, and you authorize HOA Express
					to share it with Stripe.
				</p>
				
				<p>
					Online payment processing is a service available for eligible communities. Various fees apply to using this
					service which are above and beyond the subscription fee. Each successful payment is subject to a transaction
					fee that’s dependent on the location of the payer, the location of the receiving bank account, and the payment
					method used. Failed payments may result in additional charges, particularly failed bank account payments.
					Further, chargebacks and other events may result in additional fees. Although transaction fees are deducted
					immediately from payments, other fees may be deducted from your community’s payments balance. Should your
					community’s payments balance fall below zero, you understand that we may automatically debit your community’s
					bank account and/or withhold future funds to bring the payments balance back to zero.
				</p>
			</React.Fragment>
		),
	},
	{
		label: 'Suggestions and Feedback',
		content: (
			<p>
				By sending suggestions, feedback, ideas, or other information to us, you are waiving all rights you have to such
				information and indicating that it is entirely your intellectual property. Such information immediately becomes
				the exclusive property of HOA Express and we are not entitled to provide compensation for any information given
				to us. HOA Express is permitted to use the information without restriction and no form or guarantee of
				confidentiality will be provided.
			</p>
		),
	},
	{
		label: 'Publicity',
		content: (
			<p>
				You agree that HOA Express is allowed to identify customers and use their community name, logo, website
				screenshot, or otherwise refer to them on the HOA Express website, in electronic media, or in print for
				marketing or reference purposes.
			</p>
		),
	},
	{
		label: 'Third Party Sites',
		content: (
			<p>
				During your use of our Services, you may be linked to third party websites. You agree that HOA Express has no
				control over such websites, and that your use of those websites is subject to the terms and conditions, privacy
				policies, etc. of those websites. You also agree that you will not hold HOA Express liable for content on those
				websites.
			</p>
		),
	},
	{
		label: 'DISCLAIMER OF WARRANTY',
		content: (
			<p>
				YOU AGREE THAT YOUR USE OF THIS WEBSITE AND ITS SERVICES ARE SOLELY AT YOUR OWN RISK. THIS WEBSITE AND ITS
				SERVICES ARE PROVIDED “AS IS” WITHOUT ANY EXPRESSED OR IMPLIED WARRANTIES. TO THE FULLEST EXTENT ALLOWED BY
				APPLICABLE LAW, WARRANTIES INCLUDING, BUT NOT LIMITED TO, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE
				ARE EXPRESSLY DISCLAIMED. IN NO EVENT SHALL HOA EXPRESS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
				PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, INCLUDING, BUT NOT LIMITED TO, LOSS OF PROFIT, LOSS OF DATA, OR
				INTERRUPTION OF BUSINESS, HOWEVER CAUSED OR ARISING IN ANY WAY OUT OF THE USE OF THIS SOFTWARE. HOA EXPRESS
				MAKES NO WARRANTIES OR REPRESENTATIONS THAT OUR SERVICES WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, THAT OUR
				SERVICES WILL BE ACCESSIBLE AT ALL TIMES, THAT OUR SERVICES WILL BE SECURE, UNINTERRUPTED, MALWARE-FREE,
				FREE-FROM-INACCURACIES, OR ERROR-FREE, OR THAT WE WILL CORRECT ANY BUGS OR UNEXPECTED BEHAVIOR.
			</p>
		),
	},
	{
		label: 'LIMITATION OF LIABILITY',
		content: (
			<p>
				HOA EXPRESS, ITS PARENT CORPORATION, OFFICERS, EMPLOYEES, AGENTS, PARTNERS, AND DIRECTORS SHALL NOT BE HELD
				LIABLE FOR ANY LOSS OF DATA, LOSS OF ACCESS, LOSS OF PROFITS, DAMAGES, LIABILITIES, EXPENSES, OR OTHER
				CONSEQUENCES RESULTING FROM ANYONE’S USE OF OUR SERVICES. IN NO WAY SHALL HOA EXPRESS BE HELD LIABLE FOR ANY
				UNAUTHORIZED ACCESS TO OUR SERVERS, DATABASES, CONTENT, OR OTHER INFORMATION STORED BY OUR SERVICES.
				SPECIFICALLY, YOU AGREE TO NOT HOLD HOA EXPRESS LIABLE FOR ANY WEBSITE CONTENT UPLOADED, DISTRIBUTED, OR
				OTHERWISE DISPLAYED BY ONE OF OUR USERS, WHETHER ILLEGAL, OFFENSIVE, DEFAMATORY, ABUSIVE, OR HARMFUL. YOU
				ACKNOWLEDGE THAT HOA EXPRESS DOES NOT PRESCREEN, APPROVE, OR OTHERWISE REVIEW CONTENT BEFORE IT IS UPLOADED,
				DISTRIBUTED, OR DISPLAYED USING OUR SERVICES. YOU FURTHER ACKNOWLEDGE THAT HOA EXPRESS IS NOT OBLIGATED TO
				REVIEW OR REMOVE CONTENT THAT HAS BEEN OR IS UPLOADED, DISTRIBUTED, OR DISPLAYED USING OUR SERVICES. YOU ALSO
				AGREE TO NOT HOLD HOA EXPRESS LIABLE FOR EVENTS OUTSIDE ITS REASONABLE CONTROL, INCLUDING, BUT NOT LIMITED TO,
				INTERNET FAILURES, TECHNOLOGY FAILURES, NATURAL DISASTERS, TERRORISM, WAR, COURT ORDERS, GOVERNMENT ACTIONS,
				TRIBUNALS, OR ACTS OF GOD. YOU AGREE TO NOT HOLD HOA EXPRESS LIABLE FOR THE ACTIONS OR NON-PERFORMANCE OF THIRD
				PARTIES USED BY, LINKED TO, OR OTHERWISE MENTIONED BY HOA EXPRESS.
			</p>
		),
	},
	{
		label: 'Time Limitation',
		content: (
			<p>
				You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or
				related to use of our Services must be filed within one year after such claim or cause of action arose or be
				forever barred.
			</p>
		),
	},
	{
		label: 'Severability of Terms',
		content: (
			<p>
				If any portion of this Agreement is found to be unenforceable by a court of competent jurisdiction, the
				remaining portions of this Agreement shall remain in full force and effect.
			</p>
		),
	},
	{
		label: 'Changes to the Terms of Service',
		content: (
			<p>
				HOA Express may revise or change the Terms of Service at any time and without notice. When posted to the HOA
				Express website, the changes will be considered effective. Your continued use of our Services after any changes
				are made will be considered acceptance of those changes.
			</p>
		),
	},
	{
		label: 'Miscellaneous',
		content: (
			<React.Fragment>
				<p>
					The headings of this Agreement are for convenience only, and do not affect this Agreement or its
					interpretation.
				</p>
				
				<p>You are not allowed to transfer any rights or obligations in this agreement without written consent.</p>
				
				<p>
					If we do not enforce any provision of this Agreement, it will not be considered a waiver of such provision.
				</p>
				
				<p>
					This Agreement is governed by the laws of the State of Texas, exclusive of any Texas choice of law principle
					that would require the application of the law of a different jurisdiction, and the laws of the United States
					of America, as applicable.
				</p>
			</React.Fragment>
		),
	},
];

export default clauses;
