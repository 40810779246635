// Imports
import styled from 'styled-components/macro';
import Quotes from '../../../resources/quotes';
import testimonials from './testimonials';


// Styled components
const Wrapper = styled.figure`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2.5rem;
	
	width: 100%;
	max-width: 71rem;
	
	margin-top: 3.75rem;
	padding: 0 1rem;
	
	overflow: hidden;
`;

const BrandName = styled.span`
	white-space: nowrap;
`;

const Heading = styled.figcaption`
	color: hsl(206, 69%, 35%);
	text-align: center;
	font-family: Zilla Slab;
	font-size: 3.125rem;
	font-weight: 600;
	line-height: 1;
	
	@media (max-width: 48rem) {
		font-size: 2.5rem;
	}
`;


// Function component
const Testimonials: React.FC = () => (
	<Wrapper>
		<Heading>
			Communities of all sizes love <BrandName>HOA Express</BrandName>
		</Heading>
		
		<Quotes quotes={testimonials} />
	</Wrapper>
);

export default Testimonials;
