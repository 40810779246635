// Imports
import specialSizes from './special-sizes';
import subscriptionSizes from './subscription-sizes';


/** A mapping of website sizes to their respective text message credit allotments */
const smsCreditsMapping: {
	[size in
		| (typeof subscriptionSizes)[number]
		| typeof specialSizes.LEGACY
		| typeof specialSizes.TRIAL
		| typeof specialSizes.BIG_DATA]: number;
} = {
	5000: 2500,
	2500: 2000,
	1000: 1500,
	500: 1000,
	300: 600,
	200: 400,
	125: 250,
	75: 150,
	50: 100,
	25: 50,
	15: 10, // Trials
	65535: 100, // Big data
	9999: 1500, // Legacy deluxe plan
};

export default smsCreditsMapping;


/** Determines whether a particular size is valid for using with the mapping */
export function sizeIsValidForSMSCreditsMapping(size: number): size is keyof typeof smsCreditsMapping {
	return Object.keys(smsCreditsMapping).includes(String(size));
}
