// Imports
import {
	State,
	ROUTE_CHANGED,
	SIGNED_IN,
	SIGNED_OUT,
	SHOW_PAGE_MESSAGE,
	HIDE_PAGE_MESSAGE,
	INCREMENT_NETWORK_REQUEST_COUNT,
	DECREMENT_NETWORK_REQUEST_COUNT,
	Actions,
} from './types';


// Define initial state
const initialState: State = {
	route: {
		lastChange: 0,
	},
	user: {
		signedIn: false,
		id: null,
		name: null,
		firstName: null,
		lastName: null,
		email: null,
		profilePhotoURL: null,
	},
	pageMessages: [],
	activeNetworkRequestCount: 0,
};


// The root reducer
export default function reducer(state = initialState, action: Actions): State {
	switch (action.type) {
		case ROUTE_CHANGED:
			return {
				...state,
				route: {
					...state.route,
					lastChange: new Date().getTime(),
				},
			};
		case SIGNED_IN:
			return {
				...state,
				user: {
					...state.user,
					signedIn: true,
					id: action.id,
					name: action.name || state.user.name,
					firstName: action.firstName || state.user.firstName,
					lastName: action.lastName || state.user.lastName,
					email: action.email || state.user.email,
					profilePhotoURL: action.profilePhotoURL || state.user.profilePhotoURL,
				},
			};
		case SIGNED_OUT:
			return {
				...state,
				user: {
					...state.user,
					signedIn: false,
					id: null,
					name: null,
					firstName: null,
					lastName: null,
					email: null,
					profilePhotoURL: null,
				},
			};
		case SHOW_PAGE_MESSAGE:
			return {
				...state,
				pageMessages: [
					...state.pageMessages,
					{
						color: action.color,
						title: action.title,
						message: action.message,
						dismissed: false,
					},
				],
			};
		case HIDE_PAGE_MESSAGE:
			state.pageMessages[action.index].dismissed = true;
			
			return {
				...state,
				pageMessages: [...state.pageMessages],
			};
		case INCREMENT_NETWORK_REQUEST_COUNT:
			return {
				...state,
				activeNetworkRequestCount: state.activeNetworkRequestCount + 1,
			};
		case DECREMENT_NETWORK_REQUEST_COUNT:
			return {
				...state,
				activeNetworkRequestCount: state.activeNetworkRequestCount - 1,
			};
		default:
			return state;
	}
}
