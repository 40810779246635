// Imports
import styled from 'styled-components/macro';


// Styled components
const Box = styled.div`
	border: 0.125rem solid hsl(206, 60%, 94%);
	padding: 1.5rem;
	
	> &:not(:last-child) {
		margin-bottom: 1rem;
	}
`;

export default Box;
