// Imports
import { Route } from 'react-router-dom';
import WebsiteDeleted from './deleted';
import WebsiteDisabled from './disabled';
import WebsiteNotFound from './not-found';
import WebsiteTakenDown from './taken-down';


// Function component
const sectionRoutes = (
	<Route>
		<Route path='website-deleted' element={<WebsiteDeleted />} handle={{ hideCTA: true }} />
		<Route path='website-disabled' element={<WebsiteDisabled />} handle={{ hideCTA: true }} />
		<Route path='website-taken-down' element={<WebsiteTakenDown />} handle={{ hideCTA: true }} />
		<Route path='website-not-found' element={<WebsiteNotFound />} handle={{ hideCTA: true }} />
	</Route>
);

export default sectionRoutes;
