// Imports
import { LinkProps, NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';


// Styled components
const Wrapper = styled.li`
	font-size: 1rem;
	line-height: 1;
	
	@media (max-width: 48rem) {
		font-size: 0.875rem;
	}
`;

const Label = styled.strong`
	display: block;
	margin-bottom: 0.625rem;
	line-height: 1.25;
	font-weight: 500;
	color: hsl(206, 25%, 20%);
	
	@media (max-width: 48rem) {
		margin-bottom: 0.5rem;
		line-height: 1.285;
	}
`;

const Links = styled.ul`
	list-style: none;
	display: flex;
	flex-direction: column;
	gap: 0.625rem;
	
	@media (max-width: 48rem) {
		gap: 0.5rem;
	}
`;

const Link = styled.a`
	all: unset;
	cursor: pointer;
	display: inline;
	color: hsl(206, 83%, 27%);
	
	&:hover {
		text-decoration: underline;
	}
	
	&:focus-visible {
		outline: -webkit-focus-ring-color auto 0.0625rem;
	}
`;


// Define the accepted props
interface BaseProps {
	text: string;
}

type Link = Omit<LinkProps, 'color'> & BaseProps;

type Anchor = { href: string } & Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'color' | 'href'> & BaseProps;

type Button = { 'data-canny-changelog'?: boolean } & React.ButtonHTMLAttributes<HTMLButtonElement> & BaseProps;

export interface Props {
	label: string;
	links: (Link | Anchor | Button)[];
}


// Function component
const List: React.FC<Props> = ({ label, links }) => (
	<Wrapper>
		<Label>{label}</Label>
		
		<Links>
			{links.map(({ text, ...link }, i) => (
				<li key={i}>
					<Link
						as={'to' in link ? NavLink : 'href' in link ? 'a' : 'button'}
						{...link}
						{...('href' in link ? { target: '_blank', rel: 'noopener' } : {})}
					>
						{text}
					</Link>
				</li>
			))}
		</Links>
	</Wrapper>
);

export default List;
