import DeluxePlanPricing from './deluxe-plan-pricing';
import FreePlanPricing from './free-plan-pricing';
import { Props } from './plan';


// Plans
const plans: Props[] = [
	{
		name: 'Free',
		description: 'Basic features for small communities',
		backgroundColor: 'hsl(36, 100%, 90%)',
		containerWidth: '18.75rem',
		accentColor: 'hsl(36, 44%, 52%)',
		pricing: () => <FreePlanPricing />,
		features: {
			features: [
				'Unlimited pages',
				'Unlimited storage',
				'Page restrictions',
				'Upload documents',
				'Calendar events',
				'Board & committee info',
				'News articles',
				'Resident directory',
			],
		},
		callToAction: {
			backgroundColor: 'hsl(36, 44%, 52%)',
			fontColor: 'hsl(0, 0%, 100%)',
			href: `https://start.${process.env.REACT_APP__ENVIRONMENT_HOSTNAME}`,
			rel: 'noopener',
			text: 'Get started',
			displayArrowIndicator: true,
		},
	},
	{
		name: 'Deluxe',
		description: 'Every feature your community needs to succeed',
		backgroundColor: 'hsl(206, 70%, 88%)',
		containerWidth: '25rem',
		accentColor: 'hsl(206, 69%, 35%)',
		pricing: (state) => <DeluxePlanPricing {...state} />,
		features: {
			label: 'Everything in free, plus:',
			features: [
				'Blast emails & texts',
				'Domain & email accounts',
				'Multiple website admins',
				'Collect payments online',
				'Contact forms',
				'Photo galleries',
				'Groups of residents',
				'No ads',
			],
		},
		callToAction: {
			backgroundColor: 'hsl(96, 54%, 59%)',
			fontColor: 'hsl(0, 0%, 100%)',
			href: `https://start.${process.env.REACT_APP__ENVIRONMENT_HOSTNAME}`,
			rel: 'noopener',
			text: 'Start for free',
			displayArrowIndicator: true,
		},
		mostPopular: {
			backgroundColor: 'hsl(206, 69%, 35%)',
			fontColor: 'hsl(0, 0%, 100%)',
		},
	},
];

export default plans;
