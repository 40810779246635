// Imports
import React, { useEffect, useState } from 'react';
import appRoutes from './app-routes';
import GlobalStyle from './global-style';
import GlobalErrorBoundary from './components/resources/global-error-boundary';
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import store from './shareables/foundation/front-end/redux/store';
import RouteChangeListener from './shareables/foundation/front-end/components/resources/route-change-listener';
import { z } from 'zod';
import PageMessages from './shareables/foundation/front-end/components/resources/page-messages';
import Navbar from './components/resources/navbar';
import thirdPartyIdentifiers from './shareables/third-party-identifiers';
import UserFunctionality from './components/resources/user-functionality';
import ScrollBehaviorHandler from './shareables/foundation/front-end/components/resources/scroll-behavior-handler';
import CookieBanner from './components/resources/cookie-banner';
import Title from './components/resources/title';


// Function component
const App: React.FC = () => {
	// Use state
	const [title, setTitle] = useState(document.title);
	
	
	// Boot Intercom, update with user data when authenticated
	useEffect(() => {
		if (window.Intercom) {
			window.Intercom('boot', {
				app_id:
					process.env.REACT_APP__ENVIRONMENT === 'production'
						? thirdPartyIdentifiers.intercomAppID.production
						: thirdPartyIdentifiers.intercomAppID.development,
			});
		}
	}, []);
	
	
	// Build inner content
	const innerContent = (
		<ScrollBehaviorHandler>
			{/* RouteChangeListener must be placed first in the BrowserRouter, so that it processes changes first */}
			<RouteChangeListener title={title} />
			
			<Helmet
				defaultTitle='HOA website builder & software platform // HOA Express'
				titleTemplate='%s // HOA Express'
				onChangeClientState={(newState) => {
					const clientStateSchema = z.object({
						title: z.string(),
					});
					
					const { title: newTitle } = clientStateSchema.parse(newState);
					
					setTitle(newTitle);
				}}
			/>
			
			<Title />
			
			<CookieBanner />
			
			<PageMessages />
			
			<UserFunctionality />
			
			<Navbar />
			
			<Outlet />
		</ScrollBehaviorHandler>
	);
	
	
	// Use React Router functionality
	const router = createBrowserRouter(
		createRoutesFromElements(
			<Route element={<GlobalErrorBoundary>{innerContent}</GlobalErrorBoundary>}>{appRoutes}</Route>
		)
	);
	
	
	// Return JSX
	return (
		<React.StrictMode>
			<GlobalErrorBoundary>
				<GlobalStyle />
				
				<Provider store={store}>
					<HelmetProvider>
						<RouterProvider router={router} />
					</HelmetProvider>
				</Provider>
			</GlobalErrorBoundary>
		</React.StrictMode>
	);
};

export default App;
