// Imports
import { faCreditCard } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import styled from 'styled-components/macro';
import Feature from '..';


// Styled components
const FastAndEasy = styled.span`
	white-space: nowrap;
`;


// Banner
const feature: React.ComponentProps<typeof Feature> = {
	banner: {
		label: { icon: faCreditCard, text: 'Online payments' },
		heading: 'Let residents make payments the convenient way',
		description:
			'HOA Express’s fully-integrated online payments platform is incredibly easy to set up. In just a few minutes, start offering your residents the convenience of secure online payments. Plus, pass on transaction fees with the click of checkbox.',
		screenshot: {
			width: 900,
			height: 481,
			src: '/images/screenshots/features/online-payments/banner.webp',
		},
	},
	pitch: {
		text: (
			<React.Fragment>
				Give residents a <FastAndEasy>fast & easy</FastAndEasy> way to pay
			</React.Fragment>
		),
		textMaxWidth: 'min(29.6875rem, 39.6vw)',
	},
	tiles: {
		leftColumn: [
			{
				heading: 'Pass transaction fees along',
				screenshot: {
					width: 880,
					height: 128,
					src: (layout) => `/images/screenshots/features/online-payments/${layout}/pass-transaction-fees.webp`,
				},
			},
			{
				heading: 'Detailed history',
				screenshot: {
					width: 980,
					height: 216,
					src: (layout) => `/images/screenshots/features/online-payments/${layout}/history.webp`,
				},
			},
			{
				heading: 'Pay with a bank account',
				screenshot: {
					width: 680,
					height: 364,
					src: (layout) => `/images/screenshots/features/online-payments/${layout}/bank-account.webp`,
				},
			},
			{
				heading: 'Activate & deactivate at any time',
				screenshot: {
					width: 980,
					height: 260,
					src: (layout) => `/images/screenshots/features/online-payments/${layout}/activate-deactivate.webp`,
				},
			},
		],
		rightColumn: [
			{
				heading: 'Set up in minutes',
				screenshot: {
					width: 980,
					height: 168,
					src: (layout) => `/images/screenshots/features/online-payments/${layout}/set-up.webp`,
				},
			},
			{
				heading: 'All major cards supported',
				screenshot: {
					width: 903,
					height: 140,
					src: (layout) => `/images/screenshots/features/online-payments/${layout}/cards.webp`,
				},
			},
			{
				heading: 'Quickly announce',
				screenshot: {
					width: 980,
					height: 260,
					src: (layout) => `/images/screenshots/features/online-payments/${layout}/announce.webp`,
				},
			},
			{
				heading: 'Export payment history',
				screenshot: {
					width: 659,
					height: 172,
					src: (layout) => `/images/screenshots/features/online-payments/${layout}/export.webp`,
				},
			},
		],
	},
	testimonials: [
		{
			text: 'We are excited about using the online payment option! It’s another valuable tool for members to use and will get them on the website, where they can explore even more.',
			source: {
				name: 'Michael',
				hoa: 'Emerald Estates',
				url: 'https://www.emeraldestateshoa.com/',
			},
		},
	],
};

export default feature;
