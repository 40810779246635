// Imports
import Logo from './logo';


// Function component
const Brierfield: React.FC = () => (
	<Logo width={9} aria-label='Brierfield'>
		<svg
			clipRule='evenodd'
			fillRule='evenodd'
			strokeLinejoin='round'
			strokeMiterlimit='2'
			viewBox='0 0 488 135'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g transform='translate(-26 -4)'>
				<path
					d='m.063-0h.332c.066 0 .111-.008.155-.026.067-.028.113-.09.113-.154 0-.046-.024-.093-.064-.124-.028-.023-.052-.034-.107-.05.053-.006.079-.012.113-.025.06-.024.099-.076.099-.133 0-.038-.018-.075-.048-.102-.053-.045-.122-.063-.247-.063-.089 0-.151.01-.208.032-.095.038-.155.105-.155.175 0 .057.041.098.097.098.045 0 .083-.031.083-.068 0-.022-.015-.037-.036-.037-.017 0-.034.012-.034.024 0 .002.001.005.003.009.003.007.004.008.004.011 0 .017-.022.034-.044.034-.032 0-.058-.032-.058-.072 0-.092.124-.177.257-.177.043 0 .061.009.061.03 0 .005-.002.013-.005.02l-.177.464c-.037.098-.058.12-.117.121h-.017zm.317-.37.092-.238c.011-.03.021-.037.051-.037.061 0 .102.043.102.106 0 .057-.029.11-.077.14-.034.021-.069.029-.13.029zm-.007.02h.024c.111 0 .18.058.18.152 0 .108-.096.181-.238.181h-.022c-.041 0-.06-.007-.06-.023 0-.004.003-.017.006-.025z'
					fillRule='nonzero'
					transform='matrix(149.82072 0 0 143.38464 19.46999917 111.064741952)'
				/>
				<path d='m284.505 82.153c0-5.988 7.373-12.798 17.279-12.798s15.122 1.76 22.55 1.991c7.428.23 8.801-5.629 11.599-10.983l4.858.346-12.264 32.518-4.259-.928s3.619-12.21 1.898-15.733c-1.722-3.523-20.713-3.84-26.504-2.566-5.792 1.274-8.5 2.856-9.838 8.153-1.339 5.297-5.319 5.988-5.319 0z' />
				<path
					d='m67.465 48.155-.264.792c-.192.648-.36 1.224-.552 1.896-1.392 5.208-1.536 5.688-2.28 7.776-.696 1.968-1.2 2.784-1.776 2.784-.144 0-.336-.096-.552-.312-.384-.336-.552-.432-.864-.432-.432 0-.768.336-.768.744 0 .48.456.84 1.08.84 1.488 0 2.76-1.176 4.152-3.816 1.008-1.896 2.136-5.04 3.408-9.48l.216-.792c1.2-4.392 1.56-5.232 2.208-5.232.192 0 .336.072.576.384.36.408.48.48.768.48.336 0 .648-.288.648-.648 0-.48-.504-.816-1.176-.816-1.872 0-3.456 1.896-4.824 5.832z'
					fillRule='nonzero'
					transform='matrix(7.61692 .246272 -.220822 6.82977 -185.85 -302.21)'
				/>
				<g transform='matrix(6.19433 0 0 5.55916 -277.714 -213.568)'>
					<g fillRule='nonzero'>
						<path d='m65.329 51.683c1.104-1.584 2.04-2.64 2.328-2.64.096 0 .168.096.168.192 0 .144-.072.336-.336 1.032l-3.288 8.136h1.752l1.464-3.6c1.464-3.624 2.88-5.832 3.768-5.832.336 0 .504.168.624.6.12.408.36.648.696.648.504 0 .96-.552.96-1.152s-.504-1.08-1.128-1.08c-.792 0-1.584.48-2.328 1.392-.432.504-.432.528-.936 1.224.24-.984.312-1.44.312-1.728 0-.504-.288-.864-.648-.864-.84 0-2.112 1.224-3.72 3.528z' />
						<path d='m76.153 55.163c-.696 1.032-1.8 2.208-2.04 2.208-.096 0-.144-.048-.144-.144s.024-.144.144-.48l.096-.216 2.088-5.136.312-.816c.528-1.32.576-1.44.576-1.8 0-.408-.288-.696-.72-.696-.864 0-2.4 1.416-3.888 3.6l.264.168c.864-1.296 2.04-2.64 2.328-2.64.072 0 .144.072.144.144s-.048.216-.192.576l-2.232 5.4c-.696 1.704-.792 1.968-.792 2.424s.288.744.744.744c.552 0 1.152-.384 1.92-1.152.624-.648 1.248-1.392 1.656-2.016z' />
						<path d='m82.825 55.187c-1.272 1.824-2.088 2.544-2.904 2.544-.504 0-.84-.432-.84-1.056 0-.6.264-1.848.576-2.64.168-.12.288-.192.84-.528 2.952-1.728 4.128-2.88 4.128-4.08 0-.84-.528-1.344-1.44-1.344-.864 0-1.752.576-2.952 1.944-1.776 1.992-2.904 4.392-2.904 6.192 0 1.392.72 2.256 1.92 2.256 1.224 0 2.664-1.2 3.84-3.144zm-3.024-1.656c.984-3 2.328-5.088 3.288-5.088.312 0 .552.288.552.72 0 .744-.552 1.632-1.776 2.808-.6.6-1.512 1.296-2.064 1.56z' />
						<path d='m85.417 51.683c1.104-1.584 2.04-2.64 2.328-2.64.096 0 .168.096.168.192 0 .144-.072.336-.336 1.032l-3.288 8.136h1.752l1.464-3.6c1.464-3.624 2.88-5.832 3.768-5.832.336 0 .504.168.624.6.12.408.36.648.696.648.504 0 .96-.552.96-1.152s-.504-1.08-1.128-1.08c-.792 0-1.584.48-2.328 1.392-.432.504-.432.528-.936 1.224.24-.984.312-1.44.312-1.728 0-.504-.288-.864-.648-.864-.84 0-2.112 1.224-3.72 3.528z' />
						<path d='m103.249 55.163c-.696 1.032-1.8 2.208-2.04 2.208-.096 0-.144-.048-.144-.144s.024-.144.144-.48l.096-.216 2.088-5.136.312-.816c.528-1.32.576-1.44.576-1.8 0-.408-.288-.696-.72-.696-.864 0-2.4 1.416-3.888 3.6l.264.168c.864-1.296 2.04-2.64 2.328-2.64.072 0 .144.072.144.144s-.048.216-.192.576l-2.232 5.4c-.696 1.704-.792 1.968-.792 2.424s.288.744.744.744c.552 0 1.152-.384 1.92-1.152.624-.648 1.248-1.392 1.656-2.016z' />
					</g>
					<circle cx='196.835' cy='37.718' r='7.033' transform='matrix(.161438 0 0 .179883 44.942 37.9382)' />
					<circle cx='196.835' cy='37.718' r='7.033' transform='matrix(.161438 0 0 .179883 71.8533 37.9382)' />
					<path
						d='m109.921 55.187c-1.272 1.824-2.088 2.544-2.904 2.544-.504 0-.84-.432-.84-1.056 0-.6.264-1.848.576-2.64.168-.12.288-.192.84-.528 2.952-1.728 4.128-2.88 4.128-4.08 0-.84-.528-1.344-1.44-1.344-.864 0-1.752.576-2.952 1.944-1.776 1.992-2.904 4.392-2.904 6.192 0 1.392.72 2.256 1.92 2.256 1.224 0 2.664-1.2 3.84-3.144zm-3.024-1.656c.984-3 2.328-5.088 3.288-5.088.312 0 .552.288.552.72 0 .744-.552 1.632-1.776 2.808-.6.6-1.512 1.296-2.064 1.56z'
						fillRule='nonzero'
					/>
					<path
						d='m118.489 42.395h-3.072l-.144.312c1.032.048 1.176.072 1.176.336 0 .12 0 .12-.312.936l-4.368 10.848-.36.912c-.552 1.368-.624 1.56-.624 1.992 0 .456.264.744.648.744 1.056 0 2.496-1.344 4.032-3.768l-.264-.168c-1.128 1.752-2.088 2.856-2.472 2.856-.096 0-.144-.072-.144-.144 0-.12 0-.12.168-.504l.144-.384z'
						fillRule='nonzero'
					/>
					<path
						d='m127.729 42.395h-3.36l-.12.312c1.152.024 1.416.096 1.416.336 0 .12 0 .12-.312.936l-1.848 4.584c-.312-.336-.624-.48-1.056-.48-1.152 0-2.544.984-4.008 2.856-1.728 2.136-2.784 4.488-2.784 6.072 0 .912.528 1.488 1.344 1.488.768 0 1.536-.48 2.448-1.488.48-.552 1.032-1.248 1.368-1.752l-.072.192c-.024.144-.072.288-.12.408l-.168.624c-.336 1.152-.336 1.152-.336 1.44 0 .336.192.552.528.552.744 0 2.352-1.392 3.528-3.048l-.288-.168c-.744 1.032-1.728 2.064-1.944 2.064-.048 0-.096-.048-.096-.096 0-.096.144-.528.288-.936zm-5.424 6.024c.408 0 .696.384.696.936 0 1.128-1.056 3.576-2.472 5.736-1.152 1.728-2.208 2.736-2.88 2.736-.24 0-.384-.24-.384-.648 0-.984 1.296-3.912 2.808-6.384 1.08-1.728 1.68-2.376 2.232-2.376z'
						fillRule='nonzero'
					/>
				</g>
			</g>
		</svg>
	</Logo>
);

export default Brierfield;
