// Imports
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';


// Styled component
const Wrapper = styled.div`
	white-space: nowrap;
	
	&::after {
		display: block;
		content: attr(data-content);
		font-weight: 600;
		height: 1px;
		color: transparent;
		overflow: hidden;
		visibility: hidden;
	}
`;

const Container = styled(NavLink)`
	line-height: 2;
	color: hsl(206, 15%, 40%);
	text-decoration: none;
	
	&:hover,
	&.active {
		color: hsl(206, 25%, 20%);
	}
	
	&.active {
		font-weight: 600;
	}
`;


// Define the accepted props
type Props = Omit<React.ComponentProps<typeof NavLink>, 'to'> & { to: string; text: string };


// Function component
const Link: React.FC<Props> = ({ text, ...props }) => (
	<Wrapper data-content={`${text} →`}>
		<Container {...props}>{({ isActive }) => (isActive ? `${text} →` : text)}</Container>
	</Wrapper>
);

export default Link;
