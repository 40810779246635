// Imports
import React from 'react';
import styled from 'styled-components/macro';
import SimplePageTemplate from '../../resources/simple-page-template';
import Numbers from './numbers';
import OurStory from './our-story';
import ProductValues from './product-values';
import Testimonials from './testimonials';


// Styled components
const Content = styled.div`
	display: inline-flex;
	padding-top: 3.75rem;
	flex-direction: column;
	align-items: center;
	gap: 3.75rem;
	
	width: 100%;
`;


// Function component
const AboutUs: React.FC = () => (
	<SimplePageTemplate
		heading='Making HOA websites delightful'
		descriptor={{
			text: 'Hi, we’re HOA Express! 👋  We’re passionate about creating HOA websites that everyone enjoys—board members and residents alike. Our product makes information accessible, streamlines communication, and promotes involvement.',
			textMaxWidth: '46.875rem',
		}}
	>
		<Content>
			<OurStory />
			
			<Numbers />
			
			<ProductValues />
			
			<Testimonials />
		</Content>
	</SimplePageTemplate>
);

export default AboutUs;
