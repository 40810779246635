// Imports
import styled from 'styled-components/macro';
import Quotes from '../../../resources/quotes';
import testimonials from './testimonials';


// Styled components
const StyledQuotes = styled(Quotes)`
	max-width: 62.5rem;
	padding: 0 1rem;
	
	> li {
		flex: 1 0 18.75rem;
		
		> blockquote {
			max-width: 100%;
			
			> p {
				font-size: inherit !important;
				margin-bottom: 0 !important;
			}
		}
	}
`;


// Function component
const Testimonials: React.FC = () => <StyledQuotes quotes={testimonials} />;

export default Testimonials;
