// Imports
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';


// Styled components
const Wrapper = styled(NavLink)`
	margin-right: 3.125rem;
	z-index: 3;
	
	display: inline-block;
	width: 15.625rem;
	
	@media (max-width: 48rem) {
		width: 11.25rem;
		max-width: 55vw;
	}
	
	> svg {
		width: 100%;
	}
	
	@media (max-width: 70rem) {
		margin-right: 0;
	}
`;


// Define the accepted props
interface Props {
	onClick: () => void;
}


// Function component
const Logo: React.FC<Props> = ({ onClick }) => (
	<Wrapper to='/' onClick={onClick} aria-label='Homepage'>
		<svg viewBox='0 0 250 43' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g id='Logo' clipPath='url(#clip0_8_48)'>
				<g id='Group'>
					<g id='Group_2'>
						<path
							id='Vector'
							d='M139.558 7.37722V11.5358H130.938V19.1164H137.305V23.2316H130.938V32.1552H139.558V36.2704H126.477V7.37722H139.558Z'
							fill='#7AA9CC'
						/>
						<path
							id='Vector_2'
							d='M152.597 21.1957L159.353 36.2704H154.372L149.998 25.7874L145.883 36.2704H140.989L147.442 21.1957L141.249 7.37722H146.186L150.041 16.6473L153.636 7.37722H158.487L152.597 21.1957Z'
							fill='#7AA9CC'
						/>
						<path
							id='Vector_3'
							d='M166.574 24.5745V36.2704H162.113V7.37722H169.519C172.291 7.37722 174.327 8.02699 175.583 9.2399C177.099 10.7127 177.878 12.9653 177.878 15.9975C177.878 18.9865 177.099 21.239 175.583 22.7118C174.933 23.3616 174.11 23.8381 173.157 24.1413C172.205 24.4446 170.992 24.5745 169.519 24.5745H166.574ZM166.574 20.4593H168.523C170.169 20.4593 171.338 20.1994 171.988 19.6796C172.854 19.0298 173.287 17.8169 173.287 15.9975C173.287 14.1782 172.854 12.9219 171.988 12.3155C171.338 11.7957 170.169 11.5358 168.523 11.5358H166.574V20.4593Z'
							fill='#7AA9CC'
						/>
						<path
							id='Vector_4'
							d='M185.396 24.4446V36.2704H180.935V7.37722H187.952C190.42 7.37722 192.326 7.98367 193.669 9.15326C195.358 10.6261 196.224 12.792 196.224 15.651C196.224 19.333 194.925 21.8455 192.326 23.1883L196.614 36.2704H191.893L188.082 24.4446H185.396ZM185.396 20.286H187.042C188.255 20.286 189.121 20.1994 189.641 19.9828C190.984 19.4197 191.633 18.0335 191.633 15.9109C191.633 14.3081 191.243 13.1818 190.507 12.4454C190.117 12.0123 189.598 11.7523 188.991 11.6657C188.428 11.5791 187.778 11.5358 187.042 11.5358H185.396V20.286Z'
							fill='#7AA9CC'
						/>
						<path
							id='Vector_5'
							d='M213.028 7.37722V11.5358H204.409V19.1164H210.776V23.2316H204.409V32.1552H213.028V36.2704H199.948V7.37722H213.028Z'
							fill='#7AA9CC'
						/>
						<path
							id='Vector_6'
							d='M231.265 14.1782L226.977 15.4777C226.284 12.5754 225.028 11.1459 223.122 11.1459C222.342 11.1459 221.649 11.4491 221.13 11.9689C220.523 12.5321 220.22 13.2252 220.22 14.0915C220.22 15.1745 220.653 16.1275 221.433 16.9938C221.953 17.557 223.252 18.6399 225.288 20.1561C227.367 21.8021 228.796 23.0584 229.576 24.0547C230.832 25.5708 231.438 27.3035 231.438 29.3395C231.438 31.4621 230.745 33.2381 229.316 34.581C227.843 36.0105 225.937 36.7036 223.555 36.7036C218.618 36.7036 215.672 33.9745 214.763 28.5598L219.051 27.3035C219.614 30.769 221.13 32.5017 223.555 32.5017C224.551 32.5017 225.374 32.1985 225.937 31.592C226.544 30.9856 226.847 30.1625 226.847 29.2095C226.847 28.1699 226.457 27.2602 225.721 26.3939C225.114 25.7008 224.032 24.7478 222.559 23.5782C220.393 21.8888 219.051 20.8491 218.618 20.416C216.625 18.4667 215.629 16.3874 215.629 14.0915C215.629 11.7957 216.452 10.0196 218.055 8.67676C219.441 7.55049 221.13 6.98736 223.165 6.98736C227.323 6.98736 230.009 9.36985 231.265 14.1782Z'
							fill='#7AA9CC'
						/>
						<path
							id='Vector_7'
							d='M249.727 14.1782L245.439 15.4777C244.746 12.5754 243.49 11.1459 241.585 11.1459C240.805 11.1459 240.112 11.4491 239.592 11.9689C238.986 12.5321 238.683 13.2252 238.683 14.0915C238.683 15.1745 239.116 16.1275 239.895 16.9938C240.415 17.557 241.715 18.6399 243.75 20.1561C245.829 21.8021 247.259 23.0584 248.038 24.0547C249.294 25.5708 249.901 27.3035 249.901 29.3395C249.901 31.4621 249.208 33.2381 247.778 34.581C246.306 36.0105 244.4 36.7036 242.018 36.7036C237.08 36.7036 234.135 33.9745 233.225 28.5598L237.513 27.3035C238.076 30.769 239.592 32.5017 242.018 32.5017C243.014 32.5017 243.837 32.1985 244.4 31.592C245.006 30.9856 245.31 30.1625 245.31 29.2095C245.31 28.1699 244.92 27.2602 244.183 26.3939C243.577 25.7008 242.494 24.7478 241.022 23.5782C238.856 21.8888 237.513 20.8491 237.08 20.416C235.088 18.4667 234.092 16.3874 234.092 14.0915C234.092 11.7957 234.915 10.0196 236.517 8.67676C237.903 7.55049 239.592 6.98736 241.628 6.98736C245.786 6.98736 248.471 9.36985 249.727 14.1782Z'
							fill='#7AA9CC'
						/>
					</g>
					<path
						id='Vector_8'
						d='M72.2934 23.5782H65.9697V36.2704H61.5086V7.37722H65.9697V19.4197H72.2934V7.37722H76.7979V36.2704H72.2934V23.5782Z'
						fill='#1C6296'
					/>
					<path
						id='Vector_9'
						d='M96.6394 16.5607V27.087C96.6394 28.733 96.5094 30.0326 96.2495 31.1155C95.9897 32.1552 95.5566 33.1082 94.9935 33.9312C93.6508 35.7506 91.5718 36.7036 88.7565 36.7036C85.9412 36.7036 83.8622 35.7506 82.5195 33.9312C81.8265 32.9349 81.3501 31.8519 81.1335 30.639C80.9603 29.5561 80.8736 28.3432 80.8736 27.087V16.5607C80.8736 14.9579 81.0036 13.615 81.2634 12.5754C81.5233 11.4924 81.9131 10.5828 82.5195 9.75972C83.8622 7.89704 85.9412 6.98736 88.7565 6.98736C91.5718 6.98736 93.6508 7.89704 94.9935 9.75972C95.6865 10.7127 96.1629 11.839 96.3362 13.0519C96.5527 14.1348 96.6394 15.3044 96.6394 16.5607ZM85.3348 16.6473V27.0003C85.3348 28.3865 85.4214 29.3395 85.5081 29.8593C85.9412 31.6354 87.024 32.5017 88.7565 32.5017C90.0992 32.5017 91.0521 31.9819 91.5718 30.9423C91.875 30.3791 92.0482 29.7727 92.0916 29.0796C92.1349 28.5165 92.1349 27.8234 92.1349 27.0003V16.6473C92.1349 15.2611 92.0916 14.3081 91.9616 13.7883C91.5718 12.0556 90.489 11.1459 88.7565 11.1459C87.4138 11.1459 86.4609 11.6657 85.9412 12.7487C85.638 13.3118 85.4647 13.9183 85.4214 14.568C85.3781 15.1312 85.3348 15.8243 85.3348 16.6473Z'
						fill='#1C6296'
					/>
					<path
						id='Vector_10'
						d='M111.119 29.9893H104.969L103.799 36.2704H99.4249L104.969 7.37722H111.076L116.923 36.2704H112.332L111.119 29.9893ZM110.34 25.874L108.001 12.9219L105.662 25.874H110.34Z'
						fill='#1C6296'
					/>
				</g>
				<g id='Group_3'>
					<g id='Group_4'>
						<path
							id='Vector_11'
							fillRule='evenodd'
							clipRule='evenodd'
							d='M33.676 26.1254C33.676 25.4702 33.1441 24.9383 32.4888 24.9383H32.4883C31.833 24.9383 31.301 25.4702 31.301 26.1254V36.8504C31.301 37.5056 31.833 38.0377 32.4883 38.0377H32.4888C33.1441 38.0377 33.676 37.5056 33.676 36.8504V26.1254Z'
							fill='white'
						/>
						<path
							id='Vector_12'
							fillRule='evenodd'
							clipRule='evenodd'
							d='M33.676 26.1272C33.676 25.471 33.1432 24.9383 32.4869 24.9383H24.4699C23.8136 24.9383 23.2808 25.471 23.2808 26.1272C23.2808 26.7834 23.8136 27.3162 24.4699 27.3162H32.4869C33.1432 27.3162 33.676 26.7834 33.676 26.1272Z'
							fill='white'
						/>
						<path
							id='Vector_13'
							fillRule='evenodd'
							clipRule='evenodd'
							d='M25.6558 26.1254C25.6558 25.4702 25.1238 24.9383 24.4685 24.9383H24.4681C23.8127 24.9383 23.2808 25.4702 23.2808 26.1254V36.8504C23.2808 37.5056 23.8127 38.0377 24.4681 38.0377H24.4685C25.1238 38.0377 25.6558 37.5056 25.6558 36.8504V26.1254Z'
							fill='white'
						/>
						<path
							id='Vector_14'
							fillRule='evenodd'
							clipRule='evenodd'
							d='M44.539 10.2543C44.539 9.09175 43.595 8.14792 42.4323 8.14792H42.4321C41.2695 8.14792 40.3255 9.09175 40.3255 10.2543V37.439C40.3255 38.6016 41.2695 39.5455 42.4321 39.5455H42.4323C43.595 39.5455 44.539 38.6016 44.539 37.439V10.2543Z'
							fill='white'
						/>
						<path
							id='Vector_15'
							fillRule='evenodd'
							clipRule='evenodd'
							d='M16.4757 10.2543C16.4757 9.09175 15.5318 8.14792 14.369 8.14792H14.3689C13.2062 8.14792 12.2622 9.09175 12.2622 10.2543V37.439C12.2622 38.6016 13.2062 39.5455 14.3689 39.5455H14.369C15.5318 39.5455 16.4757 38.6016 16.4757 37.439V10.2543Z'
							fill='white'
						/>
						<path
							id='Vector_16'
							fillRule='evenodd'
							clipRule='evenodd'
							d='M26.6285 1.09391C27.2102 0.0848877 28.4985 -0.260828 29.5061 0.321734L48.3702 11.229C49.3776 11.8116 49.7229 13.1017 49.1411 14.1109C48.5595 15.1199 47.2711 15.4656 46.2636 14.883L27.3996 3.97573C26.392 3.39317 26.0468 2.10294 26.6285 1.09391Z'
							fill='white'
						/>
						<path
							id='Vector_17'
							fillRule='evenodd'
							clipRule='evenodd'
							d='M30.1723 1.09391C29.5907 0.0848877 28.3023 -0.260828 27.2947 0.321734L8.43075 11.229C7.42319 11.8116 7.07799 13.1017 7.6597 14.1109C8.2414 15.1199 9.52972 15.4656 10.5373 14.883L29.4013 3.97573C30.4088 3.39317 30.7541 2.10294 30.1723 1.09391Z'
							fill='white'
						/>
						<path
							id='Vector_18'
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0.771612 41.0051C19.0129 35.2774 38.5676 35.2774 56.8088 41.0051C57.3363 41.1607 57.6297 41.6912 57.4523 42.1915C57.4481 42.2065 57.4433 42.2211 57.4383 42.2356C57.4333 42.2501 57.4279 42.2644 57.4223 42.2784C57.4109 42.3065 57.398 42.3335 57.3839 42.3596C57.3557 42.4118 57.3223 42.4602 57.2845 42.5042C57.2086 42.5925 57.1148 42.664 57.008 42.7167C56.7945 42.8219 56.5293 42.8516 56.2511 42.7879C38.373 37.1743 19.2075 37.1743 1.32936 42.7879C0.850552 42.8892 0.298525 42.6582 0.128132 42.1915C-0.0236263 41.6823 0.261166 41.155 0.771612 41.0051Z'
							fill='white'
						/>
					</g>
					<g id='Group_5'>
						<g id='Group_6'>
							<path
								id='Vector_19'
								fillRule='evenodd'
								clipRule='evenodd'
								d='M33.676 26.1254C33.676 25.4702 33.1441 24.9383 32.4888 24.9383H32.4883C31.833 24.9383 31.301 25.4702 31.301 26.1254V36.8504C31.301 37.5056 31.833 38.0377 32.4883 38.0377H32.4888C33.1441 38.0377 33.676 37.5056 33.676 36.8504V26.1254Z'
								fill='#67D91D'
								fillOpacity='0.7'
							/>
							<path
								id='Vector_20'
								fillRule='evenodd'
								clipRule='evenodd'
								d='M33.676 26.1272C33.676 25.471 33.1432 24.9383 32.4869 24.9383H24.4699C23.8136 24.9383 23.2808 25.471 23.2808 26.1272C23.2808 26.7834 23.8136 27.3162 24.4699 27.3162H32.4869C33.1432 27.3162 33.676 26.7834 33.676 26.1272Z'
								fill='#59BB19'
								fillOpacity='0.7'
							/>
							<path
								id='Vector_21'
								fillRule='evenodd'
								clipRule='evenodd'
								d='M25.6558 26.1254C25.6558 25.4702 25.1238 24.9383 24.4685 24.9383H24.4681C23.8127 24.9383 23.2808 25.4702 23.2808 26.1254V36.8504C23.2808 37.5056 23.8127 38.0377 24.4681 38.0377H24.4685C25.1238 38.0377 25.6558 37.5056 25.6558 36.8504V26.1254Z'
								fill='#479515'
								fillOpacity='0.7'
							/>
							<path
								id='Vector_22'
								fillRule='evenodd'
								clipRule='evenodd'
								d='M44.539 10.2543C44.539 9.09175 43.595 8.14792 42.4323 8.14792H42.4321C41.2695 8.14792 40.3255 9.09175 40.3255 10.2543V37.439C40.3255 38.6016 41.2695 39.5455 42.4321 39.5455H42.4323C43.595 39.5455 44.539 38.6016 44.539 37.439V10.2543Z'
								fill='#68D91E'
								fillOpacity='0.7'
							/>
							<path
								id='Vector_23'
								fillRule='evenodd'
								clipRule='evenodd'
								d='M16.4757 10.2543C16.4757 9.09175 15.5318 8.14792 14.369 8.14792H14.3689C13.2062 8.14792 12.2622 9.09175 12.2622 10.2543V37.439C12.2622 38.6016 13.2062 39.5455 14.3689 39.5455H14.369C15.5318 39.5455 16.4757 38.6016 16.4757 37.439V10.2543Z'
								fill='#3A7910'
								fillOpacity='0.7'
							/>
							<path
								id='Vector_24'
								fillRule='evenodd'
								clipRule='evenodd'
								d='M26.6285 1.09391C27.2102 0.0848877 28.4985 -0.260828 29.5061 0.321734L48.3702 11.229C49.3776 11.8116 49.7229 13.1017 49.1411 14.1109C48.5595 15.1199 47.2711 15.4656 46.2636 14.883L27.3996 3.97573C26.392 3.39317 26.0468 2.10294 26.6285 1.09391Z'
								fill='#59BB1A'
								fillOpacity='0.7'
							/>
							<path
								id='Vector_25'
								fillRule='evenodd'
								clipRule='evenodd'
								d='M30.1723 1.09391C29.5907 0.0848877 28.3023 -0.260828 27.2947 0.321734L8.43075 11.229C7.42319 11.8116 7.07799 13.1017 7.6597 14.1109C8.2414 15.1199 9.52972 15.4656 10.5373 14.883L29.4013 3.97573C30.4088 3.39317 30.7541 2.10294 30.1723 1.09391Z'
								fill='#489514'
								fillOpacity='0.7'
							/>
						</g>
						<path
							id='Vector_26'
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0.771612 41.0051C19.0129 35.2774 38.5676 35.2774 56.8088 41.0051C57.3363 41.1607 57.6297 41.6912 57.4523 42.1915C57.4481 42.2065 57.4433 42.2211 57.4383 42.2356C57.4333 42.2501 57.4279 42.2644 57.4223 42.2784C57.4109 42.3065 57.398 42.3335 57.3839 42.3596C57.3557 42.4118 57.3223 42.4602 57.2845 42.5042C57.2086 42.5925 57.1148 42.664 57.008 42.7167C56.7945 42.8219 56.5293 42.8516 56.2511 42.7879C38.373 37.1743 19.2075 37.1743 1.32936 42.7879C0.850552 42.8892 0.298525 42.6582 0.128132 42.1915C-0.0236263 41.6823 0.261166 41.155 0.771612 41.0051Z'
							fill='#DCB983'
						/>
					</g>
				</g>
			</g>
			<defs>
				<clipPath id='clip0_8_48'>
					<rect width='250' height='42.86' fill='white' />
				</clipPath>
			</defs>
		</svg>
	</Wrapper>
);

export default Logo;
