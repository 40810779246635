// Imports
import styled from 'styled-components/macro';


// Styled components
interface WrapperProps {
	gridArea: string;
	scale: number;
	rotation: number;
}

const Wrapper = styled.div<WrapperProps>`
    grid-area: ${({ gridArea }) => gridArea};
    display: flex;
    align-items: center;
    justify-content center;
    
    > svg {
		width: 1rem;
        transform: rotate(${({ rotation }) => rotation}deg) scale(${({ scale }) => scale});
	}
`;


// Function component
const House: React.FC<WrapperProps> = (props) => (
	<Wrapper {...props}>
		<svg
			clipRule='evenodd'
			fillRule='evenodd'
			strokeLinejoin='round'
			strokeMiterlimit='2'
			viewBox='0 0 449 334'
			xmlns='http://www.w3.org/2000/svg'
			aria-hidden
		>
			<g fill='#c9e3f5' transform='matrix(.1 0 0 .1 .6786 .301406)'>
				<path d='m3463.69 796.085c0-90.596-73.55-164.147-164.15-164.147h-.02c-90.59 0-164.14 73.551-164.14 164.147v2118.455c0 90.6 73.55 164.15 164.14 164.15h.02c90.6 0 164.15-73.55 164.15-164.15z' />
				<path d='m1277 796.085c0-90.596-73.55-164.147-164.15-164.147h-.01c-90.6 0-164.152 73.551-164.152 164.147v2118.455c0 90.6 73.552 164.15 164.152 164.15h.01c90.6 0 164.15-73.55 164.15-164.15z' />
				<path d='m2068.11 82.232c45.32-78.631 145.71-105.572 224.22-60.174l1469.89 849.983c78.5 45.397 105.4 145.939 60.07 224.579-45.32 78.63-145.71 105.57-224.22 60.17l-1469.88-849.983c-78.51-45.398-105.41-145.943-60.08-224.575z' />
				<path d='m2344.24 82.232c-45.32-78.631-145.71-105.572-224.22-60.174l-1469.882 849.983c-78.509 45.397-105.407 145.939-60.08 224.579 45.326 78.63 145.712 105.57 224.219 60.17l1469.883-849.983c78.51-45.398 105.41-145.943 60.08-224.575z' />
				<path d='m53.338 3192.44c1421.362-446.35 2945.062-446.35 4366.422 0 41.1 12.12 63.96 53.46 50.14 92.45-.33 1.17-.7 2.31-1.09 3.44s-.81 2.24-1.25 3.33c-.89 2.19-1.89 4.3-2.99 6.33-2.2 4.07-4.8 7.84-7.75 11.27-5.91 6.88-13.22 12.45-21.54 16.56-16.64 8.2-37.3 10.51-58.98 5.55-1393.06-437.46-2886.44-437.46-4279.502 0-37.309 7.89-80.323-10.11-93.6-46.48-11.825-39.68 10.366-80.77 50.14-92.45z' />
			</g>
		</svg>
	</Wrapper>
);

export default House;
