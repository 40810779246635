// Imports
import styled from 'styled-components/macro';
import Link from './link';


// Styled component
const Wrapper = styled.nav`
	position: sticky;
	top: 9rem;
	height: fit-content;
	
	@media (max-width: 48rem) {
		position: static;
	}
`;

const Links = styled.ul`
	list-style: none;
	
	display: flex;
	flex-direction: column;
`;


// Define the accepted props
interface Props {
	links: React.ComponentProps<typeof Link>[];
}


// Function component
const StickyNavbar: React.FC<Props> = ({ links }) => (
	<Wrapper>
		<Links>
			{links.map(({ to, ...link }, i) => (
				<Link key={i} to={`${to}#top`} {...link} />
			))}
		</Links>
	</Wrapper>
);

export default StickyNavbar;
