// Imports
import React from 'react';
import { Link } from 'react-router-dom';
import emailAccountLimitMapping from '../../../../shareables/utils/billing/email-account-limit-mapping';
import smsCreditsMapping from '../../../../shareables/utils/billing/sms-credits-mapping';
import subscriptionSizes from '../../../../shareables/utils/billing/subscription-sizes';


// Types
type BaseFeatureValue = string | number | boolean | JSX.Element;

type SizeBasedFeatureValue = (size: (typeof subscriptionSizes)[number]) => BaseFeatureValue;

type FeatureValue = BaseFeatureValue | SizeBasedFeatureValue;

export interface Feature {
	label: {
		text: string;
		note?: string;
	};
	free: FeatureValue;
	deluxe: FeatureValue;
}

export interface FeatureGroup {
	label: string;
	features: Feature[];
}


// Feature groupings
const featureGroupings: FeatureGroup[] = [
	{
		label: 'Core',
		features: [
			{
				label: { text: 'Pages & storage' },
				free: 'Unlimited',
				deluxe: 'Unlimited',
			},
			{
				label: { text: 'Residents' },
				free: 'Unlimited',
				deluxe: 'Unlimited',
			},
			{
				label: { text: 'Households' },
				free: 100,
				deluxe: (size) => size.toLocaleString(),
			},
			{
				label: { text: 'What’s Happening emails' },
				free: true,
				deluxe: true,
			},
			{
				label: { text: 'Address verification', note: 'U.S. only' },
				free: true,
				deluxe: true,
			},
			{
				label: { text: 'Intelligent address correction', note: 'U.S. only' },
				free: true,
				deluxe: true,
			},
			{
				label: { text: 'Free custom domain' },
				free: false,
				deluxe: true,
			},
			{
				label: { text: 'Multiple website admins' },
				free: false,
				deluxe: true,
			},
			{
				label: { text: 'Organize residents into groups' },
				free: false,
				deluxe: true,
			},
			{
				label: { text: 'Built-in visitor tracking' },
				free: false,
				deluxe: true,
			},
			{
				label: { text: 'No ads' },
				free: false,
				deluxe: true,
			},
		],
	},
	{
		label: 'Communication',
		features: [
			{
				label: { text: 'Blast emails' },
				free: false,
				deluxe: true,
			},
			{
				label: { text: 'Blast text messages' },
				free: false,
				deluxe: (size) => (
					<React.Fragment>
						{smsCreditsMapping[size].toLocaleString()} monthly <Link to='/pricing-details/text-messages'>credits</Link>
					</React.Fragment>
				),
			},
			{
				label: { text: 'Mailed letters', note: 'U.S. only' },
				free: false,
				deluxe: <Link to='/pricing-details/mailed-letters'>Affordable pricing</Link>,
			},
			{
				label: { text: 'Professional email accounts' },
				free: false,
				deluxe: (size) => emailAccountLimitMapping[size],
			},
			{
				label: { text: 'Surveys & polls' },
				free: false,
				deluxe: true,
			},
			{
				label: { text: 'Resident-initiated alerts' },
				free: false,
				deluxe: true,
			},
		],
	},
	{
		label: 'Page content',
		features: [
			{
				label: { text: 'General text' },
				free: true,
				deluxe: true,
			},
			{
				label: { text: 'Documents' },
				free: 'Limited',
				deluxe: true,
			},
			{
				label: { text: 'Calendars' },
				free: true,
				deluxe: true,
			},
			{
				label: { text: 'Resident directory' },
				free: true,
				deluxe: true,
			},
			{
				label: { text: 'Board members' },
				free: true,
				deluxe: true,
			},
			{
				label: { text: 'Committees' },
				free: true,
				deluxe: true,
			},
			{
				label: { text: 'News articles' },
				free: true,
				deluxe: true,
			},
			{
				label: { text: 'FAQs' },
				free: true,
				deluxe: true,
			},
			{
				label: { text: 'Maps' },
				free: true,
				deluxe: true,
			},
			{
				label: { text: 'Community facilities' },
				free: true,
				deluxe: true,
			},
			{
				label: { text: 'Photo galleries' },
				free: false,
				deluxe: true,
			},
			{
				label: { text: 'Contact forms' },
				free: false,
				deluxe: true,
			},
			{
				label: { text: 'Pet directory' },
				free: false,
				deluxe: true,
			},
			{
				label: { text: 'Forums' },
				free: false,
				deluxe: true,
			},
			{
				label: { text: 'Classifieds' },
				free: false,
				deluxe: true,
			},
			{
				label: { text: 'Useful contact info' },
				free: false,
				deluxe: true,
			},
			{
				label: { text: 'Garage sales' },
				free: false,
				deluxe: true,
			},
			{
				label: { text: 'Recipes' },
				free: false,
				deluxe: true,
			},
		],
	},
	{
		label: 'Payments',
		features: [
			{
				label: { text: 'Collect payments from residents', note: 'Select countries' },
				free: false,
				deluxe: true,
			},
			{
				label: { text: 'Pass transaction fees to residents' },
				free: false,
				deluxe: true,
			},
			{
				label: { text: 'Accept all major credit/debit cards' },
				free: false,
				deluxe: true,
			},
			{
				label: { text: 'Accept bank accounts', note: 'U.S. only' },
				free: false,
				deluxe: true,
			},
		],
	},
	{
		label: 'Security',
		features: [
			{
				label: { text: 'Restrict access to pages' },
				free: true,
				deluxe: true,
			},
			{
				label: { text: 'Require approval for new registrations' },
				free: true,
				deluxe: true,
			},
			{
				label: { text: 'Complete HTTPS' },
				free: true,
				deluxe: true,
			},
			{
				label: { text: 'Strict HSTS' },
				free: true,
				deluxe: true,
			},
			{
				label: { text: 'Full privacy controls' },
				free: true,
				deluxe: true,
			},
			{
				label: { text: 'Email scraping prevention' },
				free: true,
				deluxe: true,
			},
			{
				label: { text: 'Spam protection' },
				free: true,
				deluxe: true,
			},
			{
				label: { text: 'Frequent backups' },
				free: true,
				deluxe: true,
			},
		],
	},
];

export default featureGroupings;
