// Imports
import React from 'react';
import styled from 'styled-components/macro';


// Styled components
const Container = styled.div`
	text-align: center;
`;

const Heading = styled.h2`
	font-size: 2rem;
	font-weight: 600;
	line-height: 1.2;
	
	padding-top: 3.75rem;
	margin-bottom: 1rem;
`;


// Function component
const NotFound: React.FC = () => (
	<Container>
		<Heading>Invoice not found</Heading>
		
		<p>Double-check the link, or ask your community for a new one.</p>
	</Container>
);

export default NotFound;
