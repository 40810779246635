// Imports
import { faCubes } from '@fortawesome/pro-regular-svg-icons';
import Feature from '..';


// Banner
const feature: React.ComponentProps<typeof Feature> = {
	banner: {
		label: { icon: faCubes, text: 'Custom forms' },
		heading: 'Collect information from residents & website visitors',
		description:
			'Whether you need a simple contact form, or a robust form with many fields, HOA Express makes it easy to build fully customizable online forms that are quicker to fill out (and easier keep track of) than paper forms.',
		screenshot: {
			width: 900,
			height: 637,
			src: '/images/screenshots/features/custom-forms/banner.webp',
		},
	},
	pitch: { text: 'Build fully-customizable online forms with ease', textMaxWidth: 'min(33.325rem, 44.42vw)' },
	tiles: {
		leftColumn: [
			{
				heading: 'Many available fields types',
				screenshot: {
					width: 940,
					height: 332,
					src: (layout) => `/images/screenshots/features/custom-forms/${layout}/many-field-types.webp`,
				},
			},
			{
				heading: 'Offer multiple recipients',
				screenshot: {
					width: 580,
					height: 350,
					src: (layout) => `/images/screenshots/features/custom-forms/${layout}/multiple-recipients.webp`,
				},
			},
			{
				heading: 'Advanced spam protection',
				screenshot: {
					width: 600,
					height: 206,
					src: (layout) => `/images/screenshots/features/custom-forms/${layout}/spam-protection.webp`,
				},
			},
			{
				heading: 'Quickly rearrange fields',
				screenshot: {
					width: 980,
					height: 248,
					src: (layout) => `/images/screenshots/features/custom-forms/${layout}/rearrange-fields.webp`,
				},
			},
		],
		rightColumn: [
			{
				heading: 'View submission history',
				screenshot: {
					width: 980,
					height: 462,
					src: (layout) => `/images/screenshots/features/custom-forms/${layout}/submission-history.webp`,
				},
			},
			{
				heading: 'Use groups as recipients',
				screenshot: {
					width: 940,
					height: 272,
					src: (layout) => `/images/screenshots/features/custom-forms/${layout}/groups-as-recipients.webp`,
				},
			},
			{
				heading: 'Collect file uploads',
				screenshot: {
					width: 768,
					height: 230,
					src: (layout) => `/images/screenshots/features/custom-forms/${layout}/file-uploads.webp`,
				},
			},
			{
				heading: 'Make fields optional or required',
				screenshot: {
					width: 980,
					height: 248,
					src: (layout) => `/images/screenshots/features/custom-forms/${layout}/optional-or-required.webp`,
				},
			},
		],
	},
	testimonials: [
		{
			text: 'I have created a custom form for residents to submit Architectural Review requests. The form works great, and the document upload process is a plus!',
			source: {
				name: 'Janelle',
				hoa: 'Woodside HOA',
				url: 'https://www.woodsidepud-hoa.com/',
			},
		},
	],
};

export default feature;
