// Imports
import ThirdPartyIdentifiers from '../types/third-party-identifiers';


// Types
interface ProjectThirdPartyIdentifiers extends ThirdPartyIdentifiers {
	intercomAppID: {
		production: string;
		development: string;
	};
}


// Export
const thirdPartyIdentifiers: ProjectThirdPartyIdentifiers = {
	recaptchaSiteKeys: {
		v3: {
			dev: '6LdThdAZAAAAAPSvocbVhbvZTbLDR34JtzD_coAu',
			prd: '6LdhhdAZAAAAAHJ2UljZ7TPwc2dx6d_nzwufkHng',
		},
		v2: {
			dev: '6Le71TEpAAAAANamXsBCt5R2eG6MzwLzIKDxtEm-',
			prd: '6LeJ5DEpAAAAAH3nn_ebbi0sW7EBE1MnxFMoCgS9',
		},
	},
	cannyAppID: '5c2d329ac98e025626f5e8a8',
	statuspageAppID: 'f4g8z0njphjx',
	stripePublishableKeys: {
		live: 'pk_live_ME2xmFQDjW2sTp0CDNMbwChy',
		test: 'pk_test_l0SiFLUjXpWFAsi0HloEco9b',
	},
	intercomAppID: {
		production: 'kisnvojd',
		development: 'hhcfafrn',
	},
};

export default thirdPartyIdentifiers;
