// Imports
import styled from 'styled-components/macro';


// Styled components
const Logo = styled.li<{ width: number }>`
	> svg {
		width: ${({ width }) => width}rem;
		
		@media (max-width: 48rem) {
			width: calc(${({ width }) => width}rem * 0.72);
		}
	}
`;

export default Logo;
