// Imports
import React from 'react';
import Feature from '..';
import feature from './feature';


// Function component
const DomainAndEmail: React.FC = () => <Feature {...feature} />;

export default DomainAndEmail;
