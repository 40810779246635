// Imports
import { BannerListItem } from '../../../resources/banner-with-list';


// Items
const items: BannerListItem[] = [
	{
		label: 'Enter basic info',
		description: 'Your community’s name, location, and other simple information.',
	},
	{
		label: 'Add content',
		description: 'Start with the basics—adding content later is a piece of cake!',
	},
	{
		label: 'Invite residents',
		description: 'Invite residents to join, or import a preexisting list.',
	},
];

export default items;
